import { useQuery } from 'react-query'

import { QUERY_KEYS } from '@api'
import { ItemsApi } from '@api'

const useItem = (itemId: number | string) => {
  const { data: item, isLoading } = useQuery([QUERY_KEYS.ITEM, String(itemId)], () => ItemsApi.getById(itemId), {
    refetchOnWindowFocus: false,
  })
  if (item && item.owner && item.metadata) {
    item.metadata.item = item
  }

  return { item, isLoading }
}

export { useItem }
