import { useNavigate, useParams } from 'react-router-dom'
import * as React from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box'

import { Content, CustomButton, Header, Loading, Title } from '@components'
import { useTranslation } from '@hooks'
import { Collectible } from '@types'
import { AppBase } from '@features/AppBase'
import { CollectibleItemCard } from '@features/Challenges/components/CollectibleItemCard'
import { ROUTES } from '@constants'
import { useModal } from '@contexts'
import {
  ChallengeCompletedModal,
  ChallengeErrorSavingChangesModal,
  ChallengeSaveChangesModal,
} from '@features/Challenges/modals'
import { Images } from '@images'
import { useCheckQuota } from '@features/Quotas/hooks'
import { QuotaFeature } from '@features/Quotas/types'

import { ChallengeClientStatus, ChallengeRequirementItem } from '../../types'
import { ChallengeRequiredItems, ChallengeStatusBar, CollectiblesForRequiredItem } from '../../components'
import { useChallenge, useSaveCollectedItems } from '../../hooks'
import { useSessionUserUpdater } from '../../../../hooks/useSessionUserUpdater'

const ChallengeView = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { showModal } = useModal()
  const { id = '0' } = useParams()
  const [requiredItemSelected, updateRequiredItemSelected] = useState<ChallengeRequirementItem | null>(null)
  const [requiredItemsWithCollectible, updateRequiredItemsWithCollectible] = useState<ChallengeRequirementItem[]>([])

  const { challenge, isLoading, refetch } = useChallenge(id)

  const { refresh } = useSessionUserUpdater()

  const { validQuota, showModalQuota } = useCheckQuota(QuotaFeature.CHALLENGES, {
    redirect: () => navigate(ROUTES.CHALLENGES),
    noModal: true,
  })

  useEffect(() => {
    if (challenge && !challenge.challengeClient && !validQuota) {
      showModalQuota()
    }
  }, [challenge, validQuota])

  useEffect(() => {
    if (
      challenge &&
      requiredItemsWithCollectible.length == 0 &&
      challenge.requirements &&
      challenge.requirements.length > 0
    ) {
      updateRequiredItemsWithCollectible(challenge.requirements)
    }
  }, [challenge, requiredItemsWithCollectible])

  const onCollectibleSelected = useCallback(
    (collectible: Collectible | undefined, requiredItem: ChallengeRequirementItem) => {
      const items = requiredItemsWithCollectible.map(item => {
        if (item.id === requiredItem.id) {
          item.collectible = collectible
        }

        return item
      })
      updateRequiredItemsWithCollectible(items)
    },
    [requiredItemsWithCollectible],
  )

  const reloadAll = () => {
    updateRequiredItemSelected(null)
    refetch().then(() => updateRequiredItemsWithCollectible([]))
    refresh()
  }

  const { saveCollectedItems, isLoading: isSubmitting } = useSaveCollectedItems(challenge?.id ?? '', reloadAll, () => {
    showModal({ modal: <ChallengeErrorSavingChangesModal /> })
  })

  const onSubmit = () => {
    const collectedItems = requiredItemsWithCollectible
      .filter(item => item.collectible && !item.collectible.locked)
      .map(item => ({ requiredItemId: item.id, collectibleId: item.collectible?.id ?? 0 }))
    saveCollectedItems(collectedItems)
  }

  const pendingChanges = useMemo(() => {
    return (
      (requiredItemsWithCollectible.length > 0 &&
        requiredItemsWithCollectible
          .map(item => item.collectible && !item.collectible.locked)
          .find(unlocked => !!unlocked)) ??
      false
    )
  }, [requiredItemsWithCollectible])

  useEffect(() => {
    if (challenge && challenge.challengeClient?.status === ChallengeClientStatus.FINISHED) {
      showModal({
        modal: <ChallengeCompletedModal />,
        options: {
          onClose: () => navigate(ROUTES.CHALLENGES),
        },
      })
    }
  }, [challenge])

  return (
    <AppBase requireActiveSession backgroundImage={Images.ChallengesBackground}>
      <Box width={'100%'} px={{ lg: 16.5, md: 10, sm: 8, xs: 2 }} py={4}>
        <Header>
          <CustomButton
            variant={'secondary'}
            label={t('challenges.view.back')}
            startIcon={['fas', 'chevron-left']}
            onClick={() => navigate(ROUTES.CHALLENGES)}
          />
          <Title text={challenge?.name ?? ''} />
        </Header>
        <ChallengeStatusBar
          challenge={challenge}
          pendingChanges={pendingChanges}
          onSubmit={() => showModal({ modal: <ChallengeSaveChangesModal onSave={onSubmit} /> })}
          isSubmitting={isSubmitting || isLoading}
        />
        <Content flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs: 'column' }}>
          {isLoading && <Loading />}
          {!isLoading && requiredItemsWithCollectible.length > 0 && (
            <ChallengeRequiredItems
              requiredItems={requiredItemsWithCollectible}
              onItemSelected={item => updateRequiredItemSelected(item)}
              itemSelected={requiredItemSelected}
            />
          )}
          {!isLoading && !!requiredItemSelected && !requiredItemSelected.collectible && (
            <CollectiblesForRequiredItem
              onSave={onSubmit}
              requiredItem={requiredItemSelected}
              requiredItemsWithCollectible={requiredItemsWithCollectible}
              onCollectibleSelected={onCollectibleSelected}
              pendingChanges={pendingChanges}
            />
          )}
          {!isLoading && !!requiredItemSelected && requiredItemSelected.collectible && (
            <CollectibleItemCard
              isTemporaryLocked={false}
              isSelected={true}
              collectible={requiredItemSelected.collectible}
              onClick={() => onCollectibleSelected(undefined, requiredItemSelected)}
            />
          )}
        </Content>
      </Box>
    </AppBase>
  )
}

export { ChallengeView }
