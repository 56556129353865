import * as React from 'react'
import { useMemo, useState } from 'react'
import { InputAdornment } from '@mui/material'

import { Column, Loading, Row, SelectInput, TextH4Bold, TextInput } from '@components'
import { useDebounce, useTranslation } from '@hooks'
import { CoinsBudget, CoinsCurrency } from '@features/Coins/types'

import { useCoinsBudget } from '../../hooks/useCoinsBudget'
import { BuyButton } from '../BuyButton'

interface CoinsOption {
  label: string
  value: CoinsCurrency | 'COINS'
  adornment: string
}

interface Props {
  onSuccess: () => void
}
const BuyCoins = (props: Props) => {
  const { onSuccess } = props
  const { t } = useTranslation()
  const options: CoinsOption[] = [
    { label: t('coins.buy.coins'), value: 'COINS', adornment: '#' },
    { label: t('coins.buy.eur'), value: CoinsCurrency.EUR, adornment: '€' },

    // { label: t('coins.buy.usd'), value: CoinsCurrency.USD, adornment: '$' },
  ]

  const [option, setOption] = useState<CoinsOption>(options[0])
  const [amount, setAmount] = useState<number>(1000)
  const onValueChange = (newValue: number) => {
    if (newValue < 0) {
      setAmount(0)
    } else {
      setAmount(Math.floor(newValue * 100) / 100)
    }
  }

  const debouncedAmount = useDebounce(amount, 1000)

  const updateBudgetInput: CoinsBudget = useMemo(() => {
    if (option.value === 'COINS') {
      return { currency: CoinsCurrency.EUR, amount: 0, coins: debouncedAmount }
    } else {
      return { currency: option?.value, amount: (debouncedAmount ?? 0) * 100 }
    }
  }, [option, debouncedAmount])

  const { data: budget, isLoading, isError, error } = useCoinsBudget(updateBudgetInput)

  const budgetText = useMemo(() => {
    if (budget) {
      if (option.value === 'COINS') {
        const value = budget.amount / 100

        return `${value} ${budget.currency}`
      } else {
        return t('coins.buy.budget-coins', { coins: budget.coins })
      }
    }

    return ''
  }, [budget, option, t])

  const errorText = useMemo(() => {
    if (isError) {
      if (error) {
        const { minAmount, minCoinsToBuy } = error

        return option.value === 'COINS'
          ? t('coins.buy.error.min-coins', { minCoinsToBuy })
          : t('coins.buy.error.min-amount', { minAmount: minAmount / 100, currencySymbol: option.adornment })
      } else {
        return t('coins.buy.error.generic')
      }
    }

    return ''
  }, [option, isError, error])

  return (
    <Column width={'100%'}>
      <Row mt={2} width={'100%'}>
        <SelectInput<CoinsOption>
          label={''}
          options={options}
          multiple={false}
          objectToString={option => option?.label}
          onChange={value => setOption(value.shift()!)}
          value={option ? [option] : []}
        />
        <TextInput
          fullWidth
          startAdornment={<InputAdornment position='start'>{option?.adornment}</InputAdornment>}
          type={'number'}
          value={amount}
          onChange={e => onValueChange(Number(e.target.value) ?? 0)}
          error={isError}
          helperText={errorText}
        />
      </Row>
      {isLoading ? <Loading /> : budget && <TextH4Bold>~{budgetText}</TextH4Bold>}
      <BuyButton disabled={isError || isLoading || !budget} budget={budget} onSuccess={onSuccess} />
    </Column>
  )
}

export { BuyCoins }
