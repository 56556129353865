import { api } from '@api'
import { Item } from '@types'

import { DigitizeDto } from '../types'

const digitize = async (payload: DigitizeDto) => {
  const response = await api.post<Item>('/items', payload)

  return response.data
}

export const DigitizeApi = { digitize }
