import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'

import enTranslation from './en/global.json'
import { setLanguaje } from '../api/axios'

const options = {
  order: ['navigator'],
}

const resources = {
  en: {
    translation: enTranslation,
  },
}

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: 'en',
    detection: options,
  })

setLanguaje(i18n.resolvedLanguage ?? i18n.language)
i18n.on('languageChanged', lang => setLanguaje(lang))
export default i18n
