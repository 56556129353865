import Box from '@mui/material/Box'
import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useModal } from '@contexts'
import { CustomButton, Modal, TextH2Bold } from '@components'
import { Images } from '@images'
import { colors } from '@styles'
import { useTranslation } from '@hooks'

const EditProfileSuccessModal = () => {
  const { dismissModal } = useModal()
  const { t } = useTranslation()

  return (
    <Modal.Content>
      <Modal.Header image={Images.SuccessImage}>
        <Box sx={{ alignSelf: 'center' }}>
          <FontAwesomeIcon icon={['far', 'circle-check']} size={'3x'} color={colors.status.success.background} />
        </Box>
        <Box display={'flex'} flexDirection={'column'} gap={0} pt={1.5}>
          <TextH2Bold>{t('my-profile.modals.success.title')}</TextH2Bold>
        </Box>
      </Modal.Header>
      <Modal.Actions>
        <CustomButton
          variant={'secondary'}
          label={t('my-profile.modals.success.back')}
          fullWidth={true}
          onClick={() => dismissModal()}
        />
      </Modal.Actions>
    </Modal.Content>
  )
}

export { EditProfileSuccessModal }
