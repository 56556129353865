import * as yup from 'yup'
import dayjs from 'dayjs'

import { useTranslation } from '@hooks'

export const usePersonalInfoValidationSchema = () => {
  const { t } = useTranslation()

  const parseDateString = (value, originalValue) => {
    const parsedDate = dayjs(originalValue, 'YYYY-MM-DD')

    return parsedDate?.toDate()
  }
  const personalInfoValidationSchema = yup.object({
    firstName: yup.string().required(t('create-account.personal-info.validation.required')),
    lastName: yup.string().required(t('create-account.personal-info.validation.required')),
    email: yup
      .string()
      .email(t('create-account.personal-info.validation.email'))
      .required(t('create-account.personal-info.validation.required')),
    password: yup
      .string()
      .min(8, t('create-account.personal-info.validation.password-length'))
      .required(t('create-account.personal-info.validation.required')),
    birthdate: yup
      .date()
      .transform(parseDateString)
      .max(dayjs().format('YYYY-MM-DD'))
      .required(t('create-account.personal-info.validation.required')),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], t('create-account.personal-info.validation.password-must-match'))
      .required(t('create-account.personal-info.validation.required')),
  })

  return { personalInfoValidationSchema }
}
