import { styled } from '@mui/material/styles'

const RoundedImage = styled('img')(({ theme }) => ({
  height: theme.spacing(12),
  width: theme.spacing(12),
  borderRadius: '50%',
  overflow: 'hidden',
  cursor: 'pointer',
  objectFit: 'cover',
}))

export { RoundedImage }
