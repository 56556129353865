import { styled } from '@mui/material/styles'

import { Modal } from '@components'

const Image = styled('img')(({ theme }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: '#FFFFFF',
  overflow: 'hidden',
  width: '100%',
  height: theme.spacing(90),
  objectFit: 'contain',
}))
const ItemBigImageModal = (props: { image: string }) => {
  return (
    <Modal.Content>
      <Image src={props.image} />
    </Modal.Content>
  )
}

export { ItemBigImageModal }
