import { PaginatedRequest, PaginatedResponse } from '@types'
import { api } from '@api'

import { RankingGlobal, RankingGlobalFilters } from '../types'

const paginateRanking = async (params: PaginatedRequest<RankingGlobalFilters>) => {
  const { page = 0, pageSize = 10, filters } = params
  const response = await api.get<PaginatedResponse<RankingGlobal>>(`/rankings`, {
    params: { page, pageSize, ...filters },
  })

  return response.data
}

export const RankingApi = {
  paginateRanking,
}
