import * as React from 'react'
import { useCallback, useEffect } from 'react'

import { QuotaFeature } from '@features/Quotas/types'
import { useModal, useUser } from '@contexts'
import { QuotaExceededModal } from '@features/Quotas/modals'
import { SubscriptionType } from '@types'

interface Options {
  redirect?: () => void
  showRefresh?: boolean
  forceRedirect?: boolean
  noModal?: boolean
}

const useCheckQuota = (feature: QuotaFeature, options?: Options) => {
  const { redirect, showRefresh = true, forceRedirect = true, noModal = false } = options ?? {}
  const { userState } = useUser()
  const { showModal } = useModal()

  const showModalQuota = useCallback(() => {
    if (userState?.subscription.type != SubscriptionType.PREMIUM && userState?.subscription[feature] === 0) {
      showModal({
        modal: <QuotaExceededModal showRefresh={showRefresh} redirect={redirect} />,
        options: { onClose: forceRedirect ? redirect : undefined },
      })
    }
  }, [userState])

  useEffect(() => {
    if (!noModal) {
      showModalQuota()
    }
  }, [showModalQuota])

  return {
    validQuota: Boolean(
      (userState &&
        (userState.subscription[feature] > 0 || userState.subscription.type === SubscriptionType.PREMIUM)) ??
        false,
    ),
    showModalQuota,
  }
}
export { useCheckQuota }
