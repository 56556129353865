import Box from '@mui/material/Box'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { useRef } from 'react'

import { CarouselButton, CarouselContent, CarouselItem } from './styled'

interface Props<T> {
  items: T[]
  itemProvider: (item: T) => React.ReactElement
}

const Carousel = <T,>(props: Props<T>) => {
  const { items, itemProvider } = props
  const content = useRef<Element>()

  const onNext = () => {
    if (content.current) {
      content.current.scrollLeft = content.current.scrollLeft + 200
    }
  }

  const onPrev = () => {
    if (content.current) {
      content.current.scrollLeft = content.current.scrollLeft - 200
    }
  }

  return (
    <Box display={'flex'} flexDirection={'row'} width={'100%'} alignItems={'center'} justifyContent={'space-between'}>
      <CarouselButton onClick={onPrev}>
        <FontAwesomeIcon icon={['fas', 'chevron-left']} />
      </CarouselButton>

      <CarouselContent ref={content}>
        {items.map((item, index) => (
          <CarouselItem key={`carousel-item-${index}`}>{itemProvider(item)}</CarouselItem>
        ))}
      </CarouselContent>

      <CarouselButton onClick={onNext}>
        <FontAwesomeIcon icon={['fas', 'chevron-right']} />
      </CarouselButton>
    </Box>
  )
}

export { Carousel }
