import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'

import { colors, MediaQuery, MqTablet } from '@styles'

export const CarouselButton = styled(IconButton)(({ theme }) => ({
  color: colors.grayscale['100'].background,
  borderRadius: theme.spacing(4),
  width: theme.spacing(6),
  height: theme.spacing(6),
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
  flexShrink: 0,
  [MediaQuery[MqTablet]]: {
    display: 'flex',
  },
  display: 'none',
}))

export const CarouselItem = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(1),
  display: 'inline-block',
  whiteSpace: 'pre-wrap',
  height: '100%',
  overflow: 'hidden',
}))

export const CarouselContent = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflowX: 'scroll',
  scrollBehavior: 'smooth',
  overflowY: 'hidden',
  whiteSpace: 'nowrap',
  [MediaQuery[MqTablet]]: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}))
