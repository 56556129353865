import { useQuery } from 'react-query'

import { QUERY_KEYS, ItemsApi } from '@api'

const useMyItems = (page: number) => {
  const { data, isLoading } = useQuery([QUERY_KEYS.MY_ITEMS, page], () => ItemsApi.getMyItems(page), {
    refetchOnWindowFocus: false,
  })

  return { data, isLoading }
}

export { useMyItems }
