import Box from '@mui/material/Box'
import { Divider, FormControlLabel, FormGroup, FormHelperText, RadioGroup } from '@mui/material'
import * as React from 'react'
import { styled } from '@mui/material/styles'
import { useFormik } from 'formik'
import { useCallback, useMemo } from 'react'

import { Brand, ItemEquipmentType, TeamType } from '@types'
import { useTranslation, useBrands } from '@hooks'
import { Checkbox, CustomButton, DateInput, MaskedTextInput, Radio, SelectInput, TextInput } from '@components'
import { colors } from '@styles'
import { useModal } from '@contexts'

import { TeamPicker } from '../TeamPicker'
import { DigitizeForm } from '../../types'
import { useDigitizeFormValidationSchema } from './validation'
import { useDigitize } from '../../contexts/digitize-context'
import { ConfirmModal } from '../../modals/ConfirmModal'

const FormWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  flexGrow: 1,
  width: '100%',
  maxWidth: 500,
  flexShrink: 2,
}))

const initialValues: DigitizeForm = {
  dorsal: '',
  history: '',
  name: '',
  patched: false,
  brand: [],
  equipment: [],
  patches: [],
  season: '',
  year: '',
  signed: false,
  signer: '',
}
const Form = () => {
  const { itemMediaFiles, setSigned, digitize } = useDigitize()
  const { t } = useTranslation()
  const { brands } = useBrands()
  const { showModal } = useModal()
  const { digitizeFormValidationSchema } = useDigitizeFormValidationSchema()

  const formik = useFormik<DigitizeForm>({
    initialValues: { ...initialValues },
    validationSchema: digitizeFormValidationSchema,
    onSubmit: values => {
      formik.setSubmitting(true)
      digitize(values)
      formik.setSubmitting(false)
    },
  })

  const confirmAndSubmit = useCallback(() => {
    showModal({ modal: <ConfirmModal onConfirm={() => formik.submitForm()} /> })
  }, [showModal, formik.submitForm])

  const signed = formik.values.signed

  //const patched = formik.values.patched

  const imagesAreValid = useMemo(() => {
    const images = [
      itemMediaFiles.frontImage,
      itemMediaFiles.backImage,
      itemMediaFiles.rightArmImage,
      itemMediaFiles.leftArmImage,
    ]
    if (signed) images.push(itemMediaFiles?.signatureImage ?? '')
    let valid = true
    images.forEach(image => (valid = valid && !!image))

    return valid
  }, [itemMediaFiles, signed])

  return (
    <FormWrapper>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, justifyContent: 'space-between' }}>
        <RadioGroup
          sx={{ gap: 2, flexDirection: 'column' }}
          onChange={(_, value) => {
            formik.setFieldValue('type', value)
            formik.setFieldValue('team', null)
          }}
        >
          <FormControlLabel
            value={TeamType.NATIONAL_TEAM}
            control={<Radio />}
            label={t('digitize.form.input.team-type.national-team')}
            sx={{ color: colors.grayscale['10'].background }}
          />
          <FormControlLabel
            value={TeamType.TEAM}
            control={<Radio />}
            label={t('digitize.form.input.team-type.club')}
            sx={{ color: colors.grayscale['10'].background }}
          />
          {Boolean(formik.errors.type) && <FormHelperText error={true}>{formik.errors.type}</FormHelperText>}
        </RadioGroup>
        <Divider orientation={'vertical'} sx={{ backgroundColor: colors.grayscale[30].background }} />
        <TeamPicker
          type={formik.values.type}
          team={formik.values.team}
          onChange={t => formik.setFieldValue('team', t)}
          error={Boolean(formik.errors.team)}
          helperText={formik.errors.team}
        />
      </Box>

      <MaskedTextInput
        name={'season'}
        label={t('digitize.form.input.season')}
        mask={'0000/0000'}
        definitions={{}}
        fullWidth
        placeholder={'YYYY/YYYY'}
        value={formik.values.season}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.season && Boolean(formik.errors.season)}
        helperText={formik.touched.season && formik.errors.season}
      />

      <DateInput
        name={'year'}
        label={t('digitize.form.input.year')}
        format={'YYYY'}
        onChange={date => {
          formik.setFieldValue('year', date?.format('YYYY') ?? '')
        }}
        views={['year']}
        error={Boolean(formik.errors.year)}
        helperText={formik.errors.year}
      />
      <SelectInput<Brand>
        label={t('digitize.form.input.brand')}
        fullWidth
        options={brands}
        multiple={false}
        objectToString={brand => brand?.name}
        onChange={value => formik.setFieldValue('brand', value)}
        value={formik.values.brand}
        error={Boolean(formik.errors.brand)}
        helperText={formik.errors.brand}
      />
      <TextInput
        name={'dorsal'}
        label={t('digitize.form.input.dorsal')}
        fullWidth
        value={formik.values.dorsal}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.dorsal && Boolean(formik.errors.dorsal)}
        helperText={formik.touched.dorsal && formik.errors.dorsal}
      />
      <TextInput
        name={'name'}
        label={t('digitize.form.input.name')}
        fullWidth
        value={formik.values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
      />
      <SelectInput
        label={t('digitize.form.input.equipment')}
        options={[ItemEquipmentType.FIRST, ItemEquipmentType.SECOND, ItemEquipmentType.THIRD]}
        multiple={false}
        fullWidth
        value={formik.values.equipment}
        onChange={value => formik.setFieldValue('equipment', value)}
        error={Boolean(formik.errors.equipment)}
        helperText={formik.errors.equipment}
      />

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.signed}
              onChange={(_, checked) => {
                formik.setFieldValue('signed', checked)
                setSigned(checked)
              }}
            />
          }
          label={t('digitize.form.input.signed')}
          sx={{ color: colors.grayscale['10'].background }}
        />
        <Box
          sx={{
            transition: 'all linear 0.25s',
            opacity: !signed ? '0' : '1',
            visibility: !signed ? 'hidden' : 'visible',
            height: !signed ? '0px' : 'auto',
            overflow: 'hidden',
            paddingTop: 2,
          }}
        >
          <TextInput
            label={t('digitize.form.input.signer')}
            fullWidth
            name={'signer'}
            value={formik.values.signer}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.signer && Boolean(formik.errors.signer)}
            helperText={formik.touched.signer && formik.errors.signer}
          />
        </Box>
      </FormGroup>

      {/*(
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.patched}
                onChange={(_, checked) => {
                  formik.setFieldValue('patches', [])
                  formik.setFieldValue('patched', checked)
                  setTimeout(() => formik.validateField('patches'), 500)
                }}
              />
            }
            label={t('digitize.form.input.patches')}
            sx={{ color: colors.grayscale['10'].background }}
          />
          <Box
            sx={{
              transition: 'all linear 0.25s',
              opacity: !patched ? '0' : '1',
              visibility: !patched ? 'hidden' : 'visible',
              height: !patched ? '0px' : 'auto',
              overflow: 'hidden',
              paddingTop: 2,
            }}
          >
            {patched && (
              <PatchesInput
                values={formik.values.patches}
                errors={formik.errors.patches as FormikErrors<Patch>[] | undefined}
                onChange={values => formik.setFieldValue('patches', values)}
              />
            )}
          </Box>
        </FormGroup>
      )*/}

      <TextInput
        name={'history'}
        label={t('digitize.form.input.history')}
        fullWidth
        multiline={true}
        rows={3}
        value={formik.values.history}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.history && Boolean(formik.errors.history)}
        helperText={formik.touched.history && formik.errors.history}
      />
      <CustomButton
        variant={'primary'}
        label={'Digitize'}
        disabled={!formik.isValid || !imagesAreValid || formik.isSubmitting || formik.isValidating}
        onClick={() => confirmAndSubmit()}
      />
    </FormWrapper>
  )
}

export { Form }
