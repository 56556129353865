import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { Alert } from '@mui/material'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

type FlashProps = { message: string; severity?: 'success' | 'info' | 'warning' | 'error' }

type ContextState = {
  isOpen: boolean
  flash: FlashProps | null
  showFlash: (props: FlashProps) => void
  close: () => void
}

const FlashContext = createContext<ContextState | null>(null)

const AlertWrapper = styled(Box)(({}) => ({
  width: '50vw',
  position: 'absolute',
  zIndex: 2000,
  top: '5vh',
  right: '25vw',
}))

export function FlashProvider({ children }: { children: ReactNode }) {
  const [flash, updateFlash] = useState<null | FlashProps>(null)

  const handleClose = () => updateFlash(null)

  useEffect(() => {
    const timer = setTimeout(() => {
      handleClose()
    }, 3000)

    return () => clearTimeout(timer)
  }, [flash, handleClose])

  return (
    <FlashContext.Provider
      value={{
        isOpen: !!flash,
        flash,
        showFlash: (props: FlashProps) => {
          updateFlash(props)
        },
        close: handleClose,
      }}
    >
      {!!flash && (
        <AlertWrapper>
          <Alert variant='filled' severity={flash?.severity ?? 'info'} onClose={handleClose}>
            {flash?.message}
          </Alert>
        </AlertWrapper>
      )}
      {children}
    </FlashContext.Provider>
  )
}

export function useFlash(): ContextState {
  const context = useContext(FlashContext)
  if (!context) throw Error('This hook can only be used under the FlashProvider component')

  return context
}
