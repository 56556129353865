import Box from '@mui/material/Box'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import querystring from 'query-string'

import { Content, CustomButton, CustomPagination, Loading, Row, TextH3Bold, Title } from '@components'
import { ItemEquipmentType, ItemFilters } from '@types'
import { useTranslation } from '@hooks'
import { ROUTES } from '@constants'
import { ItemFiltersDrawer } from '@features/Filters'
import { AppBase } from '@features/AppBase'
import { Images } from '@images'

import { MarketplaceCard } from '../../components'
import { useCollectiblesOnSale } from '../../hooks'

const MarketplaceMain = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const defaultFilters = useMemo(() => {
    const tmpFilters: ItemFilters = { ...querystring.parse(location.search, { arrayFormat: 'bracket-separator' }) }

    if (tmpFilters['teamId']) {
      if (!Array.isArray(tmpFilters['teamId'])) {
        tmpFilters['teamId'] = [tmpFilters['teamId']]
      }
      tmpFilters['team'] = tmpFilters['teamId'].map(tId => ({
        id: Number(tId),
      }))
    }
    if (tmpFilters['brandId']) {
      if (!Array.isArray(tmpFilters['brandId'])) {
        tmpFilters['brandId'] = [tmpFilters['brandId']]
      }
      tmpFilters['brand'] = tmpFilters['brandId'].map(bId => ({
        id: Number(bId),
      }))
      delete tmpFilters['brandId']
    }
    if (tmpFilters['equipmentType']) {
      if (!Array.isArray(tmpFilters['equipmentType'])) {
        tmpFilters['equipmentType'] = [tmpFilters['equipmentType']]
      }
      tmpFilters['equipment'] = tmpFilters['equipmentType'].filter(
        equipmentType =>
          !!equipmentType && Object.values(ItemEquipmentType).includes(equipmentType as ItemEquipmentType),
      )

      delete tmpFilters['equipmentType']
    }

    return tmpFilters
  }, [location.search])

  const { t } = useTranslation()
  const [open, updateOpen] = useState(false)
  const [filters, updateFilters] = useState<ItemFilters>(defaultFilters)

  const { data, isLoading, changeFilters, changePage } = useCollectiblesOnSale()

  useEffect(() => {
    changeFilters(filters)
  }, [filters])

  return (
    <AppBase requireActiveSession={false} backgroundImage={Images.MarketplaceBackground}>
      <Box width={'100%'} px={{ md: 16.5, sm: 8, xs: 2 }} py={4}>
        <Title text={t('marketplace.title')}>
          <Box display={'flex'} flexGrow={1} justifyContent={'flex-end'} alignSelf={'center'} pb={0.5}>
            <CustomButton
              variant={'secondary'}
              label={t('item-filters.filters')}
              onClick={() => updateOpen(true)}
              startIcon={['fas', 'filter']}
            />
          </Box>
        </Title>
        <Content flexDirection={{ xl: 'row', lg: 'row', md: 'row', sm: 'row', xs: 'column' }}>
          {isLoading && <Loading />}
          {data?.data.map(collectible => (
            <MarketplaceCard
              collectible={collectible}
              onClick={() => navigate(`${ROUTES.MARKETPLACE}/${collectible.id}`)}
            />
          ))}
          {!isLoading && data?.data && data?.data.length == 0 && (
            <Box alignSelf={'center'} mt={4}>
              <TextH3Bold>{t('marketplace.no-matching-items')} </TextH3Bold>
            </Box>
          )}
          <ItemFiltersDrawer
            open={open}
            onClose={() => updateOpen(false)}
            defaultValues={defaultFilters}
            onChange={values => {
              updateFilters(values)
              updateOpen(false)
            }}
            showCoins={true}
          />
          <Row>
            {data && data.data.length > 0 && data.lastPage > 0 && (
              <CustomPagination count={data.lastPage} page={data.page} onChange={page => changePage(page)} />
            )}
          </Row>
        </Content>
      </Box>
    </AppBase>
  )
}

export { MarketplaceMain }
