import Box from '@mui/material/Box'
import * as React from 'react'
import { useCallback, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Chip, Grid } from '@mui/material'

import { useModal } from '@contexts'
import { CustomButton, CustomPagination, Modal, TextInput } from '@components'
import { Team, TeamType } from '@types'
import { colors } from '@styles'
import { useTeams, useTranslation } from '@hooks'
import { TeamItem } from '@features/Teams'

interface Props {
  type: TeamType | TeamType[]
  onChange: (value: Team[] | null) => void
  teams?: Team[] | null
  maxTeams?: number
}

const TeamPickerModal = (props: Props) => {
  const { type, onChange, maxTeams = 1 } = props
  const types = Array.isArray(type) ? type : [type]
  const { dismissModal } = useModal()
  const { t } = useTranslation()
  const [selectedTeams, updateSelectedTeams] = useState<Team[]>(props.teams ?? [])

  const { paginationWithFilters, data, changeFilters, changePage } = useTeams({ type: types })
  const { filters } = paginationWithFilters

  const onTeamClick = useCallback(
    (t: Team) => {
      const founded = selectedTeams.find(st => st.name === t.name)
      if (!!founded) {
        updateSelectedTeams(selectedTeams.filter(st => st.name != t.name))
      } else {
        const newTeams = [...selectedTeams, t]
        if (newTeams.length > maxTeams) {
          newTeams.splice(0, newTeams.length - maxTeams)
        }
        updateSelectedTeams(newTeams)
      }
    },
    [selectedTeams, maxTeams],
  )

  const filteredTeams = data?.data ?? []

  const label = useMemo(() => {
    if (types.length != 1) {
      return t('teams.label.all')
    } else if (types.includes(TeamType.NATIONAL_TEAM)) {
      return t('teams.label.national-team')
    } else if (types.includes(TeamType.TEAM)) {
      return t('teams.label.team')
    }

    return ''
  }, [t, types])

  return (
    <Modal.Content sx={{}}>
      <TextInput
        label={label}
        fullWidth
        onChange={e => changeFilters({ ...filters, name: e.target.value })}
        startAdornment={
          <Box mr={1}>
            <FontAwesomeIcon icon={['fas', 'magnifying-glass']} size={'1x'} color={colors.grayscale['30'].background} />
          </Box>
        }
      />
      <Box>
        {selectedTeams.map(st => (
          <Chip
            sx={{ mr: 1, mb: 1 }}
            avatar={<Avatar alt={st.name} src={st.logo} />}
            label={st.name}
            variant='outlined'
            color={'primary'}
            onDelete={() => onTeamClick(st)}
          />
        ))}
      </Box>
      <Grid container spacing={2} columns={16}>
        {filteredTeams.map(t => (
          <Grid item xs={4}>
            <TeamItem {...t} onClick={() => onTeamClick(t)} />
          </Grid>
        ))}
      </Grid>
      {data && filteredTeams.length > 0 && (
        <CustomPagination count={data.lastPage} page={data.page} onChange={page => changePage(page)} />
      )}
      <Modal.Actions>
        <CustomButton
          variant={'secondary'}
          label={t('teams.action.back')}
          fullWidth={true}
          onClick={() => {
            dismissModal()
          }}
        />
        <CustomButton
          variant={'primary'}
          label={t('teams.action.continue')}
          fullWidth={true}
          onClick={() => {
            onChange(selectedTeams)
            dismissModal()
          }}
        />
      </Modal.Actions>
    </Modal.Content>
  )
}

export { TeamPickerModal }
