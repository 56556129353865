import * as React from 'react'
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'

import { AppBase } from '@features/AppBase'
import { Content, CustomButton, CustomPagination, Loading, Row, TextH3Bold, Title } from '@components'
import { PreviousTriviasTable } from '@features/Trivias/components/PreviousTriviasTable'
import { useTranslation } from '@hooks'
import { usePreviousTrivias } from '@features/Trivias/hooks'
import { Images } from '@images'
import { ROUTES } from '@constants'

const TriviaPrevious = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data, isLoading, changePage } = usePreviousTrivias()

  return (
    <AppBase requireActiveSession={false} backgroundImage={Images.TriviasBackground}>
      <Box width={'100%'} px={{ md: 16.5, sm: 8, xs: 2 }} py={4}>
        <Title text={t('previous-trivias.title')} />
        <Content flexDirection={'column'}>
          {isLoading && <Loading />}
          {!isLoading && data?.data && data?.data.length == 0 && (
            <Box alignSelf={'center'} mt={4}>
              <TextH3Bold>{t('previous-trivias.empty')} </TextH3Bold>
            </Box>
          )}
          {data && <PreviousTriviasTable data={data} onChangePage={changePage} />}
          <Row>
            {data && data.data.length > 0 && data.lastPage > 0 && (
              <CustomPagination count={data.lastPage} page={data.page} onChange={page => changePage(page)} />
            )}
          </Row>
        </Content>
      </Box>
        <Box display={'flex'} justifyContent={'center'} pt={4}>
            <CustomButton
              variant={'primary'}
              label={t('previous-trivias.back')}
              onClick={() => navigate(ROUTES.TRIVIAS)}
            />
        </Box>
    </AppBase>
  )
}

export { TriviaPrevious }
