import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { PropsWithChildren, useCallback } from 'react'
import * as React from 'react'
import { LinearProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import {
  CountDown,
  CustomButton,
  RoundedCornersBox,
  TextBodyRegular,
  TextCaptionBold,
  TextH4Bold,
  TextTitleBold,
} from '@components'
import { colors } from '@styles'
import { useModal, useUser } from '@contexts'
import { useTranslation } from '@hooks'

import { Trivia, TriviaClientStatus } from '../../types'
import { LoginModal } from '../../../Login'

const TriviaCardImage = styled('img')(({ theme }) => ({
  borderRadius: theme.spacing(2),
  borderColor: colors.grayscale['30'].background,
  borderWidth: 1,
  borderStyle: 'solid',
  width: '50%',
  height: theme.spacing(50),
  objectFit: 'cover',
}))

const TriviaDetailsColumn = (props: PropsWithChildren) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-evenly'}
      flexWrap={'wrap'}
      gap={3}
      sx={{ width: '50%' }}
    >
      {props.children}
    </Box>
  )
}

interface Props {
  trivia: Trivia
  playEnabled: boolean
}

const TriviaCard = (props: Props) => {
  const { trivia, playEnabled } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { userState } = useUser()
  const { showModal } = useModal()

  const onPlay = useCallback(() => {
    if (userState) {
      navigate(`/trivias/${trivia.id}`)
    } else {
      showModal({ modal: <LoginModal /> })
    }
  }, [userState, trivia])

  return (
    <RoundedCornersBox
      display={'flex'}
      flexDirection={'row'}
      flexBasis={{ xl: '30%', lg: '45%', md: '45%', sm: '50%', xs: '100%' }}
      mb={4}
      p={2}
      gap={2.5}
    >
      <TriviaCardImage src={trivia.imageUrl} />
      <TriviaDetailsColumn>
        <TextTitleBold>{trivia.name}</TextTitleBold>
        <TextBodyRegular>{trivia.description}</TextBodyRegular>
        <Box gap={2} display={'flex'} flexDirection={'column'}>
          {(!trivia.quizClient || trivia.quizClient.status != TriviaClientStatus.FINISHED) && (
            <CountDown dueDate={trivia.endDate} />
          )}
          {!!trivia.quizClient && (
            <Box width={'100%'}>
              <LinearProgress
                variant='determinate'
                value={100 * (trivia.quizClient.questionAnswered / (trivia.quizClient.totalQuestions ?? 1))}
                color={'primary'}
              />
              <TextCaptionBold
                sx={{ textAlign: 'right' }}
              >{`${trivia.quizClient.questionAnswered}/${trivia.quizClient.totalQuestions}`}</TextCaptionBold>
            </Box>
          )}
        </Box>
        {!!trivia.quizClient && trivia.quizClient.status == TriviaClientStatus.FINISHED && (
          <TextH4Bold>
            {t('trivias.points', {
              points: `${trivia.quizClient.points}`,
              ok: trivia.quizClient.correctAnswers,
              total: trivia.quizClient.totalQuestions,
            })}
          </TextH4Bold>
        )}
        {(!trivia.quizClient || trivia.quizClient.status != TriviaClientStatus.FINISHED) && (
          <CustomButton
            variant={'secondary'}
            label={t('trivias.play')}
            onClick={() => onPlay()}
            disabled={!trivia.quizClient && !playEnabled}
          />
        )}
      </TriviaDetailsColumn>
    </RoundedCornersBox>
  )
}

export { TriviaCard }
