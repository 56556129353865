import { useState, MouseEvent, useEffect, useRef } from 'react'
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { colors } from '@styles'

import { TextButtonStyled } from '../Buttons/styled'
import { StyledMenu } from './styled'

interface DropDownMenuItem {
  label: string
  id: string
  icon?: IconProp
  onClick?: () => void
}

interface DropDownMenuProps {
  label: string
  keyProp: string
  options: DropDownMenuItem[]
  openMenu: null | string
  setOpenMenu: (key: null | string) => void
  mouseX: number
  mouseY: number
}

const DropDownMenu = (props: DropDownMenuProps) => {
  const { openMenu, setOpenMenu, label, mouseX, mouseY, keyProp } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const popupRef = useRef<HTMLDivElement | null>(null)
  const open = Boolean(anchorEl) && openMenu == label

  useEffect(() => {
    let mouseOverMenu = false
    let mouseOverPopUp = false
    if (anchorEl) {
      const { left, top, right, bottom } = anchorEl.getBoundingClientRect()
      mouseOverMenu = mouseX >= left && mouseX <= right && mouseY >= top && mouseY <= bottom
    }
    if (popupRef.current) {
      const { left, top, right, bottom } = popupRef.current.getBoundingClientRect()
      mouseOverPopUp = mouseX >= left && mouseX <= right && mouseY >= top - 20 && mouseY <= bottom
    }
    if (mouseOverMenu || mouseOverPopUp) {
      setOpenMenu(label)
    } else {
      setAnchorEl(null)
    }
  }, [anchorEl, mouseX, mouseY])

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setOpenMenu(label)
  }

  const handleClose = () => {
    setOpenMenu(null)
  }

  const handleOptionClick = (option: DropDownMenuItem) => {
    handleClose()
    if (!!option && option?.onClick) {
      option.onClick()
    }
  }

  return (
    <div key={keyProp}>
      <TextButtonStyled
        className={'ShiDropDownMenu-button'}
        id={`${props.keyProp}-button`}
        aria-controls={open ? `${props.keyProp}-menu` : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        sx={{ color: '#ffffff' }}
        onClick={handleClick}
        onMouseOver={handleClick}
        variant={'text'}
        endIcon={<FontAwesomeIcon icon={'chevron-down'} style={{ height: 14 }} />}
      >
        {props.label}
      </TextButtonStyled>
      <StyledMenu
        className={'ShiDropDownMenu-menu'}
        id={`${props.keyProp}-menu`}
        anchorEl={open ? anchorEl : null}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `${props.keyProp}-button`,
        }}
        slotProps={{
          paper: {
            onMouseLeave: () => handleClose(),
            ref: popupRef,
          },
        }}
      >
        {props.options.map((option, index) => (
          <MenuItem
            onClick={() => handleOptionClick(option)}
            className={'ShiDropDownMenu-item'}
            key={`menu-item-${index}`}
          >
            {!!option.icon && (
              <ListItemIcon>
                <FontAwesomeIcon icon={option.icon} color={colors.grayscale['10'].background} />
              </ListItemIcon>
            )}
            <ListItemText className={'ShiDropDownMenu-item-label'}> {option.label}</ListItemText>
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  )
}

export { DropDownMenu }
