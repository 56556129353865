import { Slider } from '@mui/material'

import { useTranslation } from '@hooks'
import { Column, MaskedTextInput, Row, TextH4Bold } from '@components'

import { CentralCell, LeftCell, RightCell } from '../styled'

interface BallPossessionRowProps {
  localValue: number
  visitorValue: number
  onLocalChange: (name: string, value: number) => void
  onVisitorChange: (name: string, value: number) => void
}
const BallPossessionRow = (props: BallPossessionRowProps) => {
  const { onLocalChange, onVisitorChange, localValue, visitorValue } = props
  const { t } = useTranslation()
  const inputName = 'possession'

  const fixNumber = (value: number) => {
    if (value > 100) {
      return 100
    }

    return value
  }

  return (
    <Row alignItems={'center'}>
      <LeftCell sx={{ width: '25%' }}>
        <MaskedTextInput
          definitions={{ '#': /^\d+$/ }}
          mask={'###'}
          name={inputName}
          onChange={event =>
            !!event.target.value && onLocalChange(inputName, fixNumber(Number(event.target.value) ?? 0))
          }
          onBlur={event => !event.target.value && onLocalChange(inputName, fixNumber(Number(event.target.value) ?? 0))}
          value={`${localValue}`}
        />
      </LeftCell>
      <CentralCell sx={{ width: '50%' }}>
        <Column flexGrow={1} sx={{ gap: 0 }}>
          <TextH4Bold sx={{ textAlign: 'center' }}>{t('predictions.form.possession')}</TextH4Bold>
          <Slider
            sx={theme => ({
              '& .MuiSlider-rail': {
                color: theme.palette.primary.light,
                opacity: 1,
                border: '1px solid',
              },
              marginBottom: '16px',
            })}
            max={100}
            value={localValue}
            onChange={(event, value) => onLocalChange(inputName, fixNumber(value as number))}
          />
        </Column>
      </CentralCell>
      <RightCell sx={{ width: '25%' }}>
        <MaskedTextInput
          definitions={{ '#': /^\d+$/ }}
          mask={'###'}
          name={inputName}
          onChange={event =>
            !!event.target.value && onVisitorChange(inputName, fixNumber(Number(event.target.value) ?? 0))
          }
          onBlur={event =>
            !event.target.value && onVisitorChange(inputName, fixNumber(Number(event.target.value) ?? 0))
          }
          value={`${visitorValue}`}
        />
      </RightCell>
    </Row>
  )
}

export { BallPossessionRow }
