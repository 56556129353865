import { api } from '@api'
import { PaginatedRequest, PaginatedResponse } from '@types'

import { RankingTrivia, RankingTriviasFilters, Trivia, TriviasFilters } from '../types'

const getById = async (id: string | number) => {
  const response = await api.get<Trivia>(`/quizzes/${id}`)

  return response.data
}
const getTrivias = async () => {
  const response = await api.get<Trivia[]>(`/quizzes`)

  return response.data
}

const paginatePreviousTrivias = async (params: PaginatedRequest<TriviasFilters>) => {
  const { page, filters } = params
  const response = await api.get<PaginatedResponse<Trivia>>('/quizzes/previous', { params: { page, ...filters } })

  return response.data
}

const paginateRanking = async (params: PaginatedRequest<RankingTriviasFilters>) => {
  const { page = 0, pageSize = 10, filters } = params
  const response = await api.get<PaginatedResponse<RankingTrivia>>('/quizzes/ranking', {
    params: { page, pageSize, ...filters },
  })

  return response.data
}
const startTrivia = async (id: string | number) => {
  const response = await api.post<Trivia>(`/quizzes/${id}/start`)

  return response.data
}

const saveAnswer = async (id: string | number, questionId: number, answersIds: number[]) => {
  const response = await api.post<Trivia>(`/quizzes/${id}`, { questionId, answersIds })

  return response.data
}

export const TriviasApi = { getById, getTrivias, startTrivia, saveAnswer, paginatePreviousTrivias, paginateRanking }
