import { QUERY_KEYS } from '@api'
import { usePaginatedRequestWithFilters } from '@hooks'
import { TriviasApi } from '@features/Trivias/api'
import { RankingTrivia, RankingTriviasFilters } from '@features/Trivias/types'

const useTriviaRanking = (triviaId: number) => {
  const { isLoading, data, changeFilters, changePage, paginationWithFilters } = usePaginatedRequestWithFilters<
    RankingTriviasFilters,
    RankingTrivia
  >({ triviaId }, `${QUERY_KEYS.TRIVIA_RANKING}-${triviaId}`, TriviasApi.paginateRanking)

  return { isLoading, data, changeFilters, changePage, paginationWithFilters }
}

export { useTriviaRanking }
