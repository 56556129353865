import { api } from '@api'

import { Challenge, CollectedItemDto } from '../features/Challenges/types'

const getById = async (itemId: string | number) => {
  const response = await api.get<Challenge>(`/challenges/${itemId}`)

  return response.data
}

const getChallenges = async (page: string | number) => {
  const params = new URLSearchParams()
  params.set('page', `${page}`)
  params.set('pageSize', '50')
  const response = await api.get<Challenge[]>(`/challenges`, { params })

  return response.data
}

const saveCollectedItems = async (challengeId: string | number, collectedItems: CollectedItemDto[]) => {
  const response = await api.post<Challenge>(`/challenges/${challengeId}`, { collectedItems })

  return response.data
}

export const ChallengesApi = { getById, getChallenges, saveCollectedItems }
