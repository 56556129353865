export class CountryCode {
  code: string
  country: string
  flag: string
  constructor(code: string, country: string, flag: string) {
    this.code = code
    this.country = country
    this.flag = flag
  }
  toString() {
    return this.code ? `+${this.code} | ${this.country}` : '-----'
  }
}
