import * as React from 'react'

import { CustomButton } from '@components'
import { useSessionUserUpdater, useTranslation } from '@hooks'

import { useActivationCodePayment } from '../../hooks/useActivationCodePayment'

interface Props {
  onSuccess: () => void
}
const BuyActivationCodeButton = (props: Props) => {
  const { t } = useTranslation()
  const { onSuccess } = props

  const { refresh } = useSessionUserUpdater()
  const { payActivationCode, isLoading } = useActivationCodePayment(() => {
    refresh()
    onSuccess()
  })

  const makePayment = () => {
    payActivationCode()
  }

  return (
    <CustomButton
      variant={'primary'}
      label={t('coins.buy.activation-code-button')}
      disabled={isLoading}
      onClick={makePayment}
    />
  )
}

export { BuyActivationCodeButton }
