import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CustomButton, RoundedCornersBox, TextBodyRegular, TextH2Bold } from '@components'
import { useTranslation } from '@hooks'
import { colors } from '@styles'
import { useFlash, useModal } from '@contexts'
import { useSavePrediction } from '@features/Predictions/hooks'
import { ROUTES } from '@constants'

import { PredictionSuccessModal, PredictionConfirmModal } from '../../modals'
import { Player, Prediction, TeamStatistics, Statistics } from '../../types'
import { PredictionRow } from '../PredictionRow'
import { NumericPredictionRow } from '../NumericPredictionRow'
import { ScorersRow } from '../ScorersRow'
import { BallPossessionRow } from '../BallPossessionRow'
import { useSessionUserUpdater } from '../../../../hooks/useSessionUserUpdater'

interface Props {
  prediction: Prediction
}

const initalTeamStatistics: TeamStatistics = {
  goals: 0,
  possession: 50,
  offsides: 0,
  cornerKicks: 0,
  shotsOffTarget: 0,
  shotsOnTarget: 0,
  blockedScoringAttempts: 0,
  goalkeeperSaves: 0,
  tackles: 0,
  totalPasses: 0,
  completedPasses: 0,
  fouls: 0,
  yellowCards: 0,
  redCards: 0,
  scorers: [],
}
const PredictionForm = (props: Props) => {
  const { prediction } = props
  const { t } = useTranslation()
  const { showModal } = useModal()
  const { showFlash } = useFlash()
  const navigate = useNavigate()
  const { refresh } = useSessionUserUpdater({ onSuccess: () => navigate(ROUTES.PREDICTIONS) })

  const { savePrediction, isLoading } = useSavePrediction(
    prediction.id,
    () => {
      showModal({ modal: <PredictionSuccessModal />, options: { onDismiss: refresh } })
    },
    () => {
      showFlash({ message: t('predictions.view.error'), severity: 'error' })
    },
  )
  const [form, updateForm] = useState<Statistics>({
    localStats: { ...(prediction.clientPrediction?.statistics?.localStats ?? initalTeamStatistics) },
    visitorStats: { ...(prediction.clientPrediction?.statistics?.visitorStats ?? initalTeamStatistics) },
  })

  const onLocalChange = (name: string, value: number | string | Player[]) => {
    let { localStats, visitorStats } = form
    if (name === 'possession') {
      visitorStats = { ...visitorStats, possession: 100 - (value as number) }
    } else if (name === 'goals' && value === 0) {
      localStats = { ...localStats, scorers: [] }
    }
    updateForm({ localStats: { ...localStats, [name]: value }, visitorStats })
  }

  const onVisitorChange = (name: string, value: number | string | Player[]) => {
    let { visitorStats, localStats } = form
    if (name === 'possession') {
      localStats = { ...localStats, possession: 100 - (value as number) }
    } else if (name === 'goals' && value === 0) {
      visitorStats = { ...visitorStats, scorers: [] }
    }
    updateForm({ localStats, visitorStats: { ...visitorStats, [name]: value } })
  }

  const onSubmit = useCallback(() => {
    if (!isLoading) {
      savePrediction(form)
    }
  }, [prediction, form, isLoading])

  const showConfirm = useCallback(() => {
    showModal({
      modal: (
        <PredictionConfirmModal
          onConfirm={() => {
            onSubmit()
          }}
        />
      ),
    })
  }, [onSubmit])

  return (
    <RoundedCornersBox display={'flex'} flexDirection={'column'} width={'100%'} padding={2}>
      <PredictionRow
        sx={theme => ({ marginBottom: theme.spacing(2) })}
        leftCellContent={<TextBodyRegular>{t('predictions.form.header-results')}</TextBodyRegular>}
        centralCellContent={
          <TextH2Bold sx={{ color: colors.primary.main.background, textAlign: 'center' }}>
            {t('predictions.form.header-stats')}
          </TextH2Bold>
        }
        rightCellContent={<TextBodyRegular>{t('predictions.form.header-results')}</TextBodyRegular>}
      />
      <NumericPredictionRow
        onLocalChange={onLocalChange}
        onVisitorChange={onVisitorChange}
        inputName={'goals'}
        title={t('predictions.form.goals')}
        localValue={form.localStats.goals}
        visitorValue={form.visitorStats.goals}
      />
      <ScorersRow
        match={prediction.match}
        onVisitorChange={value => onVisitorChange('scorers', value)}
        onLocalChange={value => onLocalChange('scorers', value)}
        maxLocalPlayers={form.localStats.goals}
        maxVisitorPlayers={form.visitorStats.goals}
        localPlayers={form.localStats.scorers}
        visitorPlayers={form.visitorStats.scorers}
      />

      <BallPossessionRow
        onLocalChange={onLocalChange}
        onVisitorChange={onVisitorChange}
        localValue={form.localStats.possession}
        visitorValue={form.visitorStats.possession}
      />
      <NumericPredictionRow
        onLocalChange={onLocalChange}
        onVisitorChange={onVisitorChange}
        inputName={'offsides'}
        title={t('predictions.form.offsides')}
        localValue={form.localStats.offsides}
        visitorValue={form.visitorStats.offsides}
      />
      <NumericPredictionRow
        onLocalChange={onLocalChange}
        onVisitorChange={onVisitorChange}
        inputName={'cornerKicks'}
        title={t('predictions.form.corner-kicks')}
        localValue={form.localStats.cornerKicks}
        visitorValue={form.visitorStats.cornerKicks}
      />
      <NumericPredictionRow
        onLocalChange={onLocalChange}
        onVisitorChange={onVisitorChange}
        inputName={'shotsOffTarget'}
        title={t('predictions.form.shots-off-target')}
        localValue={form.localStats.shotsOffTarget}
        visitorValue={form.visitorStats.shotsOffTarget}
      />
      <NumericPredictionRow
        onLocalChange={onLocalChange}
        onVisitorChange={onVisitorChange}
        inputName={'shotsOnTarget'}
        title={t('predictions.form.shots-on-target')}
        localValue={form.localStats.shotsOnTarget}
        visitorValue={form.visitorStats.shotsOnTarget}
      />
      <NumericPredictionRow
        onLocalChange={onLocalChange}
        onVisitorChange={onVisitorChange}
        inputName={'blockedScoringAttempts'}
        title={t('predictions.form.blocked-scoring-attempts')}
        localValue={form.localStats.blockedScoringAttempts}
        visitorValue={form.visitorStats.blockedScoringAttempts}
      />
      <NumericPredictionRow
        onLocalChange={onLocalChange}
        onVisitorChange={onVisitorChange}
        inputName={'goalkeeperSaves'}
        title={t('predictions.form.goalkeeper-saves')}
        localValue={form.localStats.goalkeeperSaves}
        visitorValue={form.visitorStats.goalkeeperSaves}
      />
      <NumericPredictionRow
        onLocalChange={onLocalChange}
        onVisitorChange={onVisitorChange}
        inputName={'tackles'}
        title={t('predictions.form.tackles')}
        localValue={form.localStats.tackles}
        visitorValue={form.visitorStats.tackles}
      />
      <NumericPredictionRow
        onLocalChange={onLocalChange}
        onVisitorChange={onVisitorChange}
        inputName={'totalPasses'}
        title={t('predictions.form.total-passes')}
        localValue={form.localStats.totalPasses}
        visitorValue={form.visitorStats.totalPasses}
      />
      <NumericPredictionRow
        onLocalChange={onLocalChange}
        onVisitorChange={onVisitorChange}
        inputName={'completedPasses'}
        title={t('predictions.form.completed-passes')}
        localValue={form.localStats.completedPasses}
        visitorValue={form.visitorStats.completedPasses}
      />
      <NumericPredictionRow
        onLocalChange={onLocalChange}
        onVisitorChange={onVisitorChange}
        inputName={'fouls'}
        title={t('predictions.form.fouls')}
        localValue={form.localStats.fouls}
        visitorValue={form.visitorStats.fouls}
      />
      <NumericPredictionRow
        onLocalChange={onLocalChange}
        onVisitorChange={onVisitorChange}
        inputName={'yellowCards'}
        title={t('predictions.form.yellow-cards')}
        localValue={form.localStats.yellowCards}
        visitorValue={form.visitorStats.yellowCards}
      />
      <NumericPredictionRow
        onLocalChange={onLocalChange}
        onVisitorChange={onVisitorChange}
        inputName={'redCards'}
        title={t('predictions.form.red-cards')}
        localValue={form.localStats.redCards}
        visitorValue={form.visitorStats.redCards}
      />
      <CustomButton
        variant={'primary'}
        label={t(!prediction.clientPrediction ? 'predictions.form.save' : 'predictions.form.edit')}
        onClick={showConfirm}
        disabled={isLoading}
      />
    </RoundedCornersBox>
  )
}

export { PredictionForm }
