import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { useModal } from '@contexts'
import { useTranslation } from '@hooks'
import { CustomButton, Modal, TextH2Bold } from '@components'
import { Images } from '@images'

interface Props {
  onClose: () => void
  actionPath: string
}
const AnswerPollToWinPrizeModal = (props: Props) => {
  const { actionPath, onClose } = props
  const { dismissModal } = useModal()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const onContinue = () => {
    dismissModal()
    navigate(actionPath)
  }

  return (
    <Modal.Content>
      <Modal.Header image={Images.InformativeImage}>
        <TextH2Bold>{t('landing.modals.answer-poll-to-win.title')}</TextH2Bold>
      </Modal.Header>
      <Modal.Actions>
        <CustomButton
          variant={'secondary'}
          label={t('landing.modals.answer-poll-to-win.cancel')}
          fullWidth={true}
          onClick={() => {
            onClose()
            dismissModal()
          }}
        />
        <CustomButton
          variant={'primary'}
          label={t('landing.modals.answer-poll-to-win.continue')}
          fullWidth={true}
          onClick={() => onContinue()}
        />
      </Modal.Actions>
    </Modal.Content>
  )
}

export { AnswerPollToWinPrizeModal }
