import { Avatar } from '@mui/material'
import * as React from 'react'

import { Row, TextH2Bold } from '@components'
import { Player } from '@features/Predictions/types'
import { Images } from '@images'

import { CentralCell, LeftCell, RightCell } from '../styled'

interface AlignmentRowProps {
  local: Player | undefined
  visitor: Player | undefined
}

const AlignmentRow = (props: AlignmentRowProps) => {
  const { local, visitor } = props

  const formatPlayer = (player: Player | undefined, inverted: boolean) => {
    if (!player) return '-'
    const { name, dorsal } = player
    if (inverted) {
      return dorsal ? `${name} [${dorsal}]` : `${name}`
    }

    return dorsal ? `[${dorsal}] ${name}` : `${name}`
  }

  return (
    <Row alignItems={'center'} sx={theme => ({ marginBottom: theme.spacing(2) })}>
      <LeftCell sx={{ alignItems: 'center', width: '50%' }}>
        {local && (
          <Avatar alt={local.name} src={local.image ?? Images.Placeholder} sx={{ height: 24, width: 24, mx: 1 }} />
        )}
        <TextH2Bold>{formatPlayer(local, false)}</TextH2Bold>
      </LeftCell>
      <CentralCell></CentralCell>
      <RightCell sx={{ alignItems: 'center', width: '50%' }}>
        <TextH2Bold>{formatPlayer(visitor, true)}</TextH2Bold>
        {visitor && (
          <Avatar alt={visitor.name} src={visitor.image ?? Images.Placeholder} sx={{ height: 24, width: 24, mx: 1 }} />
        )}
      </RightCell>
    </Row>
  )
}

export { AlignmentRow }
