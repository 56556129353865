import { Tab, Tabs } from '@mui/material'
import Box from '@mui/material/Box'
import { PropsWithChildren, SyntheticEvent, useState } from 'react'
import { styled } from '@mui/material/styles'

import { RoundedCornersBox } from '@components'
import { Prediction } from '@features/Predictions/types'
import { colors } from '@styles'
import { useTranslation } from '@hooks'

import { PredictionTabs } from '../PredictionTabs'

interface TabPanelProps {
  index: number
  value: number
}
const CustomTabPanel = (props: PropsWithChildren<TabPanelProps>) => {
  const { children, value, index, ...other } = props

  return (
    <div role='tabpanel' hidden={value !== index} id={`tabpanel-${index}`} {...other}>
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  )
}

const StyledTab = styled(Tab)(() => ({
  color: colors.grayscale['10'].background,
  '&.Mui-selected': {
    color: colors.grayscale['20'].background,
  },
}))

interface Props {
  prediction: Prediction
}
const PredictionDetailsTabs = (props: Props) => {
  const { prediction } = props
  const [value, setValue] = useState(0)
  const { t } = useTranslation()
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <RoundedCornersBox width={'100%'} minHeight={'30vh'}>
      <Box sx={{ borderBottom: 1, borderColor: colors.grayscale['30'].background }}>
        <Tabs value={value} onChange={handleChange} aria-label='tabs'>
          <StyledTab label={t('predictions.tabs.alignment')} id={'tab-alignment'} aria-controls={'tabpnel-alignment'} />
          <StyledTab
            label={t('predictions.tabs.statistics')}
            id={'tab-statistics'}
            aria-controls={'tabpanel-statistics'}
          />
          <StyledTab
            label={t('predictions.tabs.your-prediction')}
            id={'tab-your-predictions'}
            aria-controls={'tabpanel-your-predictions'}
          />
          <StyledTab label={t('predictions.tabs.ranking')} id={'tab-ranking'} aria-controls={'tabpanel-ranking'} />
        </Tabs>
      </Box>
      <CustomTabPanel index={0} value={value}>
        <PredictionTabs.AlignmentTab
          alignment={{
            localTeamSquad: prediction.match.localTeamSquad ?? [],
            visitorTeamSquad: prediction.match.visitorTeamSquad ?? [],
          }}
        />
      </CustomTabPanel>
      <CustomTabPanel index={1} value={value}>
        <PredictionTabs.StatisticsTab statistics={prediction.match.statistics} />
      </CustomTabPanel>
      <CustomTabPanel index={2} value={value}>
        <PredictionTabs.YourPredictionTab
          statistics={prediction.match.statistics}
          prediction={prediction.clientPrediction?.statistics}
        />
      </CustomTabPanel>
      <CustomTabPanel index={3} value={value}>
        <PredictionTabs.RankingTab prediction={prediction} />
      </CustomTabPanel>
    </RoundedCornersBox>
  )
}

export { PredictionDetailsTabs }
