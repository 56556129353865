import { AuthResponse } from '@types'

import { api } from './axios'

interface Payload {
  username: string
  password: string
}

const login = async (payload: Payload) => {
  const body = { ...payload }
  const response = await api.post<AuthResponse>('/client-auth/login', body)

  return response.data
}

interface GooglePayload {
  token: string
}

const loginGoogle = async (payload: GooglePayload) => {
  const body = { ...payload }
  const response = await api.post<AuthResponse>('/client-auth/google', body)

  return response.data
}

const status = async (redirectOnError = false) => {
  await api.get(`/client-auth/status?redirect=${redirectOnError ? 1 : 0}`)
}

const forgotPassword = async (payload: { username: string }) => {
  const body = { ...payload }
  const response = await api.post<void>('/client-auth/forgot-password', body)

  return response.data
}

const changePassword = async (payload: { username: string; password: string; token: string }) => {
  const body = { ...payload }
  const response = await api.post<void>('/client-auth/change-password', body)

  return response.data
}

const validateEmail = async (payload: { username: string; token: string }) => {
  const body = { ...payload }
  const response = await api.post<void>('/client-auth/validate-email', body)

  return response.data
}

const resendValidationEmail = async (payload: { username: string }) => {
  const body = { ...payload }
  const response = await api.post<void>('/client-auth/resend-validation-email', body)

  return response.data
}

export const AuthApi = {
  login,
  loginGoogle,
  status,
  forgotPassword,
  changePassword,
  validateEmail,
  resendValidationEmail,
}
