import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box'
import * as React from 'react'

import { useModal } from '@contexts'
import { CustomButton, Modal, TextH2Bold, TextH4Bold } from '@components'
import { Images } from '@images'
import { colors } from '@styles'
import { useTranslation } from '@hooks'

const ForgotPasswordEmailSentModal = () => {
  const { dismissModal } = useModal()
  const { t } = useTranslation()

  return (
    <Modal.Content>
      <Modal.Header image={Images.InformativeImage}>
        <FontAwesomeIcon icon={['far', 'circle-check']} size={'3x'} color={colors.status.success.background} />
        <Box display={'flex'} flexDirection={'column'} gap={0} pt={1.5}>
          <TextH2Bold>{t('forgot-password.modals.email-sent.title')}</TextH2Bold>
          <TextH4Bold>{t('forgot-password.modals.email-sent.subtitle')}</TextH4Bold>
        </Box>
      </Modal.Header>
      <Modal.Actions>
        <CustomButton
          variant={'primary'}
          label={t('forgot-password.modals.email-sent.actions.continue')}
          fullWidth={true}
          onClick={() => dismissModal()}
        />
      </Modal.Actions>
    </Modal.Content>
  )
}

export { ForgotPasswordEmailSentModal }
