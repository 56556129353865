import { Box, useMediaQuery } from '@mui/material'
import { PropsWithChildren, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconButton from '@mui/material/IconButton'
import * as React from 'react'
import { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx'

import { colors, MediaQuery, MqTablet } from '@styles'
import { RoundedCornersBox } from '@components'

interface BaseModalProps extends PropsWithChildren {
  handleClose?: () => void
  width?: string | number
  height?: string | number
  borderColor?: string
}

const Base = (props: BaseModalProps) => {
  const isTabletOrDesktop = useMediaQuery(MediaQuery[MqTablet].replace(/^@media( ?)/m, ''))

  const style = useCallback(() => {
    return (
      isTabletOrDesktop
        ? {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: props.width ?? 500,
            height: props.height ?? 'auto',
            borderColor: props.borderColor ?? colors.grayscale['20'].background,
          }
        : {
            width: '100%',
            height: '100%',
            border: 'none',
            borderRadius: 0,
            boxShadow: 'none',
          }
    ) as SystemStyleObject
  }, [isTabletOrDesktop])

  return (
    <RoundedCornersBox sx={style} display={'flex'} flexDirection={'column'}>
      <Box alignItems={'flex-end'} flexDirection={'column'} display={'flex'} p={3}>
        <IconButton
          className={'ShiModal-button-close'}
          aria-label='close'
          size='large'
          sx={{ color: colors.grayscale['10'].background, p: 0 }}
          onClick={props.handleClose}
        >
          <FontAwesomeIcon icon={['fas', 'xmark']} />
        </IconButton>
      </Box>
      {props.children}
    </RoundedCornersBox>
  )
}

export { Base }
