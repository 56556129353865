import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const Row = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'center',
  gap: theme.spacing(2),
}))

export const Column = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
}))

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: theme.spacing(2.5),
  alignItems: 'flex-start',
}))

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingTop: theme.spacing(4),
  flexDirection: 'row',
  justifyContent: 'center',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: theme.spacing(4),
}))
