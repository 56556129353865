import { ReactNode } from 'react'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import { styled } from '@mui/material'

import { Images } from '@images'
import { useCheckActiveSession } from '@hooks'

import { NavbarPanel } from '../NavbarPanel'
import { Footer } from '../Footer'

interface AppLayoutProps {
  children: ReactNode
  backgroundImage?: string
  requireActiveSession: boolean
}

const Background = styled('div')(() => ({
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundOpacity: '25%',
  width: '100%',
  height: '100%',
  display: 'flex',
  position: 'absolute',
  zIndex: -1,
  opacity: 0.25,
}))

const AppBase = ({ children, backgroundImage = Images.Background, requireActiveSession }: AppLayoutProps) => {
  useCheckActiveSession(requireActiveSession)

  return (
    <>
      <NavbarPanel />
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Background sx={{ backgroundImage: `url(${backgroundImage})` }} />
        {children}
      </Box>
      <Footer />
    </>
  )
}

export { AppBase }
