import * as process from 'process'

const config = {
  baseURL: process.env.REACT_APP_API_URL,
  googleClientID: process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '',
  gtmId: process.env.REACT_APP_GTM_ID ?? '',
  partnerId: process.env.REACT_APP_PARTNER_ID ?? '',
}

export { config }
