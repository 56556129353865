import { usePaginatedRequestWithFilters } from '@hooks'
import { QUERY_KEYS } from '@api'
import { PredictionsApi } from '@features/Predictions/api'
import { RankingPrediction, RankingPredictionsFilters } from '@features/Predictions/types'

const usePredictionRanking = (predictionId: number) => {
  const { isLoading, data, changeFilters, changePage, paginationWithFilters } = usePaginatedRequestWithFilters<
    RankingPredictionsFilters,
    RankingPrediction
  >({ predictionId }, `${QUERY_KEYS.PREDICTION_RANKING}-${predictionId}`, PredictionsApi.paginateRanking)

  return { isLoading, data, changeFilters, changePage, paginationWithFilters }
}

export { usePredictionRanking }
