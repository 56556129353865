import * as React from 'react'
import { useCallback, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CustomButton, CustomIconButton, TextTitleBold } from '@components'
import { PollMediaType, PollOption } from '@features/Polls/types'
import { useTranslation } from '@hooks'
import { colors } from '@styles'

import {
  PollOptionCardBodyBlock,
  PollOptionCardBottom,
  PollOptionCardContentBlock,
  PollOptionCardImage,
  PollOptionCardVideo,
} from './styled'

interface PollOptionCardProps {
  option: PollOption
  onClick?: () => void
  clientOption: PollOption | null | undefined
}

const PollOptionCard = (props: PollOptionCardProps) => {
  const { option, onClick, clientOption } = props
  const { t } = useTranslation()
  const videoRef = React.useRef<any>()
  const [muted, setMuted] = useState(true)
  const [playing, setPlaying] = useState(false)
  const onPlay = () => {
    if (videoRef.current) {
      videoRef.current
        .play()
        .then(() => setPlaying(true))
        .catch(() => {})
    }
  }

  const onPause = () => {
    if (videoRef.current) {
      videoRef.current.pause()
      const src = videoRef.current.currentSrc
      videoRef.current.src = ''
      videoRef.current.src = src
      setPlaying(false)
    }
  }

  const toggleMuted = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.muted = !muted
    }
    setMuted(!muted)
  }, [muted])

  return (
    <PollOptionCardContentBlock
      onMouseEnter={onPlay}
      onMouseLeave={onPause}
      className={clientOption?.id === option.id ? 'checked' : ''}
    >
      <PollOptionCardBodyBlock id={'body'}>
        <CustomIconButton
          variant={'link'}
          icon={['fas', muted ? 'volume-mute' : 'volume-high']}
          sx={theme => ({
            visibility: playing ? 'visible' : 'hidden',
            position: 'absolute',
            top: 10,
            right: 10,
            color: 'white',
            opacity: 0.4,
            marginTop: theme.spacing(1),
            zIndex: 10,
          })}
          onClick={() => toggleMuted()}
        />
        {option?.mediaType === PollMediaType.PICTURE && (
          <PollOptionCardImage
            src={option.mediaUrl}
            id={'media'}
            style={
              option.mediaThumbnailUrl !== ''
                ? {
                    backgroundImage: `url(${option.mediaThumbnailUrl}) no-repeat;`,
                  }
                : undefined
            }
          />
        )}
        {option?.mediaType === PollMediaType.VIDEO && (
          <PollOptionCardVideo
            id={'media'}
            ref={videoRef}
            muted
            loop
            preload={'auto'}
            poster={option.mediaThumbnailUrl !== '' ? option.mediaThumbnailUrl : undefined}
          >
            <source src={option.mediaUrl} />
          </PollOptionCardVideo>
        )}
        <TextTitleBold>{option.description}</TextTitleBold>
      </PollOptionCardBodyBlock>
      <PollOptionCardBottom id={'actions'}>
        {clientOption?.id === option.id ? (
          <FontAwesomeIcon icon={['fas', 'check-circle']} color={colors.primary.main.background} size={'3x'} />
        ) : (
          <CustomButton
            variant={'primary'}
            label={t('poll.actions.vote')}
            fullWidth
            onClick={onClick}
            disabled={!!clientOption}
          />
        )}
      </PollOptionCardBottom>
    </PollOptionCardContentBlock>
  )
}

export { PollOptionCard }
