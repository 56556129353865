import Box from '@mui/material/Box'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { Title, ItemCard, Content, TextH2Bold, Column, CustomButton } from '@components'
import { useTranslation } from '@hooks'
import { ROUTES } from '@constants'
import { AppBase } from '@features/AppBase'
import { Images } from '@images'

import { useMyItems } from '../../hooks'

const GalleryMain = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { data } = useMyItems(0)

  return (
    <AppBase requireActiveSession backgroundImage={Images.GalleryBackground}>
      <Box width={'100%'} px={{ md: 16.5, sm: 8, xs: 2 }} py={4}>
        <Title text={t('my-shirts.title')} />
        <Content flexDirection={{ sm: 'row', xs: 'column' }}>
          {data?.data.map(item => (
            <ItemCard item={item} onClick={() => navigate(`/my-shirts/${item.id}`)} key={`item-${item.id}`} />
          ))}
          {(data?.data.length ?? 0) == 0 && (
            <Column>
              <TextH2Bold>{t('my-shirts.empty')}</TextH2Bold>
              <CustomButton variant={'primary'} label={t('nav.digitize')} onClick={() => navigate(ROUTES.DIGITIZE)} />
            </Column>
          )}
        </Content>
      </Box>
    </AppBase>
  )
}

export { GalleryMain }
