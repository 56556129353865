import * as React from 'react'
import { useMediaQuery } from '@mui/material'
import { useMemo } from 'react'

import { CustomButton, TextBodyRegular, TextTitleBold } from '@components'
import { PropsWithCollectible } from '@types'
import { Images } from '@images'
import { useMetadataToText, useTranslation } from '@hooks'
import { MediaQuery, MqTablet } from '@styles'

import {
  MarketplaceItemCardBodyBlock,
  MarketplaceItemCardBottom,
  MarketplaceItemCardContentBlock,
  MarketplaceItemCardImage,
} from './styled'

const MarketplaceCard = (props: PropsWithCollectible<{ onClick?: () => void }>) => {
  const { collectible, onClick } = props
  const { getName } = useMetadataToText()
  const { t } = useTranslation()

  const isTabletOrDesktop = useMediaQuery(MediaQuery[MqTablet].replace(/^@media( ?)/m, ''))

  const name = useMemo(() => {
    const itemName = getName(collectible?.metadata)
    const lenLimit = isTabletOrDesktop ? 35 : 22
    if (itemName.length > lenLimit) {
      return itemName.slice(0, lenLimit - 3) + '...'
    }

    return itemName
  }, [collectible, isTabletOrDesktop])

  return (
    <MarketplaceItemCardContentBlock>
      <MarketplaceItemCardBodyBlock id={'body'}>
        <MarketplaceItemCardImage
          src={collectible?.metadata.mediaFiles.frontImage ?? Images.TShirtPlaceholder}
          id={'image'}
        />
        <TextTitleBold>{name}</TextTitleBold>
        <TextBodyRegular>{t('marketplace.item.price', { coins: collectible?.price })}</TextBodyRegular>
      </MarketplaceItemCardBodyBlock>
      <MarketplaceItemCardBottom id={'actions'}>
        <CustomButton variant={'primary'} label={t('marketplace.item.buy')} fullWidth onClick={onClick} />
      </MarketplaceItemCardBottom>
    </MarketplaceItemCardContentBlock>
  )
}

export { MarketplaceCard }
