import { useQuery } from 'react-query'
import { useState } from 'react'

import { QUERY_KEYS, ChallengesApi } from '@api'

const useChallenges = () => {
  const [page, changePage] = useState(0)

  const { data, isLoading, refetch } = useQuery(
    [QUERY_KEYS.CHALLENGES, page],
    () => ChallengesApi.getChallenges(page),
    {
      refetchOnWindowFocus: false,
    },
  )

  return { data, isLoading, changePage, refetch }
}

export { useChallenges }
