import { Collectible, Metadata } from '@types'

export type PropsWithChallenge<P = unknown> = P & { challenge?: Challenge | undefined }

export enum ChallengeType {
  TEMPORARY = 'TEMPORARY',
  ALBUM = 'ALBUM',
}

export enum ChallengeClientStatus {
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
}

export type ChallengeRequirementItem = {
  id: number
  metadata: Partial<Metadata>
  collectible?: Collectible
}

export type ChallengeClient = {
  totalCollectedItems: number
  totalRequiredItems: number
  status: ChallengeClientStatus
  points: number
}
export type Challenge = {
  id: number
  imageUrl: string
  name: string
  description: string
  type: ChallengeType
  startDate: Date
  endDate: Date
  enabled: boolean
  requirements?: ChallengeRequirementItem[]
  challengeClient?: ChallengeClient
}

export interface CollectedItemDto {
  requiredItemId: number
  collectibleId: number
}
