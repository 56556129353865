import { Drawer } from '@mui/material'
import Box from '@mui/material/Box'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconButton from '@mui/material/IconButton'
import * as React from 'react'
import { useCallback, useState } from 'react'
import dayjs from 'dayjs'

import { CustomButton, DateInput, Row, SelectInput, TextSubtitleBold } from '@components'
import { colors } from '@styles'
import { useTranslation } from '@hooks'
import { PredictionsFilters, PredictionStatus } from '@features/Predictions/types'

import { Footer, Header } from './styled'

interface Props {
  onClose?: () => void
  open?: boolean
  onChange?: (filters: PredictionsFilters) => void
  defaultValues?: PredictionsFilters
}

const PredictionsFiltersDrawer = (props: Props) => {
  const { open, onClose, onChange, defaultValues } = props
  const [filters, updateFilters] = useState<PredictionsFilters>(defaultValues ?? {})

  const _onChange = useCallback(
    (name: string, value: any) => {
      updateFilters({ ...filters, [name]: value })
    },
    [filters],
  )

  const { t } = useTranslation()

  return (
    <Drawer
      anchor={'right'}
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: 500,
          boxSizing: 'border-box',
        },
      }}
    >
      <Header>
        <TextSubtitleBold>{t('predictions.filters')}</TextSubtitleBold>
        <IconButton size={'small'} onClick={props.onClose}>
          <FontAwesomeIcon icon={'close'} size={'2x'} color={colors.grayscale['10'].background} />
        </IconButton>
      </Header>
      <Box display={'flex'} flexDirection={'column'} gap={2} p={2} flexGrow={1}>
        <Row>
          <DateInput
            name={'from'}
            label={t('predictions-filters.from')}
            format={'DD-MM-YYYY'}
            views={['year', 'month', 'day']}
            defaultValue={filters.from ? dayjs(filters.from, 'YYYY-MM-DD') : undefined}
            onChange={date => {
              if (!!date) {
                _onChange('from', date?.format('YYYY-MM-DD') ?? '')
              }
            }}
          />
          <DateInput
            name={'to'}
            label={t('predictions-filters.to')}
            format={'DD-MM-YYYY'}
            views={['year', 'month', 'day']}
            defaultValue={filters.to ? dayjs(filters.to, 'YYYY-MM-DD') : undefined}
            onChange={date => {
              if (!!date) {
                _onChange('to', date?.format('YYYY-MM-DD') ?? '')
              }
            }}
          />
        </Row>

        <SelectInput
          label={t('predictions-filters.item-status')}
          options={[
            PredictionStatus.ACTIVE,
            PredictionStatus.COMPLETED,
            PredictionStatus.CLOSED,
            PredictionStatus.FINISHED,
          ]}
          value={filters.status ?? []}
          multiple={true}
          objectToString={status => (status ? t(`prediction-status.${status.toLowerCase()}`) : '')}
          onChange={values => _onChange('status', values)}
        />
      </Box>
      <Footer>
        <CustomButton
          variant={'secondary'}
          label={t('predictions-filters.clear')}
          fullWidth
          onClick={() => {
            updateFilters({})
            if (onChange) onChange({})
          }}
        />
        <CustomButton
          variant={'primary'}
          label={t('predictions-filters.submit')}
          fullWidth
          onClick={() => {
            if (onChange) onChange({ ...filters, t: Date.now() })
          }}
        />
      </Footer>
    </Drawer>
  )
}

export { PredictionsFiltersDrawer }
