import * as yup from 'yup'
import dayjs from 'dayjs'
import { PhoneNumberUtil } from 'google-libphonenumber'

import { useTranslation } from '@hooks'

const phoneUtil = PhoneNumberUtil.getInstance()

export const useProfileValidationSchema = () => {
  const { t } = useTranslation()

  const parseDateString = (_value, originalValue) => {
    const parsedDate = dayjs(originalValue, 'YYYY-MM-DD')

    return parsedDate?.toDate()
  }

  const validatePhone = phone => {
    if (!phone) {
      return true
    }
    try {
      const phoneNumber = phoneUtil.parse(phone)

      return phoneUtil.isValidNumber(phoneNumber)
    } catch {
      return false
    }
  }

  const profileValidationSchema = yup.object({
    firstName: yup.string(),
    lastName: yup.string(),
    birthdate: yup.date().transform(parseDateString).max(dayjs().format('YYYY-MM-DD')),
    phoneNumber: yup
      .string()
      .optional()
      .test('is-valid-phone', t('create-account.extra-info.validation.phone'), value => validatePhone(value)),
    picture: yup.string(),
  })

  return { profileValidationSchema }
}
