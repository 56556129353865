import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";

import {colors} from "@styles";

const ExternalLoginButtonText = styled('div')(()=>({
    color: colors.grayscale['80'].background,
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'
}))

interface ExternalLoginButtonProps {
    iconSrc?: string
    onClick?: () => void
    label: string
}
const ExternalLoginButton = (props:ExternalLoginButtonProps) => {

    return <Box  onClick={props.onClick} display={'flex'} px={1} py={2} justifyContent={'center'} alignItems={'center'} gap={2} flexGrow={1} sx={theme => ({backgroundColor: colors.grayscale['10'].background, borderRadius: theme.spacing(theme.shape.borderRadius),})}>
        {!!props.iconSrc && <img src={props.iconSrc} height={20}/>}
        <ExternalLoginButtonText>{props.label}</ExternalLoginButtonText>
    </Box>
}

export {ExternalLoginButton}