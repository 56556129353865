import { useMutation } from 'react-query'

import { QUERY_KEYS } from '@api'
import { PredictionsApi } from '@features/Predictions/api'

import { Prediction, Statistics } from '../../types'

const useSavePrediction = (predictionId: number, onSuccess?: () => void, onError?: () => void) => {
  const {
    mutate: savePrediction,
    isLoading: isSavingPrediction,
    data: prediction,
  } = useMutation<Prediction, Error, Statistics>(
    [QUERY_KEYS.PREDICTION, String(predictionId)],

    (statistics: Statistics) => PredictionsApi.savePrediction(predictionId, statistics),
    {
      onSuccess,
      onError,
    },
  )

  return { prediction, isLoading: isSavingPrediction, savePrediction }
}

export { useSavePrediction }
