import * as yup from 'yup'

import { useTranslation } from '@hooks'
import { TeamType } from '@types'

import { DigitizeForm, Patch } from '../../types'

const useDigitizeFormValidationSchema = () => {
  const { t } = useTranslation()

  // @ts-ignore
  const digitizeFormValidationSchema = yup.object<DigitizeForm>().shape({
    type: yup
      .mixed()
      .oneOf([TeamType.TEAM, TeamType.NATIONAL_TEAM], t('digitize.form.validation.required'))
      .required(t('digitize.form.validation.required')),
    team: yup.object().nonNullable().required(t('digitize.form.validation.required')),
    brand: yup.array().min(1, t('digitize.form.validation.required')),
    equipment: yup.array().min(1, t('digitize.form.validation.required')),
    season: yup
      .string()
      .required(t('digitize.form.validation.required'))
      .matches(/^[12][0-9]{3}\/[12][0-9]{3}$/, t('digitize.form.validation.season')),
    year: yup
      .string()
      .required(t('digitize.form.validation.required'))
      .matches(/^[12][0-9]{3}$/, t('digitize.form.validation.year')),
    dorsal: yup
      .string()
      .matches(/^[0-9]+$/, t('digitize.form.validation.dorsal'))
      .min(0, t('digitize.form.validation.dorsal'))
      .max(3, t('digitize.form.validation.dorsal')),
    signed: yup.boolean(),
    signer: yup
      .string()
      .when('signed', { is: true, then: schema => schema.required(t('digitize.form.validation.required')) }),
    patched: yup.boolean(),
    patches: yup
      .array()
      .of(yup.object<Patch>().shape({ name: yup.string().required(t('digitize.form.validation.required')) }))
      .when('patched', { is: true, then: schema => schema.min(1, 'min length') }),
  })

  return { digitizeFormValidationSchema }
}

export { useDigitizeFormValidationSchema }
