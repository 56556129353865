import Box from '@mui/material/Box'
import * as React from 'react'
import { useMemo } from 'react'

import { TextCaptionBold } from '@components'
import { GroupedCollectible } from '@types'
import { useGroupedCollectible, useTranslation } from '@hooks'
import { colors } from '@styles'

import { TokenItem } from '../TokenItem'

interface Props {
  groupedCollectible: GroupedCollectible
}
const TokenList = (props: Props) => {
  const { groupedCollectible } = props
  const { t } = useTranslation()
  const { refetch } = useGroupedCollectible(groupedCollectible.id)
  const itemImage = useMemo(() => {
    return groupedCollectible.mediaFiles.frontImage
  }, [groupedCollectible])

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={1}
      flexGrow={1}
      flexShrink={1}
      sx={{ maxWidth: 350, width: '100%', borderLeft: `4px solid ${colors.grayscale['30'].background}` }}
      mt={4}
      px={1}
      pb={1.5}
    >
      <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
        <TextCaptionBold>{t('collectible.tokens.title')}</TextCaptionBold>
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={1.5}>
        {groupedCollectible.collectibles.map(collectible => (
          <TokenItem collectible={collectible} image={itemImage} onChange={() => refetch()} key={collectible.id} />
        ))}
      </Box>
    </Box>
  )
}

export { TokenList }
