import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

const HiddenInput = styled('input')(() => ({
  display: 'none',
}))

const TShirtImagePickerContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  borderLeftStyle: 'solid',
  borderLeftWidth: 2,
  borderLeftColor: '#cecece',
  minHeight: 30,
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(2),
}))

const TShirtImagePickerHeader = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

const TShirtImagePickerContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  flexGrow: 1,
}))

export { HiddenInput, TShirtImagePickerHeader, TShirtImagePickerContent, TShirtImagePickerContainer }
