import Box from '@mui/material/Box'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { useCallback } from 'react'
import IconButton from '@mui/material/IconButton'
import { useNavigate } from 'react-router-dom'

import { PaginatedTable, TableActions, TableColumns, TextCaptionBold, TextCaptionRegular } from '@components'
import { PredictionStatusChip } from '@features/Predictions/components/PredictionStatusChip'
import { PaginatedResponse } from '@types'
import { Prediction, PredictionStatus } from '@features/Predictions/types'
import { colors } from '@styles'
import { useTranslation } from '@hooks'
import { ROUTES } from '@constants'
import { useModal, useUser } from '@contexts'
import { LoginModal } from '@features/Login'
import { useCheckQuota } from '@features/Quotas/hooks'
import { QuotaFeature } from '@features/Quotas/types'

interface Props {
  data: PaginatedResponse<Prediction>
  onChangePage: (page: number) => void
}

const PredictionsTable = (props: Props) => {
  const navigate = useNavigate()
  const { userState } = useUser()
  const { showModal } = useModal()
  const { data, onChangePage } = props
  const { t } = useTranslation()
  const { showModalQuota, validQuota } = useCheckQuota(QuotaFeature.PREDICTIONS, {
    redirect: () => navigate(ROUTES.HOME),
    noModal: true,
  })
  const columns: (TableColumns<Prediction> | TableActions<Prediction>)[] = [
    {
      width: '15%',
      field: 'date',
      sorteable: false,
      type: 'column',
      headerName: t('predictions.header.date'),
      getValue: item => {
        return dayjs(item.match.date).date()
      },
      renderCell: (item, _value) => {
        const matchDate = dayjs(item.match.date)
        const userTime = dayjs(item.match.date).tz(dayjs.tz.guess())

        return (
          <Box display={'flex'} flexDirection={'column'}>
            <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>
              {matchDate.format(t('predictions.column.date-format')).toUpperCase()}
            </TextCaptionBold>
            <TextCaptionRegular sx={{ color: colors.grayscale[10].background }}>
              {userTime.format(t('predictions.column.time-format'))}
            </TextCaptionRegular>
          </Box>
        )
      },
    },
    {
      width: '10%',
      field: 'competition',
      sorteable: true,
      type: 'column',
      headerName: t('predictions.header.competition'),
      renderCell: (item, value) => {
        return (
          <Box display={'flex'} flexDirection={'column'}>
            <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>{value}</TextCaptionBold>
            <TextCaptionRegular sx={{ color: colors.grayscale[10].background }}>
              {t('predictions.view.round-format', { round: item.match.round })}
            </TextCaptionRegular>
          </Box>
        )
      },
      getValue: item => `${item.match.competition || ''}`,
    },
    {
      width: '25%',
      field: 'match',
      sorteable: true,
      type: 'column',
      headerName: t('predictions.header.match'),
      renderCell: (_item, value) => {
        return <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>{value}</TextCaptionBold>
      },
      getValue: item =>
        t('predictions.column.match', {
          localTeamName: item.match.localTeam.name,
          visitorTeamName: item.match.visitorTeam.name,
        }),
    },
    {
      width: '35%',
      field: 'stadium',
      sorteable: true,
      type: 'column',
      headerName: t('predictions.header.stadium'),
      renderCell: item => {
        return (
          <Box display={'flex'} flexDirection={'column'}>
            <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>{item.match.stadium.name}</TextCaptionBold>
            <TextCaptionRegular sx={{ color: colors.grayscale[10].background }}>
              {item.match.stadium.address}
            </TextCaptionRegular>
          </Box>
        )
      },
      getValue: item => {
        return item.match.stadium.name
      },
    },
    {
      width: '10%',
      field: 'status',
      sorteable: false,
      type: 'column',
      headerName: t('predictions.header.status'),
      renderCell: item => {
        return <PredictionStatusChip status={item.status} />
      },
      getValue: item => {
        return item.status
      },
    },
    {
      width: '5%',
      field: 'actions',
      headerName: '',
      type: 'actions',
      getActions: () => [
        <IconButton onClick={() => {}} key={'chevron'}>
          <FontAwesomeIcon icon={['fas', 'chevron-right']} size={'1x'} color={colors.primary.main.background} />
        </IconButton>,
      ],
    },
  ]

  const handleClick = useCallback(
    (_: React.MouseEvent<unknown>, item: Prediction) => {
      if (userState) {
        if (validQuota || item.status != PredictionStatus.ACTIVE) {
          navigate(`${ROUTES.PREDICTIONS}/${item.id}`)
        } else {
          showModalQuota()
        }
      } else {
        showModal({ modal: <LoginModal /> })
      }
    },
    [userState, validQuota],
  )

  return (
    <PaginatedTable<Prediction> columns={columns} onChangePage={onChangePage} data={data} onRowClicked={handleClick} />
  )
}

export { PredictionsTable }
