import * as React from 'react'
import { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { TextInput } from '@components'
import { useModal } from '@contexts'
import { usePlayerAccumulator } from '@features/Predictions/hooks'

import { PlayerPickerModal } from '../../modals'
import { Player } from '../../types'

interface Props {
  maxPlayers: number
  label: string
  squad: Player[]
  players?: Player[]
  onChange: (players: Player[] | null) => void
}
const ScorerPickerInput = (props: Props) => {
  const { label, maxPlayers, players = [], squad, onChange } = props
  const { showModal } = useModal()
  const playerAccumulator = usePlayerAccumulator()

  const value = useMemo(() => {
    const playerWithCount = playerAccumulator(players)

    return playerWithCount.map(pc => pc.player.name + (pc.count > 1 ? ` (${pc.count})` : '')).join(', ')
  }, [players])

  const openModal = () =>
    showModal({
      modal: (
        <PlayerPickerModal maxPlayers={maxPlayers} squad={squad} players={players} onChange={p => onChange(p ?? [])} />
      ),
    })

  return (
    <TextInput
      label={label}
      readOnly={true}
      value={value}
      onClick={maxPlayers > 0 ? openModal : undefined}
      endAdornment={<FontAwesomeIcon icon={'futbol-ball'} />}
      error={maxPlayers > 0 && players.length != maxPlayers}
      disabled={maxPlayers == 0}
    />
  )
}

export { ScorerPickerInput }
