import Box from '@mui/material/Box'
import * as React from 'react'

import { CustomButton, Modal, TextH2Bold } from '@components'
import { Images } from '@images'
import { useTranslation } from '@hooks'

interface StartTriviaModalProps {
  onPlay: () => void
  onBack: () => void
}
const StartTriviaModal = (props: StartTriviaModalProps) => {
  const { onPlay, onBack } = props
  const { t } = useTranslation()

  return (
    <Modal.Content>
      <Modal.Header image={Images.WarningImage}>
        <Box display={'flex'} flexDirection={'column'} gap={0} pt={1.5}>
          <TextH2Bold>{t('trivias.modal.start.title')}</TextH2Bold>
        </Box>
      </Modal.Header>
      <Modal.Actions>
        <CustomButton variant={'secondary'} label={t('trivias.modal.start.back')} fullWidth={true} onClick={onBack} />
        <CustomButton variant={'primary'} label={t('trivias.modal.start.play')} fullWidth={true} onClick={onPlay} />
      </Modal.Actions>
    </Modal.Content>
  )
}

export { StartTriviaModal }
