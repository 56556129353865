import { createTheme } from '@mui/material'

import { globalStyles } from './global-styles'

const colors = {
  paper: '#303030',
  primary: {
    main: {
      background: '#00F645',
      contrast: '#1B1C1C',
    },
    light: {
      background: '#D2FF00',
      contrast: '#1B1C1C',
    },
    medium: {
      background: '#00FFB4',
      contrast: '#1B1C1C',
    },
    dark: {
      background: '#00A32A',
      contrast: '#1B1C1C',
    },
  },
  secondary: {
    main: {
      background: '#FF0083',
      contrast: '#1B1C1C',
    },
    light: {
      background: '#FF436F',
      contrast: '#1B1C1C',
    },
    medium: {
      background: '#FF0049',
      contrast: '#1B1C1C',
    },
    dark: {
      background: '#FF0084',
      contrast: '#1B1C1C',
    },
  },
  tertiary: {
    main: {
      background: '#3C5BFD',
      contrast: '#FFFFFF',
    },
    light: {
      background: '#6713E9',
      contrast: '#FFFFFF',
    },
    medium: {
      background: '#5974FF',
      contrast: '#141414',
    },
    dark: {
      background: '#1C03D8',
      contrast: '#FFFFFF',
    },
  },
  grayscale: {
    '10': {
      background: '#FFFFFF',
      contrast: '#1B1C1C',
    },
    '20': {
      background: '#EBEBEB',
      contrast: '#1B1C1C',
    },
    '30': {
      background: '#A3A3A3',
      contrast: '#1B1C1C',
    },
    '40': {
      background: '#7A7A7A',
      contrast: '#1B1C1C',
    },
    '50': {
      background: '#525252',
      contrast: '#FFFFFF',
    },
    '60': {
      background: '#3D3D3D',
      contrast: '#FFFFFF',
    },
    '70': {
      background: '#292929',
      contrast: '#FFFFFF',
    },
    '80': {
      background: '#1F1F1F',
      contrast: '#FFFFFF',
    },
    '90': {
      background: '#141414',
      contrast: '#FFFFFF',
    },
    '100': {
      background: '#1B1C1C',
      contrast: '#FFFFFF',
    },
  },
  status: {
    success: {
      background: '#00A32A',
      contrast: '#1B1C1C',
    },
    warning: {
      background: '#00FFB4',
      contrast: '#1B1C1C',
    },
    error: {
      background: '#FF0049',
      contrast: '#1B1C1C',
    },
    info: {
      background: '#5974FF',
      contrast: '#1B1C1C',
    },
    disabled: {
      background: '#A3A3A3',
      contrast: '#1B1C1C',
    },
  },
}

const theme = createTheme({
  shape: {
    borderRadius: 1.5,
  },
  palette: {
    background: {
      default: '#1F1F1F',
      paper: '#1F1F1F',
    },
    primary: {
      main: colors.primary.main.background,
      light: colors.primary.light.background,
      dark: colors.primary.dark.background,
      contrastText: colors.primary.main.contrast,
    },
    secondary: {
      main: colors.secondary.main.background,
      light: colors.secondary.light.background,
      dark: colors.secondary.dark.background,
      contrastText: colors.secondary.main.contrast,
    },
    info: {
      main: colors.status.info.background,
      contrastText: colors.status.info.contrast,
    },
    warning: {
      main: colors.status.warning.background,
      contrastText: colors.status.warning.contrast,
    },
    error: {
      main: colors.status.error.background,
      contrastText: colors.status.error.contrast,
    },
    success: {
      main: colors.status.success.background,
      contrastText: colors.status.success.contrast,
    },
  },
  typography: {
    fontFamily: ['GT-Flexa'].join(','),
    h3: {
      fontSize: '1.125 rem',
    },
    body1: {
      fontSize: '1 rem',
    },
    body2: {
      fontSize: '1 rem',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: globalStyles,
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          backgroundColor: colors.grayscale['50'].background,
        },
        barColorPrimary: {
          borderRadius: 2,
          backgroundColor: colors.primary.light.background,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: '-8px',
        },
        shrink: {
          transform: 'translate(14px, -3px) scale(0.75)',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          minHeight: '40px',
        },
        input: {
          borderRadius: '12px',
          paddingTop: '0px',
          paddingBottom: '0px',
          paddingRight: '16px',
          paddingLeft: '16px',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          minHeight: '54px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          fontSize: '12px',
          lineHeight: '14px',
        },
      },
    },
  },
})

const breakpoints = [600, 900]
const MqTablet = 0
const MqDesktop = 1

const MediaQuery = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

export { theme, colors, MediaQuery, MqTablet, MqDesktop }
