import { SxProps, Theme } from '@mui/material'

import { Row } from '@components'

import { CentralCell, LeftCell, RightCell } from '../styled'

interface PredictionRowProps {
  leftCellContent: JSX.Element
  centralCellContent: JSX.Element
  rightCellContent: JSX.Element
  sx?: SxProps<Theme>
}

const PredictionRow = (props: PredictionRowProps) => {
  const { leftCellContent, centralCellContent, rightCellContent, sx } = props

  return (
    <Row alignItems={'center'} sx={sx}>
      <LeftCell>{leftCellContent}</LeftCell>
      <CentralCell>{centralCellContent}</CentralCell>
      <RightCell>{rightCellContent}</RightCell>
    </Row>
  )
}

export { PredictionRow }
