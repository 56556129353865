import { useNavigate, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'

import { useTranslation } from '@hooks'
import { AppBase } from '@features/AppBase'
import { Images } from '@images'
import { Content, Loading, Title } from '@components'
import { PollOptionCard } from '@features/Polls/components/PollOptionCard'
import { ROUTES } from '@constants'
import { useFlash, useModal } from '@contexts'
import { PollOption } from '@features/Polls/types'
import { PollsApi } from '@features/Polls/api/polls'
import { PollOptionSavedModal } from '@features/Polls/modals/PollOptionSavedModal'
import { ErrorEnum } from '@types'
import { QuotaExceededModal } from '@features/Quotas/modals'
import { useCheckQuota } from '@features/Quotas/hooks'
import { QuotaFeature } from '@features/Quotas/types'
import { useSessionUserUpdater } from '@hooks'

import { usePoll } from '../../hooks/usePoll'

const PollView = () => {
  const { id = '0' } = useParams()
  const { poll, isLoading, isError } = usePoll(id)
  const navigate = useNavigate()
  const { showFlash } = useFlash()
  const { showModal } = useModal()
  const { t } = useTranslation()
  const [isSubmiting, setSubmiting] = useState(false)

  const { validQuota, showModalQuota } = useCheckQuota(QuotaFeature.POLLS, {
    redirect: () => navigate(ROUTES.HOME),
    noModal: true,
  })

  useEffect(() => {
    if (poll && !poll.clientOption && !validQuota) {
      showModalQuota()
    }
  }, [validQuota, poll])

  const { refresh } = useSessionUserUpdater({
    onSuccess: () => {
      navigate(ROUTES.HOME)
    },
  })
  useEffect(() => {
    if (isError) {
      showFlash({ message: t('poll.error'), severity: 'error' })
      navigate(ROUTES.HOME)
    }
  }, [isError, t])

  const onDismiss = () => {
    refresh()
  }

  const onOptionSelected = useCallback(
    async (option: PollOption) => {
      if (!isSubmiting && poll) {
        setSubmiting(true)
        try {
          const result = await PollsApi.saveOption(poll.id, option.id)
          showModal({
            modal: <PollOptionSavedModal poll={result} />,
            options: { onDismiss },
          })
        } catch (apiError: any) {
          if (apiError.type === ErrorEnum.QUOTA_EXCEEDED) {
            showModal({ modal: <QuotaExceededModal />, options: { onDismiss: () => navigate(ROUTES.HOME) } })
          } else {
            showFlash({ message: t('poll.error'), severity: 'error' })
          }
        } finally {
          setSubmiting(false)
        }
      }
    },
    [isSubmiting, poll],
  )

  return (
    <AppBase requireActiveSession backgroundImage={Images.PollsBackground}>
      <Box width={'100%'} px={{ md: 16.5, sm: 8, xs: 2 }} py={4}>
        {isLoading && <Loading />}
        {poll && (
          <>
            <Title text={poll.name} />
            <Content flexDirection={{ xl: 'row', lg: 'row', md: 'row', sm: 'row', xs: 'column' }}>
              {!isLoading &&
                poll?.options.map(option => (
                  <PollOptionCard
                    option={option}
                    onClick={() => onOptionSelected(option)}
                    clientOption={poll.clientOption}
                  />
                ))}
            </Content>
          </>
        )}
      </Box>
    </AppBase>
  )
}

export { PollView }
