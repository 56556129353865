import { Match, Player } from '@features/Predictions/types'
import { useTranslation } from '@hooks'
import { TextH4Bold } from '@components'

import { PredictionRow } from '../PredictionRow'
import { ScorerPickerInput } from '../ScorerPickerInput'

interface ScorersRowProps {
  match: Match
  localPlayers: Player[]
  visitorPlayers: Player[]
  onLocalChange: (value: Player[]) => void
  onVisitorChange: (value: Player[]) => void
  maxLocalPlayers: number
  maxVisitorPlayers: number
}
const ScorersRow = (props: ScorersRowProps) => {
  const { match, onLocalChange, onVisitorChange, maxLocalPlayers, maxVisitorPlayers, localPlayers, visitorPlayers } =
    props
  const { t } = useTranslation()

  return (
    <PredictionRow
      leftCellContent={
        <ScorerPickerInput
          squad={match.localTeamSquad}
          maxPlayers={maxLocalPlayers}
          label={''}
          onChange={value => onLocalChange(value ?? [])}
          players={localPlayers}
        />
      }
      centralCellContent={
        <TextH4Bold sx={{ textAlign: 'center', marginBottom: '16px' }}>{t('predictions.form.scorers')}</TextH4Bold>
      }
      rightCellContent={
        <ScorerPickerInput
          squad={match.visitorTeamSquad}
          maxPlayers={maxVisitorPlayers}
          label={''}
          onChange={value => onVisitorChange(value ?? [])}
          players={visitorPlayers}
        />
      }
    />
  )
}

export { ScorersRow }
