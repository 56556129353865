import * as React from 'react'
import Box from '@mui/material/Box'
import { useEffect, useState } from 'react'

import { AppBase } from '@features/AppBase'
import { Content, CustomButton, CustomPagination, Loading, Row, TextH3Bold, Title } from '@components'
import { useTranslation } from '@hooks'
import { PredictionsFilters } from '@features/Predictions/types'
import { Images } from '@images'
import { useDigitizeYourFirstTShirt } from '@features/Landing/hooks'

import { PredictionsFiltersDrawer, PredictionsTable } from '../../components'
import { usePredictions } from '../../hooks/usePredictions'

const PredictionsMain = () => {
  useDigitizeYourFirstTShirt()
  const { t } = useTranslation()
  const [open, updateOpen] = useState(false)
  const defaultFilters: PredictionsFilters = {}
  const [filters, updateFilters] = useState<PredictionsFilters>(defaultFilters)
  const { data, isLoading, changePage, changeFilters } = usePredictions()

  useEffect(() => {
    changeFilters(filters)
  }, [filters])

  return (
    <AppBase requireActiveSession={false} backgroundImage={Images.PredictionsBackground}>
      <Box width={'100%'} px={{ md: 16.5, sm: 8, xs: 2 }} py={4}>
        <Title text={t('predictions.title')}>
          <Box display={'flex'} flexGrow={1} justifyContent={'flex-end'} alignSelf={'center'} pb={0.5}>
            <CustomButton
              variant={'secondary'}
              label={t('item-filters.filters')}
              onClick={() => updateOpen(true)}
              startIcon={['fas', 'filter']}
            />
          </Box>
        </Title>
        <Content flexDirection={'column'}>
          {isLoading && <Loading />}
          {!isLoading && data?.data && data?.data.length == 0 && (
            <Box alignSelf={'center'} mt={4}>
              <TextH3Bold>{t('predictions.no-matching-matches')} </TextH3Bold>
            </Box>
          )}
          {data && <PredictionsTable data={data} onChangePage={changePage} />}
          <PredictionsFiltersDrawer
            open={open}
            onClose={() => updateOpen(false)}
            defaultValues={defaultFilters}
            onChange={values => {
              updateFilters(values)
              updateOpen(false)
            }}
          />
          <Row>
            {data && data.data.length > 0 && data.lastPage > 0 && (
              <CustomPagination count={data.lastPage} page={data.page} onChange={page => changePage(page)} />
            )}
          </Row>
        </Content>
      </Box>
    </AppBase>
  )
}

export { PredictionsMain }
