import { styled } from '@mui/material/styles'
import MButton from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'

import { colors } from '@styles'

const ButtonPrimaryStyled = styled(MButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  borderRadius: theme.spacing(theme.shape.borderRadius),
  gap: theme.spacing(2),
  ':hover': { backgroundColor: theme.palette.primary.light },
  textTransform: 'none',
  height: '40px',
  '&.Mui-disabled': {
    backgroundColor: colors.grayscale['30'].background,
    color: theme.palette.primary.contrastText,
  },
}))

const ButtonSecondaryStyled = styled(MButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.primary.contrastText,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  borderRadius: theme.spacing(theme.shape.borderRadius),
  borderColor: theme.palette.primary.main,
  borderWidth: 1,
  borderStyle: 'solid',
  gap: theme.spacing(2),
  textTransform: 'none',
  ':hover': {
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    backgroundColor: colors.grayscale['80'].background,
  },
  '&.Mui-disabled': {
    borderColor: colors.grayscale['30'].background,
    color: colors.grayscale['30'].background,
  },
  '&.Mui-selected': {
    backgroundColor: colors.grayscale['60'].background,
    color: theme.palette.primary.light,
  },
}))

const TextButtonStyled = styled(MButton)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0)',
    color: `${colors.primary.light.background} !important`,
  },
  '&.Mui-disabled': {
    color: `${colors.grayscale['30'].background} !important`,
  },
}))

const LinkButtonStyled = styled(TextButtonStyled)(() => ({
  paddingLeft: 0,
  paddingRight: 0,
  backgroundColor: 'rgba(0,0,0,0)',
  minWidth: 'auto',
  '&.Mui-disabled': {
    color: `${colors.grayscale['30'].background} !important`,
  },
}))

const IconButtonPrimaryStyled = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  borderRadius: theme.spacing(theme.shape.borderRadius),
  ':hover': { backgroundColor: theme.palette.primary.light },
  textTransform: 'none',
  height: '40px',
  '&.Mui-disabled': {
    backgroundColor: colors.grayscale['30'].background,
    color: theme.palette.primary.contrastText,
  },
}))

const IconButtonSecondaryStyled = styled(IconButton)(({ theme }) => ({
  color: `${theme.palette.primary.main} !important`,
  backgroundColor: theme.palette.primary.contrastText,
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  borderRadius: theme.spacing(theme.shape.borderRadius),
  borderColor: theme.palette.primary.main,
  borderWidth: 1,
  borderStyle: 'solid',
  textTransform: 'none',
  '&:hover': {
    color: `${theme.palette.primary.light} !important`,
    borderColor: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.contrastText,
  },
}))

const IconButtonLinkStyled = styled(IconButton)(({ theme }) => ({
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0)',
    color: `${colors.primary.light.background} !important`,
  },
}))

export {
  ButtonPrimaryStyled,
  ButtonSecondaryStyled,
  TextButtonStyled,
  LinkButtonStyled,
  IconButtonPrimaryStyled,
  IconButtonSecondaryStyled,
  IconButtonLinkStyled,
}
