import { useMutation } from 'react-query'

import { FrontendUsersApi, QUERY_KEYS } from '@api'
import { useUser } from '@contexts'

const onError = () => {
  console.log('No pudimos encontrar el usuario')
}

interface Props {
  onSuccess?: () => void
}

const useSessionUserUpdater = (props?: Props) => {
  const { setUserState } = useUser()

  const { mutate, isLoading } = useMutation([QUERY_KEYS.SESSION_USER], FrontendUsersApi.getFrontendUser, {
    onError,
    onSuccess: user => {
      setUserState(user)
      if (props?.onSuccess) props.onSuccess()
    },
  })

  return { isLoading, refresh: mutate }
}

export { useSessionUserUpdater }
