import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SxProps, Theme } from '@mui/material'

import { ButtonPrimaryStyled, ButtonSecondaryStyled, TextButtonStyled, LinkButtonStyled } from '../styled'

interface CustomButtonProps {
  variant: 'primary' | 'secondary' | 'text' | 'link'
  label: string
  color?: string
  startIcon?: IconProp
  endIcon?: IconProp
  onClick?: () => void
  fullWidth?: boolean
  disabled?: boolean
  sx?: SxProps<Theme>
}

const CustomButton = (props: CustomButtonProps) => {
  switch (props.variant) {
    case 'primary':
      return (
        <ButtonPrimaryStyled
          className={'ShiCustomButton-primary'}
          fullWidth={props.fullWidth}
          variant={'contained'}
          onClick={props.onClick}
          startIcon={!!props.startIcon && <FontAwesomeIcon icon={props.startIcon} />}
          endIcon={!!props.endIcon && <FontAwesomeIcon icon={props.endIcon} />}
          disabled={props.disabled}
          sx={props.sx}
        >
          {props.label}
        </ButtonPrimaryStyled>
      )
    case 'secondary':
      return (
        <ButtonSecondaryStyled
          className={'ShiCustomButton-secondary'}
          fullWidth={props.fullWidth}
          variant={'contained'}
          onClick={props.onClick}
          startIcon={!!props.startIcon && <FontAwesomeIcon icon={props.startIcon} />}
          endIcon={!!props.endIcon && <FontAwesomeIcon icon={props.endIcon} />}
          disabled={props.disabled}
          sx={props.sx}
        >
          {props.label}
        </ButtonSecondaryStyled>
      )
    case 'text':
      return (
        <TextButtonStyled
          className={'ShiCustomButton-text'}
          fullWidth={props.fullWidth}
          style={{ color: props.color || '#ffffff' }}
          onClick={props.onClick}
          variant={'text'}
          startIcon={!!props.startIcon && <FontAwesomeIcon icon={props.startIcon} />}
          endIcon={!!props.endIcon && <FontAwesomeIcon icon={props.endIcon} />}
          disabled={props.disabled}
          sx={props.sx}
        >
          {props.label}
        </TextButtonStyled>
      )
    case 'link':
      return (
        <LinkButtonStyled
          className={'ShiCustomButton-link'}
          fullWidth={props.fullWidth}
          style={{ color: props.color || '#ffffff' }}
          onClick={props.onClick}
          startIcon={!!props.startIcon && <FontAwesomeIcon icon={props.startIcon} />}
          endIcon={!!props.endIcon && <FontAwesomeIcon icon={props.endIcon} />}
          disabled={props.disabled}
          sx={props.sx}
        >
          {props.label}
        </LinkButtonStyled>
      )
  }
}

export { CustomButton }
