import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import { PropsWithChildren } from 'react'

const Card = styled(Box)(({ theme }) => ({
  // backgroundColor: theme.palette.background.default,
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(2),
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  width: theme.spacing(16),
  marginRight: theme.spacing(4),
}))
const Content = (props: PropsWithChildren) => {
  return (
    <Box
      display={'flex'}
      flexDirection={{ lg: 'row', md: 'column', sm: 'column', xs: 'column' }}
      gap={3.75}
      paddingTop={2.5}
      justifyContent={'space-evenly'}
    >
      {props.children}
    </Box>
  )
}
const ProfileColumn = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))
const ProfileRow = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}))

export { Card, ProfileRow, ProfileColumn, Content }
