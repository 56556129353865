import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box'
import * as React from 'react'

import { useModal } from '@contexts'
import { CustomButton, Modal, TextH2Bold } from '@components'
import { Images } from '@images'
import { colors } from '@styles'
import { useTranslation } from '@hooks'

interface Props {
  onSave: () => void
}
const ChallengeSaveChangesModal = (props: Props) => {
  const { onSave } = props
  const { dismissModal } = useModal()
  const { t } = useTranslation()

  return (
    <Modal.Content>
      <Modal.Header image={Images.WarningImage}>
        <FontAwesomeIcon icon={['far', 'circle-bookmark']} size={'3x'} color={colors.status.success.background} />
        <Box display={'flex'} flexDirection={'column'} gap={0} pt={1.5}>
          <TextH2Bold>{t('challenges.modals.save-changes.title')}</TextH2Bold>
        </Box>
      </Modal.Header>
      <Modal.Actions>
        <CustomButton
          variant={'secondary'}
          label={t('challenges.modals.save-changes.back')}
          fullWidth={true}
          onClick={() => {
            dismissModal()
          }}
        />
        <CustomButton
          variant={'primary'}
          label={t('challenges.modals.save-changes.save')}
          fullWidth={true}
          onClick={() => {
            onSave()
            dismissModal()
          }}
        />
      </Modal.Actions>
    </Modal.Content>
  )
}

export { ChallengeSaveChangesModal }
