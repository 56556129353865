import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { QUERY_KEYS } from '@api'
import { ROUTES } from '@constants'
import { useModal } from '@contexts'
import { ApiError, ErrorEnum } from '@types'
import { QuotaExceededModal } from '@features/Quotas/modals'

import { TriviasApi } from '../../api'
import { Trivia } from '../../types'
import { useSessionUserUpdater } from '../../../../hooks/useSessionUserUpdater'

export const useTrivia = (id: number | string, onSuccess: (trivia: Trivia) => void) => {
  const navigate = useNavigate()
  const { mutate: getTrivia, isLoading: isLoadingGet } = useMutation(
    [QUERY_KEYS.TRIVIA, id],
    () => TriviasApi.getById(id),
    {
      onSuccess,
      onError: () => {
        navigate(ROUTES.TRIVIAS)
      },
    },
  )

  const { refresh } = useSessionUserUpdater()
  const { showModal } = useModal()

  const { mutate: startTrivia, isLoading: isLoadingStart } = useMutation<Trivia, ApiError, void>(
    [QUERY_KEYS.TRIVIA, id],
    () => TriviasApi.startTrivia(id),
    {
      onSuccess: (trivia: Trivia) => {
        refresh()
        onSuccess(trivia)
      },
      onError: error => {
        if (error.type === ErrorEnum.QUOTA_EXCEEDED) {
          showModal({ modal: <QuotaExceededModal />, options: { onDismiss: () => navigate(ROUTES.HOME) } })
        } else {
          navigate(ROUTES.TRIVIAS)
        }
      },
    },
  )

  interface SaveAnswersParams {
    questionId: number
    answersIds: number[]
  }
  const { mutate: saveAnswer, isLoading: isSavingAnswer } = useMutation<Trivia, Error, SaveAnswersParams>(
    [QUERY_KEYS.TRIVIA, id],
    (params: SaveAnswersParams) => TriviasApi.saveAnswer(id, params.questionId, params.answersIds),
    {
      onSuccess,
      onError: () => {
        navigate(ROUTES.TRIVIAS)
      },
    },
  )

  return { getTrivia, startTrivia, saveAnswer, isLoading: isLoadingGet || isLoadingStart || isSavingAnswer }
}
