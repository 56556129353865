import { Box } from '@mui/material'
import { PropsWithChildren } from 'react'

const Actions = (props: PropsWithChildren) => {
  return (
    <Box
      className={'ShiModal-actions'}
      display={'flex'}
      flexDirection={{ sm: 'row', xs: 'column-reverse' }}
      flexGrow={1}
      flexShrink={0}
      gap={2}
      width={'100%'}
    >
      {props.children}
    </Box>
  )
}

export { Actions }
