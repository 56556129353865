import { useCallback, useEffect, useState } from 'react'
import { useMutation } from 'react-query'

import { ItemFilters, PaginatedRequest } from '@types'
import { CollectiblesApi, QUERY_KEYS } from '@api'

const useMyCollectiblesWithFilters = () => {
  const [paginationWithFilters, updatePaginationWithFilters] = useState<PaginatedRequest<ItemFilters>>({
    page: 0,
    pageSize: 100,
    filters: {},
  })
  const { filters } = paginationWithFilters

  const changeFilters = (f: ItemFilters) => {
    updatePaginationWithFilters({ filters: { ...f }, page: 0, pageSize: 100 })
  }

  const changePage = useCallback(
    (page: number) => {
      updatePaginationWithFilters({ ...paginationWithFilters, page })
    },
    [paginationWithFilters],
  )

  const { data, isLoading, mutate } = useMutation(
    [QUERY_KEYS.MY_COLLECTIBLES_FILTERED, filters],
    (params: PaginatedRequest<ItemFilters>) => CollectiblesApi.getMyCollectiblesWithFilters(params),
  )

  useEffect(() => {
    mutate(paginationWithFilters)
  }, [paginationWithFilters])

  return { data, isLoading, changePage, changeFilters }
}
export { useMyCollectiblesWithFilters }
