import { Country } from '@types'

import { api } from './axios'

const all = async () => {
  const response = await api.get<Country[]>('/countries')

  return response.data
}

export const CountriesApi = {
  all,
}
