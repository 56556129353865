import { useQuery } from 'react-query'

import { AuthApi } from '@api'
import { useUser } from '@contexts'

const useCheckActiveSession = (enabled: boolean) => {
  const { setUserState } = useUser()
  useQuery(['session-status'], () => AuthApi.status(enabled), {
    retry: false,
    refetchOnWindowFocus: 'always',
    refetchIntervalInBackground: true,
    refetchInterval: 10 * 60 * 1000,
    onError: () => {
      setUserState(undefined)
    },
  })
}

export { useCheckActiveSession }
