import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

import { colors, MediaQuery, MqTablet } from '@styles'
import { RoundedCornersBox } from '@components'

export const MarketplaceItemCardImage = styled('img')(({ theme }) => ({
  borderRadius: theme.spacing(2),
  borderColor: colors.grayscale['30'].background,
  backgroundColor: '#ffffff',
  borderWidth: 1,
  borderStyle: 'solid',
  width: 230,
  objectFit: 'contain',
  height: 350,
  [MediaQuery[MqTablet]]: {
    height: 330,
  },
  transition: 'height 0.5s linear',
}))

export const MarketplaceItemCardBottom = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: colors.grayscale['60'].background,
  borderBottomLeftRadius: theme.spacing(2),
  borderBottomRightRadius: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.primary.main}`,
  transition: 'opacity 1s linear',
  padding: theme.spacing(2),
  justifyContent: 'center',
  height: 80,
  position: 'absolute',
  bottom: 0,
  opacity: 0,
  display: 'flex',
  visibility: 'hidden',
}))

export const MarketplaceItemCardContentBlock = styled(RoundedCornersBox)(({ theme }) => ({
  display: 'block',
  width: 264,
  marginBottom: theme.spacing(4),
  position: 'relative',
  height: 450,
  '&:hover': { borderColor: colors.primary.light.background },
  '&:hover #image': {
    height: 270,
  },
  '&:hover #actions': {
    display: 'flex',
    opacity: 1,
    visibility: 'visible',
  },
}))

export const MarketplaceItemCardBodyBlock = styled(Box)(({ theme }) => ({
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
}))
