import { PaginatedRequest, PaginatedResponse } from '@types'
import { api } from '@api'

import { PredictionsFilters, Prediction, Statistics, RankingPrediction, RankingPredictionsFilters } from '../types'

const paginate = async (params: PaginatedRequest<PredictionsFilters>) => {
  const { page, filters } = params
  const response = await api.get<PaginatedResponse<Prediction>>('/predictions', { params: { page, ...filters } })

  return response.data
}

const getById = async (id: string | number) => {
  const response = await api.get<Prediction>(`/predictions/${id}`)

  return response.data
}

const savePrediction = async (id: number, prediction: Statistics) => {
  const response = await api.put<Prediction>(`/predictions/${id}`, prediction)

  return response.data
}

const paginateRanking = async (params: PaginatedRequest<RankingPredictionsFilters>) => {
  const { page = 0, pageSize = 10, filters } = params
  const response = await api.get<PaginatedResponse<RankingPrediction>>('/predictions/ranking', {
    params: { page, pageSize, ...filters },
  })

  return response.data
}

export const PredictionsApi = {
  paginate,
  getById,
  savePrediction,
  paginateRanking,
}
