import * as React from 'react'
import { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Team, TeamType } from '@types'
import { TextInput } from '@components'
import { useModal } from '@contexts'

import { TeamPickerModal } from '../../modals'

interface Props {
  label: string
  type?: TeamType[]
  team?: Team[]
  onChange: (team: Team[] | null) => void
}
const TeamPickerInput = (props: Props) => {
  const { label, type = [TeamType.TEAM, TeamType.NATIONAL_TEAM], team = [], onChange } = props
  const { showModal } = useModal()

  const value = useMemo(() => {
    const names = team.map(st => st.name)

    return names.join(', ')
  }, [team])

  const openModal = () =>
    showModal({
      modal: <TeamPickerModal maxTeams={10} type={type} teams={team} onChange={t => onChange(t ?? [])} />,
    })

  return (
    <TextInput
      label={label}
      readOnly={true}
      value={value}
      onClick={openModal}
      endAdornment={<FontAwesomeIcon icon={'shield-alt'} />}
    />
  )
}

export { TeamPickerInput }
