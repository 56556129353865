import { User } from '@types'

import { api } from './axios'

const getFrontendUser = async () => {
  const response = await api.get<User>(`/client/me`)

  return response.data
}

export const FrontendUsersApi = { getFrontendUser }
