import { useCallback, useState } from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Cropper from 'react-easy-crop'
import { Slider } from '@mui/material'
import * as React from 'react'

import { CustomButton, TextCaptionBold } from '@components'
import { useTranslation } from '@hooks'

import { getCroppedImg } from '../../utils/canvas-utils'
import { FileProps } from '../../types'

const CropContainer = styled(Box)(() => ({
  position: 'relative',
  width: '100%',
  height: 300,
  background: '#333',
}))

const Controls = styled(Box)(() => ({
  width: '100%',
}))
const SliderContainer = styled(Box)(() => ({
  display: 'flex',
  flex: '1',
  alignItems: 'center',
}))

const CustomSlider = styled(Slider)(({ theme }) => ({
  padding: '22px 0px',
  marginLeft: 16,
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 16px',
  },
}))
const ImageCropper = (props: {
  imgSrc: FileProps
  onCrop: (croppedImage: FileProps) => void
  onCancel?: () => void
  aspect?: number
}) => {
  const { t } = useTranslation()
  const { imgSrc, onCrop, onCancel } = props
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const showCroppedImage = useCallback(async () => {
    try {
      const [croppedImageUrl, croppedImageSize] = await getCroppedImg(imgSrc.objectUrl, croppedAreaPixels, rotation)
      if (!!croppedImageUrl) {
        onCrop({ ...imgSrc, objectUrl: croppedImageUrl, size: croppedImageSize })
      }
    } catch {}
  }, [onCrop, imgSrc, croppedAreaPixels, rotation])

  return imgSrc ? (
    <>
      <CropContainer>
        <Cropper
          image={imgSrc.objectUrl}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={props.aspect ?? 4 / 5}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          style={{ containerStyle: { width: '100%' } }}
        />
      </CropContainer>
      <Controls>
        <SliderContainer>
          <TextCaptionBold>{t('image-cropper.zoom')}</TextCaptionBold>
          <CustomSlider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby={t('image-cropper.zoom')}
            onChange={(e, zoom) => {
              if (Array.isArray(zoom) && zoom.length > 0) {
                setZoom(zoom[0])
              } else {
                setZoom(zoom as number)
              }
            }}
          />
        </SliderContainer>
        <SliderContainer>
          <TextCaptionBold>{t('image-cropper.rotation')}</TextCaptionBold>

          <CustomSlider
            value={rotation}
            min={0}
            max={360}
            step={1}
            aria-labelledby={t('image-cropper.rotation')}
            onChange={(e, rotation) => {
              if (Array.isArray(rotation) && rotation.length > 0) {
                setRotation(rotation[0])
              } else {
                setRotation(rotation as number)
              }
            }}
          />
        </SliderContainer>
      </Controls>
      <Box
        display={'flex'}
        flexDirection={{ sm: 'row', xs: 'column-reverse' }}
        flexGrow={1}
        flexShrink={0}
        gap={2}
        width={'100%'}
      >
        <CustomButton variant={'secondary'} label={t('image-cropper.cancel')} fullWidth={true} onClick={onCancel} />
        <CustomButton
          variant={'primary'}
          label={t('image-cropper.apply')}
          fullWidth={true}
          onClick={showCroppedImage}
        />
      </Box>
    </>
  ) : null
}

export { ImageCropper }
