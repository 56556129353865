import Box from '@mui/material/Box'

import { Images } from '@images'
import { AppBase } from '@features/AppBase'

import { useDigitizeYourFirstTShirt, useFreemiumUserPoll, usePasswordResetAndEmailValidation } from '../../hooks'
import {
  Banner,
  LastTShirts,
  PutYourShirtsToPlayWidget,
  VideoWidget,
  AnswerPollToWinPrizeWidget,
  DigitizeWidget,
} from '../../components'

const LandingMain = () => {
  const { shouldShowWidget: shouldShowAnswerPollToWinPrizeWidget, actionPath } = useFreemiumUserPoll()
  usePasswordResetAndEmailValidation()
  useDigitizeYourFirstTShirt()

  return (
    <AppBase requireActiveSession={false} backgroundImage={Images.LandingBackground}>
      <Banner />
      <Box display={'flex'} flexDirection={'column'} gap={4} width={'100%'} px={{ md: 16.5, sm: 8, xs: 2 }} py={8}>
        <Box
          display={'flex'}
          flexDirection={{ lg: 'row', md: 'column', sm: 'column', xs: 'column' }}
          gap={4}
          width={'100%'}
        >
          {shouldShowAnswerPollToWinPrizeWidget ? (
            <AnswerPollToWinPrizeWidget actionPath={actionPath} />
          ) : (
            <PutYourShirtsToPlayWidget />
          )}
          <VideoWidget />
        </Box>
        <DigitizeWidget />
        <LastTShirts />
      </Box>
    </AppBase>
  )
}

export { LandingMain }
