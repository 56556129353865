import { WidgetContent } from '../WidgetContent'

const VideoWidget = () => {
  return (
    <WidgetContent>
      <video src={'./video.mp4'} width={'100%'} controls />
    </WidgetContent>
  )
}

export { VideoWidget }
