import Box from '@mui/material/Box'
import * as React from 'react'

import { PaginatedTable, TextCaptionBold, TableActions, TableColumns } from '@components'
import { PaginatedResponse } from '@types'
import { colors } from '@styles'
import { useTranslation } from '@hooks'

import { RankingTrivia, RankingTriviaComparator } from '../../types'

interface Props {
  data: PaginatedResponse<RankingTrivia>
  onChangePage: (page: number) => void
}

const RankingTable = (props: Props) => {
  const { data, onChangePage } = props
  const { t } = useTranslation()

  const columns: (TableColumns<RankingTrivia> | TableActions<RankingTrivia>)[] = [
    {
      width: '10%',
      field: 'position',
      sorteable: false,
      type: 'column',
      headerName: t('ranking-trivia.header.position'),
      getValue: item => {
        return item.position
      },
      renderCell: (_item, value) => {
        return (
          <Box
            display={'flex'}
            flexDirection={'column'}
            sx={{ minHeight: 40, justifyContent: 'center', paddingLeft: 1 }}
          >
            <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>{value}</TextCaptionBold>
          </Box>
        )
      },
    },
    {
      width: '30%',
      field: 'name',
      sorteable: false,
      type: 'column',
      headerName: t('ranking-trivia.header.name'),
      renderCell: (item, value) => {
        return (
          <Box display={'flex'} flexDirection={'column'}>
            <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>{value}</TextCaptionBold>
          </Box>
        )
      },
      getValue: item => `${item.name || ''}`,
    },
    {
      width: '30%',
      field: 'team',
      sorteable: false,
      type: 'column',
      headerName: t('ranking-trivia.header.team'),
      renderCell: (_item, value) => {
        return <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>{value}</TextCaptionBold>
      },
      getValue: item => item.team ?? '---',
    },
    {
      width: '15%',
      field: 'points',
      sorteable: false,
      type: 'column',
      headerName: t('ranking-trivia.header.points'),
      renderCell: (_item, value) => {
        return (
          <Box display={'flex'} flexDirection={'column'}>
            <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>{value}</TextCaptionBold>
          </Box>
        )
      },
      getValue: item => {
        return item.points ?? '-'
      },
    },
    {
      width: '15%',
      field: 'percentage',
      sorteable: false,
      type: 'column',
      headerName: t('ranking-trivia.header.percentage'),
      renderCell: (_item, value) => {
        return (
          <Box display={'flex'} flexDirection={'column'}>
            <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>{`${value}%`}</TextCaptionBold>
          </Box>
        )
      },
      getValue: item => {
        return Math.floor((100 * item.correct_answers) / item.total_questions)
      },
    },
  ]

  return (
    <PaginatedTable<RankingTrivia>
      columns={columns}
      onChangePage={onChangePage}
      data={data}
      extraItemComparator={RankingTriviaComparator}
    />
  )
}

export { RankingTable }
