import Box from '@mui/material/Box'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { Content, CustomButton, Loading, TextH2Bold, Title } from '@components'
import { useTranslation } from '@hooks'
import { AppBase } from '@features/AppBase'
import { ROUTES } from '@constants'
import { Images } from '@images'
import { useDigitizeYourFirstTShirt } from '@features/Landing/hooks'

import { useTrivias } from '../../hooks'
import { TriviaCard } from '../../components'

const TriviaMain = () => {
  useDigitizeYourFirstTShirt()
  const { data, isLoading } = useTrivias()
  const { t } = useTranslation()
  const navigate = useNavigate()

  // const { validQuota } = useCheckQuota(QuotaFeature.TRIVIAS, {
  //   redirect: () => navigate(ROUTES.TRIVIAS),
  //   noModal:true,
  //   forceRedirect: false,
  // })

  return (
    <AppBase requireActiveSession={false} backgroundImage={Images.TriviasBackground}>
      <Box width={'100%'} px={{ md: 16.5, sm: 8, xs: 2 }} py={4}>
        <Title text={t('trivias.title')} />
        <Content flexDirection={{ xl: 'row', lg: 'row', md: 'column', sm: 'column', xs: 'column' }}>
          {isLoading && <Loading />}
          {data?.map(trivia => <TriviaCard trivia={trivia} playEnabled={true} key={`trivia-${trivia.id}`} />)}
          {!isLoading && (data?.length ?? 0) == 0 && <TextH2Bold>{t('trivias.empty')}</TextH2Bold>}
        </Content>
        <Box display={'flex'} justifyContent={'center'} pt={4}>
          <CustomButton
            variant={'primary'}
            label={t('trivias.see-previous')}
            onClick={() => navigate(ROUTES.PREVIOUS_TRIVIAS)}
          />
        </Box>
      </Box>
    </AppBase>
  )
}

export { TriviaMain }
