import * as React from 'react'

import { CustomButton } from '@components'
import { CoinsBudget } from '@features/Coins/types'
import { useTranslation } from '@hooks'

import { useCoinsPayment } from '../../hooks/useCoinsPayment'

interface Props {
  disabled: boolean
  budget?: CoinsBudget
  onSuccess: () => void
}
const BuyButton = (props: Props) => {
  const { t } = useTranslation()
  const { disabled, budget, onSuccess } = props
  const { payCoins, isLoading } = useCoinsPayment(onSuccess)

  const makePayment = () => {
    payCoins(budget)
  }

  return (
    <CustomButton
      variant={'primary'}
      label={t('coins.balance.buy')}
      fullWidth
      disabled={disabled || isLoading}
      onClick={makePayment}
    />
  )
}

export { BuyButton }
