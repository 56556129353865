import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box'
import * as React from 'react'
import { useFormik } from 'formik'

import { useModal } from '@contexts'
import { CustomButton, Modal, PasswordInput, TextH2Bold, TextH4Bold } from '@components'
import { Images } from '@images'
import { colors } from '@styles'
import { useTranslation } from '@hooks'
import { AuthApi } from '@api'
import { ForgotPasswordUserNotFoundModal } from '@features/ForgotPassword/modals'

import { useChangePasswordValidationSchema } from './validation'
import { ForgotPasswordSuccessModal } from '../ForgotPasswordSuccessModal'

interface Props {
  username: string
  token: string
}
const ForgotPasswordChangePasswordModal = (props: Props) => {
  const { token, username } = props
  const { showModal, dismissModal } = useModal()
  const { t } = useTranslation()
  const { changePasswordValidationSchema } = useChangePasswordValidationSchema()
  const formik = useFormik<{ username: string; password: string; confirmPassword: string; token: string }>({
    initialValues: {
      password: '',
      confirmPassword: '',
      token,
      username,
    },
    validationSchema: changePasswordValidationSchema,
    onSubmit: async values => {
      formik.setSubmitting(true)
      try {
        await AuthApi.changePassword(values)
        showModal({ modal: <ForgotPasswordSuccessModal /> })
      } catch (e) {
        showModal({ modal: <ForgotPasswordUserNotFoundModal /> })
      }
      formik.setSubmitting(false)
    },
  })

  return (
    <Modal.Content>
      <Modal.Header image={Images.ConfirmImage}>
        <FontAwesomeIcon icon={['far', 'circle-check']} size={'3x'} color={colors.status.success.background} />
        <Box display={'flex'} flexDirection={'column'} gap={0} pt={1.5}>
          <TextH2Bold>{t('forgot-password.modals.change-password.title')}</TextH2Bold>
          <TextH4Bold>{t('forgot-password.modals.change-password.subtitle')}</TextH4Bold>
        </Box>
      </Modal.Header>
      <Modal.Form>
        <PasswordInput
          label={t('forgot-password.modals.change-password.inputs.password')}
          fullWidth
          name={'password'}
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <PasswordInput
          label={t('forgot-password.modals.change-password.inputs.confirm-password')}
          fullWidth
          name={'confirmPassword'}
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
          helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
        />
      </Modal.Form>
      <Modal.Actions>
        <CustomButton
          variant={'secondary'}
          label={t('forgot-password.modals.change-password.actions.back')}
          fullWidth={true}
          onClick={() => dismissModal()}
        />
        <CustomButton
          variant={'primary'}
          label={t('forgot-password.modals.change-password.actions.confirm')}
          fullWidth={true}
          disabled={!formik.isValid || formik.isSubmitting}
          onClick={() => formik.submitForm()}
        />
      </Modal.Actions>
    </Modal.Content>
  )
}

export { ForgotPasswordChangePasswordModal }
