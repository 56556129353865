import Box from '@mui/material/Box'
import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useModal } from '@contexts'
import { CustomButton, Modal, TextH2Bold, TextH4Bold } from '@components'
import { Images } from '@images'
import { colors } from '@styles'
import { useTranslation } from '@hooks'

import { useSessionUserUpdater } from '../../../../hooks/useSessionUserUpdater'

interface Props {
  showRefresh?: boolean
  redirect?: () => void
}
const QuotaExceededModal = (props: Props) => {
  const { showRefresh = false, redirect } = props
  const { dismissModal } = useModal()
  const { t } = useTranslation()
  const { refresh } = useSessionUserUpdater({ onSuccess: dismissModal })

  return (
    <Modal.Content>
      <Modal.Header image={Images.ErrorImage}>
        <Box sx={{ alignSelf: 'center' }}>
          <FontAwesomeIcon icon={['far', 'circle-xmark']} size={'3x'} color={colors.status.error.background} />
        </Box>
        <Box display={'flex'} flexDirection={'column'} gap={0} pt={1.5}>
          <TextH2Bold>{t('quota.modals.exceeded.title')}</TextH2Bold>
          <TextH4Bold>{t('quota.modals.exceeded.description')}</TextH4Bold>
        </Box>
      </Modal.Header>
      <Modal.Actions>
        {showRefresh && (
          <CustomButton
            variant={'secondary'}
            label={t('quota.modals.exceeded.refresh')}
            fullWidth={true}
            onClick={() => {
              refresh()
            }}
          />
        )}
        <CustomButton
          variant={'secondary'}
          label={t('quota.modals.exceeded.back')}
          fullWidth={true}
          onClick={() => {
            if (redirect) redirect()
            dismissModal()
          }}
        />
      </Modal.Actions>
    </Modal.Content>
  )
}

export { QuotaExceededModal }
