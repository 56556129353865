export const ROUTES = Object.freeze({
  HOME: '/',
  DIGITIZE: '/digitize',
  PROFILE: '/profile',
  TRIVIAS: '/trivias',
  PREVIOUS_TRIVIAS: '/previous-trivias',
  RANKING_TRIVIAS: '/ranking-trivias',
  MY_SHIRTS: '/my-shirts',
  MY_COLLECTIBLES: '/my-collectibles',
  MARKETPLACE: '/marketplace',
  CHALLENGES: '/challenges',
  PREDICTIONS: '/predictions',
  COINS: '/points',
  RESET_PASSWORD: '/reset-password',
  VALIDATE_EMAIL: '/validate-email',
  RANKINGS: '/rankings',
  POLLS: '/polls',
})
