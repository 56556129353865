import { styled } from '@mui/material/styles'

import { colors, MediaQuery, MqDesktop, MqTablet } from '@styles'

const TextH1 = styled('h1')(() => ({
  margin: 0,
  color: colors.grayscale['10'].background,
  fontSize: 32,
  [MediaQuery[MqDesktop]]: {
    fontSize: 48,
  },
  fontFamily: 'GT-Flexa',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  textTransform: 'uppercase',
}))

const TextH1Bold = styled(TextH1)(() => ({
  fontWeight: 700,
}))

const TextH2Bold = styled('h2')(() => ({
  margin: 0,
  color: colors.grayscale['10'].background,
  fontSize: 16,
  [MediaQuery[MqTablet]]: {
    fontSize: 18,
  },
  [MediaQuery[MqDesktop]]: {
    fontSize: 24,
  },
  fontFamily: 'GT-Flexa',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  textTransform: 'uppercase',
}))

const TextH3Bold = styled('h3')(() => ({
  margin: 0,
  color: colors.grayscale['10'].background,
  fontSize: 16,
  [MediaQuery[MqDesktop]]: {
    fontSize: 18,
  },
  fontFamily: 'GT-Flexa',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  textTransform: 'uppercase',
}))

const TextH4Regular = styled('h4')(() => ({
  margin: 0,
  color: colors.grayscale['10'].background,
  fontSize: 14,
  [MediaQuery[MqDesktop]]: {
    fontSize: 16,
  },
  fontFamily: 'GT-Flexa',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  textTransform: 'uppercase',
}))

const TextH4Bold = styled(TextH4Regular)(() => ({
  fontWeight: 700,
}))

const TextTitleBold = styled('div')(() => ({
  margin: 0,
  color: colors.grayscale['10'].background,
  fontSize: 20,
  [MediaQuery[MqDesktop]]: {
    fontSize: 24,
  },
  fontFamily: 'GT-Flexa',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
}))

const TextMainTitleBold = styled('div')(() => ({
  margin: 0,
  color: colors.grayscale['10'].background,
  fontSize: 24,
  [MediaQuery[MqDesktop]]: {
    fontSize: 40,
  },
  fontFamily: 'GT-Flexa',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
}))

const TextSubtitleRegular = styled('div')(() => ({
  margin: 0,
  color: colors.grayscale['10'].background,
  fontSize: 16,
  [MediaQuery[MqDesktop]]: {
    fontSize: 20,
  },
  fontFamily: 'GT-Flexa',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
}))

const TextSubtitleBold = styled(TextSubtitleRegular)(() => ({
  fontWeight: 700,
}))

const TextLegalRegular = styled('div')(() => ({
  color: colors.grayscale['30'].background,
  fontSize: 10,
  [MediaQuery[MqDesktop]]: {
    fontSize: 12,
  },
  fontStyle: 'normal',
  fontFamily: 'GT-Flexa',
  fontWeight: 400,
  lineHeight: 'normal',
}))

const TextCaptionRegular = styled('div')(() => ({
  color: colors.grayscale['20'].background,
  fontSize: 12,
  [MediaQuery[MqDesktop]]: {
    fontSize: 14,
  },
  fontFamily: 'GT-Flexa',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
}))

const TextCaptionBold = styled(TextCaptionRegular)(() => ({
  fontWeight: 700,
}))

const TextBodyRegular = styled('div')(() => ({
  color: colors.grayscale['20'].background,
  fontSize: 14,
  [MediaQuery[MqDesktop]]: {
    fontSize: 16,
  },
  fontFamily: 'GT-Flexa',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
}))

const TextBodyBold = styled(TextBodyRegular)(() => ({
  fontWeight: 700,
}))

export {
  TextH1,
  TextH1Bold,
  TextH2Bold,
  TextH3Bold,
  TextH4Regular,
  TextH4Bold,
  TextLegalRegular,
  TextCaptionRegular,
  TextCaptionBold,
  TextTitleBold,
  TextSubtitleRegular,
  TextSubtitleBold,
  TextBodyBold,
  TextBodyRegular,
  TextMainTitleBold,
}
