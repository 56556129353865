import Box from '@mui/material/Box'
import { useMemo, useState } from 'react'
import * as React from 'react'

import { Content, RoundedCornersBox, TextCaptionBold, Title } from '@components'
import { Images } from '@images'
import { useTranslation } from '@hooks'
import { AppBase } from '@features/AppBase'

import { Progress, Step } from './components'
import { CreateAccountProvider } from '../../contexts/create-account-context'

const STEPS_COUNT = 4
const CreateAccountMain = () => {
  const { t } = useTranslation()
  const [step, updateStep] = useState(0)

  const progress = useMemo(() => {
    return Math.floor((step / STEPS_COUNT) * 100)
  }, [step])

  const currentStep = useMemo(() => {
    switch (step) {
      case 0:
        return <Step.PersonalInfo next={() => updateStep(step + 1)} />
      case 1:
        return <Step.NationalTeam next={() => updateStep(step + 1)} prev={() => updateStep(step - 1)} />
      case 2:
        return <Step.Club next={() => updateStep(step + 1)} prev={() => updateStep(step - 1)} />
      case 3:
        return <Step.ExtraInfo prev={() => updateStep(step - 1)} />
      default:
        return <TextCaptionBold>{'---'}</TextCaptionBold>
    }
  }, [step])

  const currentTitle = useMemo(() => {
    switch (step) {
      case 0:
        return t('create-account.personal-info.label')
      case 1:
        return t('create-account.national-team.label')
      case 2:
        return t('create-account.club-team.label')
      case 3:
        return t('create-account.extra-info.label')
      default:
        return '---'
    }
  }, [step, t])

  return (
    <CreateAccountProvider>
      <AppBase requireActiveSession={false} backgroundImage={Images.CreateAccountBackground}>
        <Box width={'100%'} px={{ md: 16.5, sm: 8, xs: 2 }} py={4}>
          <Title text={t('create-account.title')} />
          <Content flexDirection={{ lg: 'row', md: 'column', sm: 'column', xs: 'column' }} sx={{ flexWrap: 'nowrap' }}>
            <Progress value={progress} title={currentTitle} />
            <RoundedCornersBox
              display={'flex'}
              width={{ lg: '50%', md: '100%', sm: '100%', xs: '100%' }}
              flexDirection={'column'}
              alignItems={'flex-start'}
              gap={2.5}
              p={2}
            >
              {currentStep}
            </RoundedCornersBox>
          </Content>
        </Box>
      </AppBase>
    </CreateAccountProvider>
  )
}

export { CreateAccountMain }
