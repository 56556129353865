import { api } from '@api'
import { GroupedCollectible, PaginatedResponse } from '@types'

const getById = async (collectibleId: string | number) => {
  const response = await api.get<GroupedCollectible>(`/grouped-collectible/${collectibleId}`)

  return response.data
}

const getMyCollectibles = async (page: string | number) => {
  const params = new URLSearchParams()
  params.set('page', `${page}`)
  params.set('pageSize', '50')
  const response = await api.get<PaginatedResponse<GroupedCollectible>>(
    `/grouped-collectible/owned?${params.toString()}`,
  )
  const { data, ...other } = response.data

  return { ...other, data }
}

export const GroupedCollectiblesApi = { getById, getMyCollectibles }
