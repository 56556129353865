import axios from 'axios'

import { config } from '@config'
import { setInterceptors } from '@utils'

const api = setInterceptors(
  axios.create({
    baseURL: config.baseURL,
  }),
)

api.defaults.headers.common['x-partner-id'] = config.partnerId
api.defaults.headers.common['Content-Type'] = 'application/json'
api.defaults.headers.common.Accept = 'application/json'

export const setAuthHeader = (token: string | null) => {
  api.defaults.headers.Authorization = token ? `Bearer ${token}` : null
}

export const setLanguaje = (lang: string) => {
  api.defaults.headers.common['x-lang'] = lang
}

export const removeAuthHeader = () => {
  delete api.defaults.headers.Authorization
}

export { api }
