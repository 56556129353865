import { useNavigate, useParams } from 'react-router-dom'
import * as React from 'react'
import { PropsWithChildren, useEffect, useState } from 'react'
import Box from '@mui/material/Box'

import { Title } from '@components'
import { useModal } from '@contexts'
import { ROUTES } from '@constants'
import { AppBase } from '@features/AppBase'
import { Images } from '@images'

import { Trivia, TriviaClientStatus } from '../../types'
import { QuestionCard } from '../../components'
import { TriviaCompletedModal, StartTriviaModal } from '../../modals'
import { useTrivia } from '../../hooks'

const Content = (props: PropsWithChildren) => {
  return (
    <Box
      display={'flex'}
      flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs: 'column' }}
      paddingTop={2.5}
      gap={4}
      justifyContent={'space-evenly'}
      flexWrap={'wrap'}
    >
      {props.children}
    </Box>
  )
}
const TriviaView = () => {
  const { showModal, dismissModal } = useModal()
  const navigate = useNavigate()
  const { id = '0' } = useParams()
  const [trivia, setTrivia] = useState<Trivia>()
  const { getTrivia, startTrivia, saveAnswer } = useTrivia(id, q => setTrivia(q))
  useEffect(() => {
    getTrivia()
  }, [id])

  useEffect(() => {
    if (trivia && trivia.quizClient) {
      if (trivia.quizClient.status === TriviaClientStatus.OPENED) {
        showModal({
          modal: (
            <StartTriviaModal
              onPlay={() => {
                startTrivia()
                dismissModal()
              }}
              onBack={() => {
                navigate(ROUTES.TRIVIAS)
                dismissModal()
              }}
            />
          ),
          options: { onClose: () => navigate(ROUTES.TRIVIAS) },
        })
      } else if (trivia.quizClient.status === TriviaClientStatus.FINISHED) {
        showModal({
          modal: (
            <TriviaCompletedModal
              points={trivia.quizClient.points}
              correctAnswers={trivia.quizClient.correctAnswers}
              totalQuestions={trivia.quizClient.totalQuestions}
              onClick={() => {
                dismissModal()
              }}
            />
          ),
          options: {
            onDismiss: () => {
              navigate(ROUTES.TRIVIAS)
            },
          },
        })
      }
    }
  }, [trivia])

  return (
    <AppBase requireActiveSession backgroundImage={Images.TriviasBackground}>
      <Box width={'100%'} px={{ lg: 16.5, md: 10, sm: 8, xs: 2 }} py={4}>
        <Title text={trivia?.name ?? ''} />
        <Content>
          {!!trivia && !!trivia.quizClient && trivia.quizClient.status != TriviaClientStatus.OPENED && (
            <QuestionCard
              trivia={trivia}
              onChange={(questionId, answers) => {
                const answersArray = Array.isArray(answers) ? answers : [answers]
                saveAnswer({ questionId, answersIds: answersArray.map(a => a.id) })
              }}
            />
          )}
        </Content>
      </Box>
    </AppBase>
  )
}

export { TriviaView }
