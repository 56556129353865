import { useQuery } from 'react-query'
import { useEffect, useState } from 'react'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { QUERY_KEYS, ChallengesApi } from '@api'
import { Challenge } from '@features/Challenges/types'
import { ApiError, ErrorEnum } from '@types'
import { QuotaExceededModal } from '@features/Quotas/modals'
import { ROUTES } from '@constants'
import { useModal } from '@contexts'

const useChallenge = (challengeId: number | string) => {
  const [delayedLoading, setDelayedLoading] = useState(false)
  const navigate = useNavigate()
  const { showModal } = useModal()
  const {
    data: challenge,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery<Challenge, ApiError>(
    [QUERY_KEYS.CHALLENGE, String(challengeId)],
    () => ChallengesApi.getById(challengeId),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: apiError => {
        if (apiError.type === ErrorEnum.QUOTA_EXCEEDED) {
          showModal({ modal: <QuotaExceededModal />, options: { onDismiss: () => navigate(ROUTES.CHALLENGES) } })
        } else {
          navigate(ROUTES.CHALLENGES)
        }
      },
    },
  )

  useEffect(() => {
    const changeDelayedLoading = setTimeout(
      () => {
        setDelayedLoading(isLoading || isRefetching)
      },
      isLoading || isRefetching ? 0 : 1000,
    )

    return () => clearTimeout(changeDelayedLoading)
  }, [isLoading, isRefetching])

  return { challenge, isLoading: delayedLoading, refetch }
}

export { useChallenge }
