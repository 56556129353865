import * as yup from 'yup'

import { useTranslation } from '@hooks'

export const useForgotPasswordValidationSchema = () => {
  const { t } = useTranslation()

  const forgotPasswordValidationSchema = yup.object({
    username: yup
      .string()
      .email(t('forgot-password.modals.initial.validation.email'))
      .required(t('forgot-password.modals.initial.validation.required')),
  })

  return { forgotPasswordValidationSchema }
}
