import { QUERY_KEYS } from '@api'
import { usePaginatedRequestWithFilters } from '@hooks'
import { TriviasApi } from '@features/Trivias/api'
import { Trivia, TriviasFilters } from '@features/Trivias/types'

const usePreviousTrivias = () => {
  const { isLoading, data, changeFilters, changePage, paginationWithFilters } = usePaginatedRequestWithFilters<
    TriviasFilters,
    Trivia
  >({}, QUERY_KEYS.PREVIOUS_TRIVIAS, TriviasApi.paginatePreviousTrivias)

  return { isLoading, data, changeFilters, changePage, paginationWithFilters }
}

export { usePreviousTrivias }
