import * as React from 'react'
import { useMediaQuery } from '@mui/material'
import { useMemo } from 'react'

import { CustomButton, TextTitleBold } from '@components'
import { Collectible, PropsWithCollectible } from '@types'
import { Images } from '@images'
import { useMetadataToText, useTranslation } from '@hooks'
import { MediaQuery, MqTablet } from '@styles'

import {
  CollectibleItemCardBodyBlock,
  CollectibleItemCardBottom,
  CollectibleItemCardContentBlock,
  CollectibleItemCardImage,
} from './styled'

const CollectibleItemCard = (
  props: PropsWithCollectible<{
    onClick: (collectible: Collectible | undefined) => void
    isSelected: boolean
    isTemporaryLocked: boolean
  }>,
) => {
  const { collectible, onClick, isSelected, isTemporaryLocked } = props
  const isLocked = isTemporaryLocked || (collectible?.locked ?? false)
  const { getName } = useMetadataToText()
  const { t } = useTranslation()

  const isTabletOrDesktop = useMediaQuery(MediaQuery[MqTablet].replace(/^@media( ?)/m, ''))

  const name = useMemo(() => {
    const itemName = getName(collectible?.metadata)
    const lenLimit = isTabletOrDesktop ? 35 : 22
    if (itemName.length > lenLimit) {
      return itemName.slice(0, lenLimit - 3) + '...'
    }

    return itemName
  }, [collectible, isTabletOrDesktop])

  return (
    <CollectibleItemCardContentBlock
      sx={isLocked ? { borderColor: '#FF0000', '&:hover': { borderColor: '#FF0000' } } : {}}
    >
      <CollectibleItemCardBodyBlock id={'body'}>
        <CollectibleItemCardImage
          src={collectible?.metadata?.mediaFiles.frontImage ?? Images.TShirtPlaceholder}
          id={'image'}
        />
        <TextTitleBold>{name}</TextTitleBold>
      </CollectibleItemCardBodyBlock>
      <CollectibleItemCardBottom id={'actions'}>
        {!isSelected && (
          <CustomButton
            variant={'primary'}
            label={t('collectible.actions.apply')}
            fullWidth
            onClick={() => onClick(collectible)}
            disabled={isLocked}
          />
        )}
        {isSelected && (
          <CustomButton
            disabled={collectible?.locked}
            variant={'secondary'}
            label={t('collectible.actions.remove')}
            fullWidth
            onClick={() => onClick(undefined)}
          />
        )}
      </CollectibleItemCardBottom>
    </CollectibleItemCardContentBlock>
  )
}

export { CollectibleItemCard }
