import { MaskedTextInput, TextH4Bold } from '@components'

import { PredictionRow } from '../PredictionRow'

interface NumericPredictionRowProps {
  title: string
  inputName: string
  localValue: number
  visitorValue: number
  onLocalChange: (name: string, value: number) => void
  onVisitorChange: (name: string, value: number) => void
}
const NumericPredictionRow = (props: NumericPredictionRowProps) => {
  const { title, inputName, onLocalChange, onVisitorChange, localValue, visitorValue } = props

  return (
    <PredictionRow
      leftCellContent={
        <MaskedTextInput
          definitions={{ '#': /^\d+$/ }}
          mask={'####'}
          name={inputName}
          onChange={event => !!event.target.value && onLocalChange(inputName, Number(event.target.value) ?? 0)}
          onBlur={event => !event.target.value && onLocalChange(inputName, Number(event.target.value) ?? 0)}
          value={`${localValue}`}
        />
      }
      centralCellContent={<TextH4Bold sx={{ textAlign: 'center', marginBottom: '16px' }}>{title}</TextH4Bold>}
      rightCellContent={
        <MaskedTextInput
          definitions={{ '#': /^\d+$/ }}
          mask={'####'}
          name={inputName}
          onChange={event => !!event.target.value && onVisitorChange(inputName, Number(event.target.value) ?? 0)}
          onBlur={event => !event.target.value && onVisitorChange(inputName, Number(event.target.value) ?? 0)}
          value={`${visitorValue}`}
        />
      }
    />
  )
}

export { NumericPredictionRow }
