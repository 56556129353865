import { useCallback, useState } from 'react'
import * as monei from '@monei-js/components'

import { useModal } from '@contexts'
import { PaymentsApi } from '@features/Coins/api'
import { CoinsBudget } from '@features/Coins/types'
import { BuyCoinsErrorModal, BuyCoinsSuccessModal } from '@features/Coins/modals'

const useCoinsPayment = (onSuccess: () => void) => {
  const { showModal } = useModal()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<unknown>()

  const payCoins = useCallback(
    async (budget: CoinsBudget | undefined) => {
      setIsLoading(true)
      if (budget && !isLoading) {
        const coins = budget.coins!
        try {
          const paymentRequest = await PaymentsApi.createCoinsPayment(budget)
          const result = await monei.confirmPayment({
            paymentId: paymentRequest.paymentId,
          })
          if (result.status === 'SUCCEEDED') {
            setError(null)
            showModal({ modal: <BuyCoinsSuccessModal coins={coins} /> })
            onSuccess()
          } else {
            showModal({ modal: <BuyCoinsErrorModal error={result.statusMessage ?? ''} /> })
            setError(result)
          }
        } catch (error) {
          setError(error)
        }
      }
      setIsLoading(false)
    },
    [isLoading],
  )

  return { payCoins, isLoading, isError: !!error, error }
}

export { useCoinsPayment }
