import { styled } from '@mui/material/styles'

const Preview = styled('img')(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  backgroundColor: '#FFFFFF',
  borderWidth: 1,
  borderStyle: 'solid',
  height: theme.spacing(6),
  width: theme.spacing(8),
  objectFit: 'contain',
}))

export { Preview }
