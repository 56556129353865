import Box from '@mui/material/Box'
import { useMemo } from 'react'

import { Alignment, Player } from '@features/Predictions/types'
import { AlignmentRow } from '@features/Predictions/components/AlignmentRow'
import { PredictionRow } from '@features/Predictions/components/PredictionRow'
import { TextBodyRegular, TextH2Bold } from '@components'
import { colors } from '@styles'
import { useTranslation } from '@hooks'

interface Props {
  alignment?: Alignment
}

interface PlayerRow {
  local: Player | undefined
  visitor: Player | undefined
}
const AlignmentTab = (props: Props) => {
  const { alignment } = props
  const { t } = useTranslation()
  const comparePlayers = (a: Player, b: Player) => {
    return Number(a.dorsal ?? '0') - Number(b.dorsal ?? '0')
  }

  const rows = useMemo<PlayerRow[]>(() => {
    if (alignment) {
      const { visitorTeamSquad, localTeamSquad } = alignment
      visitorTeamSquad.sort(comparePlayers)
      localTeamSquad.sort(comparePlayers)

      const result: PlayerRow[] = localTeamSquad.map((player, index) => {
        return { local: player, visitor: visitorTeamSquad[index] }
      })

      if (visitorTeamSquad.length > localTeamSquad.length) {
        for (let index = localTeamSquad.length; index < visitorTeamSquad.length; index++) {
          result.push({ local: undefined, visitor: visitorTeamSquad[index] })
        }
      }

      return result
    }

    return []
  }, [alignment])

  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
      <PredictionRow
        sx={theme => ({ marginBottom: theme.spacing(1) })}
        leftCellContent={<TextBodyRegular>{t('predictions.alignment.local')}</TextBodyRegular>}
        centralCellContent={
          <TextH2Bold sx={{ color: colors.primary.main.background, textAlign: 'center' }}>
            {t('predictions.alignment.title')}
          </TextH2Bold>
        }
        rightCellContent={<TextBodyRegular>{t('predictions.alignment.visitor')}</TextBodyRegular>}
      />
      {rows.map((row, index) => (
        <AlignmentRow key={`index-${index}`} local={row.local} visitor={row.visitor} />
      ))}
    </Box>
  )
}

export { AlignmentTab }
