import {styled} from "@mui/material/styles";
import * as React from "react";

const HeaderImageContainer = styled('div')(()=>({
    display: 'flex',
    height: 150,
    alignItems: 'center',
    alignSelf: 'stretch',
    flexGrow:1
}))

const StyledHeaderImage = styled('div')(()=>({
    flex: '1 0 0',
    backgroundSize:'cover',
    backgroundRepeat:'no-repeat',
    backgroundPositionY:'center',
    alignSelf: 'stretch'
}))

interface HeaderImageProps {
    image: string
}
const HeaderImage = (props: HeaderImageProps) => {
    return (    <HeaderImageContainer>
        <StyledHeaderImage style={{  backgroundImage: `url(${props.image})`}}/>
    </HeaderImageContainer>)
}

export {HeaderImage}
