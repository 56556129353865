import { useQuery } from 'react-query'

import { QUERY_KEYS, GroupedCollectiblesApi } from '@api'

const useMyCollectibles = (page: number) => {
  const { data, isLoading } = useQuery(
    [QUERY_KEYS.MY_COLLECTIBLES, page],
    () => GroupedCollectiblesApi.getMyCollectibles(page),
    {
      refetchOnWindowFocus: false,
    },
  )

  return { data, isLoading }
}

export { useMyCollectibles }
