import { styled } from '@mui/material/styles'
import Menu from '@mui/material/Menu'

import { colors } from '@styles'

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: theme.spacing(1.5),
    marginTop: theme.spacing(1),
    minWidth: 180,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    backgroundColor: colors.grayscale['80'].background,
    color: colors.grayscale['10'],
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      backgroundColor: colors.grayscale['80'].background,
      color: colors.grayscale['10'].background,
      '&:hover': {
        color: theme.palette.primary.light,
        '& .MuiListItemIcon-root > svg': {
          color: theme.palette.primary.light,
        },
      },
    },
  },
}))

export { StyledMenu }
