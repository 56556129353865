import { useNavigate, useParams } from 'react-router-dom'
import { useMemo } from 'react'
import Box from '@mui/material/Box'
import * as React from 'react'

import { CustomButton, Title, ItemImages, Loading, ItemMetadata, Content, Header } from '@components'
import { useMetadataToText, useTranslation, useItem } from '@hooks'
import { ROUTES } from '@constants'
import { AppBase } from '@features/AppBase'
import { Images } from '@images'

const GalleryItemView = () => {
  const { id = '0' } = useParams()
  const { item, isLoading } = useItem(id)
  const navigate = useNavigate()
  const { getName } = useMetadataToText()
  const { t } = useTranslation()

  const images = useMemo(
    () =>
      item
        ? [
            item.metadata.mediaFiles.frontImage,
            item.metadata.mediaFiles.backImage,
            item.metadata.mediaFiles.leftArmImage,
            item.metadata.mediaFiles.rightArmImage,
          ]
        : [],
    [item],
  )

  return (
    <AppBase requireActiveSession backgroundImage={Images.GalleryBackground}>
      <Box width={'100%'} px={{ lg: 16.5, md: 10, sm: 8, xs: 2 }} py={4}>
        <Header>
          <CustomButton
            variant={'secondary'}
            label={t('my-shirts.view.back')}
            startIcon={['fas', 'chevron-left']}
            onClick={() => navigate(ROUTES.MY_SHIRTS)}
          />
          <Title text={getName(item?.metadata)} />
        </Header>

        <Content flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs: 'column' }} sx={{ alignItems: 'flex-start' }}>
          {isLoading && <Loading />}
          {item && (
            <>
              <ItemImages images={images} />
              <Box flexDirection={'column'} display={'flex'} flexGrow={4} flexShrink={0}>
                <ItemMetadata metadata={item.metadata} />
              </Box>
            </>
          )}
        </Content>
      </Box>
    </AppBase>
  )
}

export { GalleryItemView }
