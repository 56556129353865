import { FormControl, InputLabel, OutlinedInput, FormHelperText, OutlinedInputProps } from '@mui/material'
import * as React from 'react'
import { IMaskInput } from 'react-imask'
import { Definitions } from 'imask/masked/pattern'

import { TextInputProp } from '../types'
import { InputStyle, LabelStyle } from '../styles'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
  mask: string
  definitions: Definitions
}
const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>((props, ref) => {
  const { onChange, mask, definitions, ...other } = props

  return (
    <IMaskInput
      {...other}
      mask={mask}
      definitions={definitions}
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  )
})

const Input = (props: OutlinedInputProps) => (
  <OutlinedInput className={'ShiTextInput-input'} type={'text'} sx={InputStyle} {...props} />
)

interface LabelProps {
  label?: React.ReactNode
}
const Label = (props: LabelProps) => (
  <InputLabel className={'ShiTextInput-label'} sx={LabelStyle}>
    {props.label}
  </InputLabel>
)

const TextInput = (props: TextInputProp) => {
  const { helperText, formControlSx, ...otherProps } = props

  return (
    <FormControl
      className={'ShiTextInput-form-control'}
      variant='outlined'
      fullWidth={otherProps.fullWidth}
      sx={formControlSx}
    >
      {otherProps.label && <Label label={otherProps.label} />}
      <Input {...otherProps} />
      {helperText && <FormHelperText error={otherProps.error}>{helperText}</FormHelperText>}
    </FormControl>
  )
}

const MaskedTextInput = (props: TextInputProp & CustomProps) => {
  const { helperText, mask, definitions, name, onChange, ...otherProps } = props

  return (
    <FormControl className={'ShiMaskedTextInput-form-control'} variant='outlined' fullWidth={otherProps.fullWidth}>
      <Label label={otherProps.label} />
      <Input
        className={'ShiMaskedTextInput-input'}
        type={'text'}
        sx={InputStyle}
        inputComponent={TextMaskCustom as any}
        inputProps={{ mask, definitions, name, onChange }}
        {...otherProps}
      />
      {helperText && <FormHelperText error={otherProps.error}>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export { TextInput, MaskedTextInput, Input, Label }
