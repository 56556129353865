import { useEffect } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { ROUTES } from '@constants'
import { ForgotPasswordChangePasswordModal } from '@features/ForgotPassword/modals'
import { AuthApi } from '@api'
import { useFlash, useModal } from '@contexts'
import { useTranslation } from '@hooks'

const usePasswordResetAndEmailValidation = () => {
  const { showModal } = useModal()
  const { showFlash } = useFlash()
  const { pathname } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  useEffect(() => {
    if (setSearchParams) {
      const token = searchParams.get('token')
      const username = searchParams.get('username')
      if (token && username && pathname && pathname === ROUTES.RESET_PASSWORD) {
        showModal({ modal: <ForgotPasswordChangePasswordModal token={token} username={username} /> })
      }
      if (token && username && pathname && pathname === ROUTES.VALIDATE_EMAIL) {
        AuthApi.validateEmail({ username, token })
          .then(() => {
            showFlash({ message: t('email-validation.success'), severity: 'success' })
            navigate(ROUTES.HOME)
          })
          .catch(() => {
            showFlash({ message: t('email-validation.error'), severity: 'error' })
          })
      }
      setSearchParams(new URLSearchParams())
    }
  }, [setSearchParams, pathname, t])
}

export { usePasswordResetAndEmailValidation }
