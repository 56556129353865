import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { Item, ItemMediaFiles } from '@types'
import { useModal } from '@contexts'
import { ROUTES } from '@constants'

import { DigitizeForm } from '../types'
import { DigitizeApi } from '../api'
import { SuccessModal } from '../modals/SuccessModal'
import { ErrorModal } from '../modals/ErrorModal'
import { useSessionUserUpdater } from '../../../hooks/useSessionUserUpdater'

type ContextState = {
  itemMediaFiles: ItemMediaFiles
  signed: boolean
  setItemMediaFiles: (state: ItemMediaFiles) => void
  setSigned: (signed: boolean) => void
  onImageMediaFileSelected: (name: string, value: string) => void
  digitize: (form: DigitizeForm) => void
}

const DigitizeContext = React.createContext<ContextState | null>(null)

export function DigitizeProvider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate()
  const { showModal } = useModal()

  const [itemMediaFiles, setItemMediaFiles] = useState<ItemMediaFiles>({
    frontImage: '',
    backImage: '',
    rightArmImage: '',
    leftArmImage: '',
  })
  const [signed, setSigned] = useState<boolean>(false)

  const onImageMediaFileSelected = useCallback(
    (name: string, value: string) => {
      setItemMediaFiles({ ...itemMediaFiles, [name]: value })
    },
    [itemMediaFiles],
  )

  useEffect(() => {
    if (!signed && itemMediaFiles.signatureImage !== undefined) {
      const { signatureImage: _, ...otherImages } = itemMediaFiles
      setItemMediaFiles({ ...otherImages })
    }
  }, [signed, itemMediaFiles])

  const { refresh } = useSessionUserUpdater()

  const { mutate } = useMutation(DigitizeApi.digitize, {
    onSuccess: (item: Item) => {
      showModal({
        modal: <SuccessModal />,
        options: {
          onDismiss: () => {
            navigate(`${ROUTES.MY_SHIRTS}/${item.id}`)
            refresh()
          },
        },
      })
    },
    onError: () => {
      showModal({ modal: <ErrorModal /> })
    },
  })

  const digitize = useCallback(
    (form: DigitizeForm) => {
      const { brand, equipment, year, dorsal, team, type, patches: _, patched: __, ...other } = form
      mutate({
        metadata: {
          brand: brand[0]!,
          equipmentType: equipment[0]!,
          team: team!,
          type: type!,
          year: Number(year),
          dorsal: Number(dorsal),
          ...other,
          mediaFiles: {
            ...itemMediaFiles,
          },
        },
      })
    },
    [itemMediaFiles, mutate],
  )

  return (
    <DigitizeContext.Provider
      value={{ signed, setSigned, setItemMediaFiles, itemMediaFiles, onImageMediaFileSelected, digitize }}
    >
      {children}
    </DigitizeContext.Provider>
  )
}

export function useDigitize(): ContextState {
  const context = useContext(DigitizeContext)
  if (!context) throw Error('This hook can only be used under the DigitizeProvider component')

  return context
}
