import { api } from '@api'

import { Transaction } from '../types'

const getTransactions = async () => {
  const response = await api.get<Transaction[]>(`/transactions`)

  return response.data
}

export const TransactionsApi = { getTransactions }
