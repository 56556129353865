import { colors } from '@styles'

export const LabelStyle = theme => ({
  backgroundColor: colors.grayscale['10'].background,
  color: colors.grayscale['10'].contrast,
  borderRadius: theme.spacing(0.75),
  paddingRight: 1,
  paddingLeft: 1,
  '&.Mui-focused': {
    color: colors.grayscale['10'].contrast,
  },
})

export const InputStyle = theme => ({
  backgroundColor: colors.grayscale['10'].background,
  borderRadius: theme.spacing(1.5),
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: colors.grayscale['30'].background,
    borderWidth: 2,
  },
  ':hover': {
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.light,
    },
  },
})
