import { useMemo } from 'react'
import { Chip } from '@mui/material'

import { SubscriptionType } from '@types'

interface Props {
  subscriptionType: SubscriptionType | undefined
}
const SubscriptionChip = (props: Props) => {
  const { subscriptionType } = props

  const text = useMemo(() => {
    switch (subscriptionType) {
      case SubscriptionType.FREEMIUM:
        return 'FREEMIUM'
      case SubscriptionType.PREMIUM:
        return 'PREMIUM'
      default:
        return null
    }
  }, [subscriptionType])

  return !!text ? (
    <Chip
      label={text}
      color={'primary'}
      size={'small'}
      sx={{
        fontWeight: 700,
        fontSize: 8,
        height: 14,
        '&.MuiChip-label': {
          paddingRight: 0.75,
          paddingLeft: 0.75,
        },
      }}
    />
  ) : null
}

export { SubscriptionChip }
