import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { CollectiblesApi, QUERY_KEYS } from '@api'

const useCollectible = (id: number | string) => {
  const navigate = useNavigate()

  const { data: collectible, isLoading } = useQuery(
    [QUERY_KEYS.COLLECTIBLE_ON_SALE, String(id)],
    () => CollectiblesApi.getOnSaleById(id),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      onError: () => {
        navigate('/')
      },
    },
  )
  if (collectible && collectible.owner && collectible.metadata) {
    collectible.metadata.owner = collectible.owner
    collectible.metadata.item = collectible.item
  }

  return { collectible, isLoading }
}

export { useCollectible }
