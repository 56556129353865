import { api } from '@api'

import { CoinsBudget, CreatePaymentResponse } from '../types'

const getCoinsBudget = async (data: CoinsBudget) => {
  const response = await api.post<CoinsBudget>(`/payments/calculate-budget`, data)

  return response.data
}

const createCoinsPayment = async (data: CoinsBudget) => {
  const response = await api.post<CreatePaymentResponse>(`/payments/coins`, data)

  return response.data
}

const createActivationCodePayment = async () => {
  const response = await api.post<CreatePaymentResponse>(`/payments/activation-code`)

  return response.data
}

export const PaymentsApi = { getCoinsBudget, createCoinsPayment, createActivationCodePayment }
