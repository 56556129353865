import { useQuery } from 'react-query'
import { useEffect, useState } from 'react'

import { QUERY_KEYS } from '@api'
import { PredictionsApi } from '@features/Predictions/api'

const usePrediction = (id: number | string) => {
  const [delayedLoading, setDelayedLoading] = useState(false)
  const {
    data: prediction,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery([QUERY_KEYS.PREDICTION, String(id)], () => PredictionsApi.getById(id), {
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    const changeDelayedLoading = setTimeout(
      () => {
        setDelayedLoading(isLoading || isRefetching)
      },
      isLoading || isRefetching ? 0 : 1000,
    )

    return () => clearTimeout(changeDelayedLoading)
  }, [isLoading, isRefetching])

  return { prediction, isLoading: delayedLoading, refetch }
}

export { usePrediction }
