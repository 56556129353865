import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

import { colors } from '@styles'
import { RoundedCornersBox } from '@components'

const ChallengeRequiredItemCardImage = styled('img')(({ theme }) => ({
  borderRadius: theme.spacing(2),
  borderColor: theme.palette.primary.main,
  backgroundColor: '#FFFFFF',
  borderWidth: 1,
  borderStyle: 'solid',
  width: '100%',
  objectFit: 'contain',
  height: theme.spacing(30),
  transition: 'height 0.5s linear',
}))

const ChallengeRequiredItemCardBottom = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: colors.grayscale['60'].background,
  borderBottomLeftRadius: theme.spacing(2),
  borderBottomRightRadius: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.primary.main}`,
  transition: 'opacity 1s linear',
  padding: theme.spacing(2),
  justifyContent: 'center',
  height: theme.spacing(10),
  position: 'absolute',
  bottom: 0,
  opacity: 0,
  display: 'flex',
  visibility: 'hidden',
}))

const ChallengeRequiredItemCardContentBlock = styled(RoundedCornersBox)(({ theme }) => ({
  display: 'block',
  marginBottom: theme.spacing(4),
  position: 'relative',
  height: theme.spacing(55),
  width: theme.spacing(30),
  '&:hover, &.checked': { borderColor: colors.primary.light.background },
  '&:hover #image, &.checked #image': {
    height: theme.spacing(20),
  },
  '&:hover #actions, &.checked #actions': {
    display: 'flex',
    opacity: 1,
    visibility: 'visible',
  },
  overflow: 'hidden',
}))

const ChallengeRequiredItemCardBodyBlock = styled(Box)(({ theme }) => ({
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
}))

export {
  ChallengeRequiredItemCardImage,
  ChallengeRequiredItemCardBottom,
  ChallengeRequiredItemCardContentBlock,
  ChallengeRequiredItemCardBodyBlock,
}
