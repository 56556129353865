import { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { PaginatedRequest, PaginatedResponse } from '@types'
import { useDebounce } from '@hooks'

const usePaginatedRequestWithFilters = <FiltersType, DataType>(
  genericFilters: FiltersType,
  queryKey: string,
  requestHandler: (params: PaginatedRequest<FiltersType>) => Promise<PaginatedResponse<DataType>>,
) => {
  const [paginationWithFilters, updatePaginationWithFilters] = useState<PaginatedRequest<FiltersType>>({
    page: 0,
    filters: { ...genericFilters },
  })
  const { page, filters } = paginationWithFilters
  const { isLoading, data } = useQuery([queryKey, page, filters], () => requestHandler(paginationWithFilters))

  const [internalFilters, updateInternalFilters] = useState<FiltersType>(filters)
  const debouncedFilters = useDebounce(internalFilters)

  const changeFilters = useCallback(
    (f: FiltersType) => {
      updateInternalFilters({ ...f })
    },
    [updateInternalFilters],
  )

  const changePage = useCallback(
    (page: number) => {
      updatePaginationWithFilters({ ...paginationWithFilters, page })
    },
    [updatePaginationWithFilters, paginationWithFilters],
  )

  useEffect(() => {
    if (JSON.stringify(debouncedFilters) != JSON.stringify(paginationWithFilters.filters)) {
      updatePaginationWithFilters({ ...paginationWithFilters, filters: { ...debouncedFilters }, page: 0 })
    }
  }, [debouncedFilters, updatePaginationWithFilters, paginationWithFilters])

  return { isLoading, data, changeFilters, changePage, paginationWithFilters }
}

export { usePaginatedRequestWithFilters }
