import { CircularProgress } from '@mui/material'
import Box from '@mui/material/Box'
import * as React from 'react'

export const Loading = () => {
  return (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
      <CircularProgress />
    </Box>
  )
}
