import Box from '@mui/material/Box'
import * as React from 'react'

import { PaginatedResponse } from '@types'
import { RankingPrediction, RankingPredictionsComparator } from '@features/Predictions/types'
import { PaginatedTable, TableActions, TableColumns, TextCaptionBold } from '@components'
import { colors } from '@styles'
import { useTranslation } from '@hooks'

interface Props {
  data: PaginatedResponse<RankingPrediction>
  onChangePage: (page: number) => void
}

const RankingTable = (props: Props) => {
  const { data, onChangePage } = props
  const { t } = useTranslation()
  const columns: (TableColumns<RankingPrediction> | TableActions<RankingPrediction>)[] = [
    {
      width: '10%',
      field: 'position',
      sorteable: false,
      type: 'column',
      headerName: t('ranking-trivia.header.position'),
      getValue: item => {
        return item.position
      },
      renderCell: (_item, value) => {
        return (
          <Box
            display={'flex'}
            flexDirection={'column'}
            sx={{ minHeight: 40, justifyContent: 'center', paddingLeft: 1 }}
          >
            <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>{value}</TextCaptionBold>
          </Box>
        )
      },
    },
    {
      width: '30%',
      field: 'name',
      sorteable: false,
      type: 'column',
      headerName: t('ranking-trivia.header.name'),
      renderCell: (item, value) => {
        return (
          <Box display={'flex'} flexDirection={'column'}>
            <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>{value}</TextCaptionBold>
          </Box>
        )
      },
      getValue: item => `${item.name || ''}`,
    },
    {
      width: '30%',
      field: 'team',
      sorteable: false,
      type: 'column',
      headerName: t('ranking-trivia.header.team'),
      renderCell: (_item, value) => {
        return <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>{value}</TextCaptionBold>
      },
      getValue: item => item.team ?? '---',
    },
    {
      width: '15%',
      field: 'points',
      sorteable: false,
      type: 'column',
      headerName: t('ranking-trivia.header.points'),
      renderCell: (_item, value) => {
        return (
          <Box display={'flex'} flexDirection={'column'}>
            <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>{value}</TextCaptionBold>
          </Box>
        )
      },
      getValue: item => {
        return item.points ?? '-'
      },
    },
  ]

  return (
    <PaginatedTable<RankingPrediction>
      columns={columns}
      data={data}
      onChangePage={onChangePage}
      borderColor={'#C7C7C7'}
      extraItemComparator={RankingPredictionsComparator}
    />
  )
}

export { RankingTable }
