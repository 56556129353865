import { useQuery } from 'react-query'
import { useMemo } from 'react'
import { PhoneNumberUtil } from 'google-libphonenumber'

import { CountriesApi, QUERY_KEYS } from '@api'
import { CountryCode } from '@types'

const phoneUtil = PhoneNumberUtil.getInstance()

export const useCountryCodes = () => {
  const { isLoading, data } = useQuery([QUERY_KEYS.COUNTRIES], () => CountriesApi.all())

  const countryCodes = useMemo(() => {
    const countryCodes: CountryCode[] = []
    if (data) {
      data.forEach(country => {
        countryCodes.push(
          new CountryCode(`${phoneUtil.getCountryCodeForRegion(country.code)}`, country.name, country.flag),
        )
      })
    }

    return countryCodes
  }, [data])

  return { isLoading, countryCodes }
}
