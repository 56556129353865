import querystring from 'query-string'
import { useNavigate } from 'react-router-dom'
import { PropsWithChildren, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import * as React from 'react'

import { useTranslation } from '@hooks'
import { Collectible, ItemFilters } from '@types'
import { CustomButton, Loading, TextH2Bold, TextH3Bold } from '@components'
import { ROUTES } from '@constants'
import { useModal } from '@contexts'
import { ChallengeSaveChangesModal } from '@features/Challenges/modals'

import { useMyCollectiblesWithFilters } from '../../hooks'
import { ChallengeRequirementItem } from '../../types'
import { CollectibleItemCard } from '../CollectibleItemCard'

const CollectiblesForRequiredItemContent = (props: PropsWithChildren) => {
  return (
    <Box
      display={'flex'}
      flexDirection={{ xl: 'row', lg: 'row', md: 'row', sm: 'row', xs: 'column' }}
      paddingTop={4}
      gap={4}
      justifyContent={'space-evenly'}
      flexWrap={'wrap'}
    >
      {props.children}
    </Box>
  )
}

interface Props {
  pendingChanges: boolean
  onSave: () => void
  requiredItem: ChallengeRequirementItem
  requiredItemsWithCollectible: ChallengeRequirementItem[]
  onCollectibleSelected: (collectible: Collectible | undefined, requiredItem: ChallengeRequirementItem) => void
}

const CollectiblesForRequiredItem = (props: Props) => {
  const { showModal } = useModal()
  const { requiredItem, pendingChanges, onSave, requiredItemsWithCollectible, onCollectibleSelected } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [collectibles, setCollectibles] = useState<Collectible[]>([])
  const [queryFilters, setQueryFilters] = useState<string>('')

  const { data, isLoading, changeFilters } = useMyCollectiblesWithFilters()

  useEffect(() => {
    if (requiredItem) {
      const { name, type, team, year, season, brand, dorsal, equipmentType, signed } = requiredItem.metadata

      const filters: ItemFilters = {}
      if (name) {
        filters['name'] = name
      }
      if (type) {
        filters['type'] = [type]
      }
      if (team) {
        filters['teamId'] = [team.id]
      }
      if (brand) {
        filters['brandId'] = [brand.id]
      }
      if (equipmentType) {
        filters['equipmentType'] = [equipmentType]
      }
      if (year) {
        filters['year'] = year
      }
      if (season) {
        filters['season'] = season
      }
      if (dorsal) {
        filters['dorsal'] = dorsal
      }
      if (signed !== undefined) {
        filters['isSigned'] = signed
      }
      changeFilters(filters)
      setQueryFilters(querystring.stringify(filters))
    } else {
      setCollectibles([])
      setQueryFilters('')
    }
  }, [requiredItem])

  useEffect(() => {
    setCollectibles(data?.data ?? [])
  }, [data])

  const isTemporaryLocked = (collectible: Collectible) => {
    return !!requiredItemsWithCollectible.find(r => r.id != requiredItem.id && r.collectible?.id === collectible.id)
  }

  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
      <Box display={'flex'} flexDirection={'row'} width={'100%'} justifyContent={'space-between'}>
        <TextH2Bold>{t('challenges.view.collectibles')}</TextH2Bold>
        <CustomButton
          variant={'secondary'}
          label={t('challenges.view.actions.search')}
          onClick={
            pendingChanges
              ? () =>
                  showModal({
                    modal: <ChallengeSaveChangesModal onSave={onSave} />,
                    options: { onDismiss: () => navigate(`${ROUTES.MARKETPLACE}?${queryFilters}`) },
                  })
              : () => navigate(`${ROUTES.MARKETPLACE}?${queryFilters}`)
          }
          startIcon={['fas', 'search']}
        />
      </Box>
      {isLoading && <Loading />}

      {collectibles.length > 0 && (
        <CollectiblesForRequiredItemContent>
          {collectibles.map(collectible => (
            <CollectibleItemCard
              isTemporaryLocked={isTemporaryLocked(collectible)}
              isSelected={collectible.id === requiredItem.collectible?.id}
              collectible={collectible}
              onClick={c => onCollectibleSelected(c, requiredItem)}
            />
          ))}
        </CollectiblesForRequiredItemContent>
      )}
      {requiredItem && !isLoading && collectibles.length == 0 && (
        <Box alignSelf={'center'}>
          <TextH3Bold>{t('challenges.view.no-matching-items')} </TextH3Bold>
        </Box>
      )}
    </Box>
  )
}

export { CollectiblesForRequiredItem }
