import Box from '@mui/material/Box'
import * as React from 'react'
import { useCallback } from 'react'

import { useModal } from '@contexts'
import { CustomButton, Modal, TextH2Bold } from '@components'
import { Images } from '@images'
import { useTranslation } from '@hooks'
import { Collectible } from '@types'
import { CollectiblesApi } from '@api'

interface Props {
  collectible: Collectible
}
const RemoveFromSaleModal = (props: Props) => {
  const { collectible } = props
  const { dismissModal } = useModal()
  const { t } = useTranslation()
  const removeCollectibleFromSale = useCallback(async () => {
    await CollectiblesApi.removeFromSaleById(collectible.id)
    dismissModal()
  }, [collectible, dismissModal])

  return (
    <Modal.Content>
      <Modal.Header image={Images.WarningImage}>
        <Box display={'flex'} flexDirection={'column'} gap={0} pt={1.5}>
          <TextH2Bold>{t('collectible.modal.remove-from-sale.title')}</TextH2Bold>
        </Box>
      </Modal.Header>

      <Modal.Actions>
        <CustomButton
          variant={'secondary'}
          label={t('collectible.modal.remove-from-sale.actions.back')}
          fullWidth={true}
          onClick={() => dismissModal()}
        />
        <CustomButton
          variant={'primary'}
          label={t('collectible.modal.remove-from-sale.actions.submit')}
          fullWidth={true}
          onClick={removeCollectibleFromSale}
        />
      </Modal.Actions>
    </Modal.Content>
  )
}

export { RemoveFromSaleModal }
