import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { colors } from '@styles'
import { useModal } from '@contexts'

import { ItemBigImageModal } from '../ItemBigImageModal'

const Image = styled('img')(({ theme }) => ({
  borderRadius: theme.spacing(2),
  borderColor: theme.palette.primary.main,
  backgroundColor: '#FFFFFF',
  borderWidth: 1,
  borderStyle: 'solid',
  height: theme.spacing(60),
  objectFit: 'contain',
}))

const Thumbnail = styled('img')(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  width: '100%',
  height: theme.spacing(8),
  objectFit: 'cover',
  borderRadius: theme.spacing(0.5),
  margin: theme.spacing(0.5),
  cursor: 'pointer',
}))

const Thumbnails = (props: { images: string[]; onImageSelected: (index: number) => void }) => {
  const { images, onImageSelected } = props

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      sx={theme => ({
        borderRadius: theme.spacing(1),
        borderColor: theme.palette.primary.main,
        borderWidth: 1,
        borderStyle: 'solid',
        overflow: 'hidden',
      })}
    >
      {images.map((image, index) => (
        <Thumbnail src={image} onClick={() => onImageSelected(index)} key={`thumbnail-${index}`} />
      ))}
    </Box>
  )
}

const ItemImages = (props: { images: string[] }) => {
  const { showModal } = useModal()
  const { images } = props
  const [currentImageIndex, updateCurrentImageIndex] = useState(0)

  const image = useMemo(() => {
    if (currentImageIndex < images.length) {
      return images[currentImageIndex]
    }

    return undefined
  }, [images, currentImageIndex])

  const handleUpdateImageIndex = (newIndex: number) => {
    const imagesCount = images.length
    if (imagesCount > 0) {
      updateCurrentImageIndex(newIndex > 0 ? newIndex % imagesCount : imagesCount - 1)
    }
  }

  return images.length > 0 ? (
    <Box display={'flex'} flexDirection={'column'} gap={2} position={'relative'} flexShrink={2}>
      {image && (
        <FontAwesomeIcon
          icon={'magnifying-glass-plus'}
          color={colors.primary.main.background}
          style={{ position: 'absolute', top: 16, right: 16, padding: 8, cursor: 'pointer' }}
          onClick={() =>
            showModal({
              modal: <ItemBigImageModal image={image} />,
              options: { modalProps: { width: '80%' } },
            })
          }
        />
      )}
      <FontAwesomeIcon
        icon={'chevron-right'}
        color={colors.primary.main.background}
        style={{ position: 'absolute', top: 'calc(50% - 32px)', right: 16, padding: 8, cursor: 'pointer' }}
        onClick={() => handleUpdateImageIndex(currentImageIndex + 1)}
      />
      <FontAwesomeIcon
        icon={'chevron-left'}
        color={colors.primary.main.background}
        style={{ position: 'absolute', top: 'calc(50% - 32px)', left: 16, padding: 8, cursor: 'pointer' }}
        onClick={() => handleUpdateImageIndex(currentImageIndex - 1)}
      />
      {image && <Image src={image} sx={{ width: { md: '500px', sm: '400px', xs: '400px' }, alignSelf: 'center' }} />}
      <Thumbnails images={images} onImageSelected={updateCurrentImageIndex} />
    </Box>
  ) : null
}

export { ItemImages }
