import * as React from 'react'
import { useFormik } from 'formik'

import { useModal } from '@contexts'
import { CustomButton, Modal, TextH2Bold, TextH4Bold, TextInput } from '@components'
import { Images } from '@images'
import { useTranslation } from '@hooks'
import { AuthApi } from '@api'

import { useForgotPasswordValidationSchema } from './validation'
import { ForgotPasswordEmailSentModal } from '../ForgotPasswordEmailSentModal'

interface ForgotPasswordInitProps {
  username?: string
}
const ForgotPasswordInitModal = (props: ForgotPasswordInitProps) => {
  const { showModal, dismissModal } = useModal()
  const { t } = useTranslation()
  const { forgotPasswordValidationSchema } = useForgotPasswordValidationSchema()
  const formik = useFormik<{ username: string }>({
    initialValues: {
      username: props.username ?? '',
    },
    validationSchema: forgotPasswordValidationSchema,
    onSubmit: async values => {
      formik.setSubmitting(true)
      try {
        await AuthApi.forgotPassword(values)
      } catch {}
      formik.setSubmitting(false)
      showModal({ modal: <ForgotPasswordEmailSentModal /> })
    },
  })

  return (
    <Modal.Content>
      <Modal.Header image={Images.WarningImage}>
        <TextH2Bold>{t('forgot-password.modals.initial.title')}</TextH2Bold>
        <TextH4Bold>{t('forgot-password.modals.initial.subtitle')}</TextH4Bold>
      </Modal.Header>
      <Modal.Form>
        <TextInput
          name={'username'}
          label={t('forgot-password.modals.initial.inputs.email')}
          fullWidth
          value={formik.values.username}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
        />
      </Modal.Form>
      <Modal.Actions>
        <CustomButton
          variant={'secondary'}
          label={t('forgot-password.modals.initial.actions.back')}
          fullWidth={true}
          onClick={() => dismissModal()}
        />
        <CustomButton
          variant={'primary'}
          label={t('forgot-password.modals.initial.actions.continue')}
          fullWidth={true}
          disabled={!formik.isValid || formik.isSubmitting}
          onClick={() => formik.submitForm()}
        />
      </Modal.Actions>
    </Modal.Content>
  )
}

export { ForgotPasswordInitModal }
