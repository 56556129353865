import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'

import { useTranslation } from '@hooks'
import { CustomButton, TextTitleBold } from '@components'
import { Images } from '@images'

import { WidgetContent } from '../WidgetContent'

interface Props {
  actionPath: string
}
const AnswerPollToWinPrizeWidget = (props: Props) => {
  const { actionPath } = props

  const navigate = useNavigate()
  const { t } = useTranslation()
  const onContinue = () => {
    navigate(actionPath)
  }

  return (
    <WidgetContent sx={{ flexDirection: 'row', justifyContent: 'flex-start', gap: 2.5 }}>
      <img src={Images.SuccessImage} style={{ borderRadius: 12, width: '100%' }} alt={'t-shirts'} />
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={1.5}
        justifyContent={'center'}
        alignItems={'flex-start'}
        width={'100%'}
      >
        <TextTitleBold>{t('landing.modals.answer-poll-to-win.title')}</TextTitleBold>
        <Box alignSelf={'center'}>
          <CustomButton
            variant={'primary'}
            label={t('landing.modals.answer-poll-to-win.continue')}
            onClick={() => onContinue()}
          />
        </Box>
      </Box>
    </WidgetContent>
  )
}

export { AnswerPollToWinPrizeWidget }
