import { useQuery } from 'react-query'

import { QUERY_KEYS } from '@api'

import { PollsApi } from '../../api/polls'

const usePoll = (id: number | string) => {
  const {
    data: poll,
    isLoading,
    isError,
  } = useQuery([QUERY_KEYS.POLL, String(id)], () => PollsApi.getById(id), {
    refetchOnWindowFocus: false,
  })

  return { poll, isLoading, isError }
}

export { usePoll }
