import * as React from 'react'
import { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconButton from '@mui/material/IconButton'

import { Radio, TextBodyRegular } from '@components'
import { colors } from '@styles'
import { Images } from '@images'
import { useTranslation } from '@hooks'
import { TeamType } from '@types'

import {
  ChallengeRequiredItemCardBodyBlock,
  ChallengeRequiredItemCardBottom,
  ChallengeRequiredItemCardContentBlock,
  ChallengeRequiredItemCardImage,
} from './styled'
import { ChallengeRequirementItem } from '../../types'

const ChallengeRequiredItemCard = (props: {
  requiredItem: ChallengeRequirementItem
  isSelected: boolean
  onSelected: () => void
}) => {
  const { requiredItem, onSelected, isSelected } = props
  const { t } = useTranslation()
  const description = useMemo(() => {
    const {
      metadata: { dorsal, brand, equipmentType, name, season, year, type, team, signed },
    } = requiredItem
    let requirementText = t('required-item.any-shirt')
    const attributes: string[] = []
    if (dorsal) attributes.push(t('required-item.with-dorsal', { dorsal }))
    if (brand) attributes.push(t('required-item.from-brand', { brand }))
    if (equipmentType)
      attributes.push(
        t('required-item.from-equipment', { equipment: t(`equipment.${equipmentType.toString().toLowerCase()}`) }),
      )
    if (name) attributes.push(t('required-item.of-the-player', { name }))
    if (season) attributes.push(t('required-item.from-season', { season }))
    if (year) attributes.push(t('required-item.from-year', { year }))
    if (team) {
      if (team.type == TeamType.NATIONAL_TEAM) {
        attributes.push(t('required-item.from-national-team', { team: team.name }))
      } else if (team.type == TeamType.TEAM) {
        attributes.push(t('required-item.from-club', { team: team.name }))
      }
    }
    if (type)
      attributes.push(t('required-item.from-any-team', { type: t(`team-type.${type.toString().toLowerCase()}`) }))
    if (signed) attributes.push(t('required-item.signed'))

    requirementText += ' ' + attributes.join(', ')

    return requirementText
  }, [requiredItem.metadata, t])

  return (
    <ChallengeRequiredItemCardContentBlock className={isSelected || !!requiredItem.collectible ? 'checked' : ''}>
      <ChallengeRequiredItemCardBodyBlock id={'body'}>
        <ChallengeRequiredItemCardImage
          src={requiredItem.collectible?.metadata?.mediaFiles?.frontImage ?? Images.TShirtPlaceholder}
          id={'image'}
          sx={{ backgroundColor: requiredItem.collectible ? '#FFFFFF' : colors.grayscale['70'].background }}
        />
        <TextBodyRegular>{description}</TextBodyRegular>
      </ChallengeRequiredItemCardBodyBlock>
      <ChallengeRequiredItemCardBottom id={'actions'}>
        {requiredItem.collectible ? (
          <IconButton onClick={() => onSelected()}>
            <FontAwesomeIcon icon={['fas', 'check-circle']} color={colors.primary.main.background} size={'1x'} />
          </IconButton>
        ) : (
          <Radio checked={isSelected} onChange={() => onSelected()} />
        )}
      </ChallengeRequiredItemCardBottom>
    </ChallengeRequiredItemCardContentBlock>
  )
}

export { ChallengeRequiredItemCard }
