import Box from '@mui/material/Box'
import * as React from 'react'
import dayjs from 'dayjs'
import { styled } from '@mui/material/styles'

import { Row, TextBodyRegular, TextCaptionBold, TextH1Bold, TextH4Bold } from '@components'
import { Team } from '@types'
import { colors, MediaQuery, MqTablet } from '@styles'
import { useTranslation } from '@hooks'

import { Match, PredictionStatus } from '../../types'

interface Props {
  match: Match
  status: PredictionStatus
}

export const TeamImage = styled('img')(() => ({
  width: 60,
  height: 60,
  [MediaQuery[MqTablet]]: {
    width: 100,
    height: 100,
  },
}))
const TeamBox = (props: { team: Team }) => {
  const { team } = props

  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <TeamImage src={team.logo} alt={team.name} />
      <TextCaptionBold sx={theme => ({ textAlign: 'center', mt: theme.spacing(1) })}>{team.name}</TextCaptionBold>
    </Box>
  )
}

const FinalScore = (props: { match: Match }) => {
  const { match } = props
  const { t } = useTranslation()

  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
      <TextH4Bold>{t('predictions.view.final-score.title')}</TextH4Bold>
      <TextH1Bold>
        {t('predictions.view.final-score.result', {
          localTeamGoals: match.statistics?.localStats.goals ?? 0,
          visitorTeamGoals: match.statistics?.visitorStats.goals ?? 0,
        })}
      </TextH1Bold>
    </Box>
  )
}

const MatchDetails = (props: { match: Match }) => {
  const { match } = props
  const { t } = useTranslation()
  const userTime = dayjs(match.date).tz(dayjs.tz.guess())

  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
      <TextBodyRegular>{match.competition}</TextBodyRegular>
      <TextBodyRegular>{t('predictions.view.round-format', { round: match.round })}</TextBodyRegular>
      <TextBodyRegular>{match.stadium.name}</TextBodyRegular>
      <TextBodyRegular>{userTime.format(t('transactions-history.view.date')).toUpperCase()}</TextBodyRegular>
    </Box>
  )
}
const MatchHeader = (props: Props) => {
  const { match, status } = props

  return (
    <Row
      justifyContent={'space-between'}
      sx={theme => ({
        backgroundColor: colors.grayscale['80'].background,
        borderTopColor: colors.grayscale['30'].background,
        borderBottomColor: colors.grayscale['30'].background,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderTopStyle: 'solid',
        borderBottomStyle: 'solid',
        padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
      })}
    >
      <TeamBox team={match.localTeam} />
      <Box display={'flex'} flexGrow={1} justifyContent={'center'}>
        {status === PredictionStatus.FINISHED ? <FinalScore match={match} /> : <MatchDetails match={match} />}
      </Box>
      <TeamBox team={match.visitorTeam} />
    </Row>
  )
}

export { MatchHeader }
