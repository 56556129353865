import { Box, styled } from '@mui/material'

import { colors } from '@styles'

const RoundedCornersBox = styled(Box)(({ theme }) => ({
  background: colors.grayscale['100'].background,
  border: `2px solid ${colors.primary.main.background}`,
  borderRadius: theme.spacing(2),
  boxShadow: `0px 1px 3px 0px rgba(27, 27, 27, 0.30);`,
}))

export { RoundedCornersBox }
