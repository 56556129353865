/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useContext, useCallback } from 'react'

import { User } from '@types'
import { LOCAL_STORAGE_KEYS } from '@constants'

type ContextState = {
  userState?: User
  setUserState: (state: User | undefined) => void
}

const UserContext = React.createContext<ContextState | null>(null)

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [userState, setUser] = useState<User>(() => {
    const localUser = window.localStorage.getItem(LOCAL_STORAGE_KEYS.USER)
    if (localUser) return JSON.parse(localUser)

    return null
  })
  const setUserState = useCallback(updatedUser => {
    setUser(updatedUser)
    if (updatedUser) {
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(updatedUser))
    } else {
      window.localStorage.removeItem(LOCAL_STORAGE_KEYS.USER)
    }
  }, [])

  return <UserContext.Provider value={{ userState, setUserState }}>{children}</UserContext.Provider>
}

export function useUser(): ContextState {
  const context = useContext(UserContext)
  if (!context) throw Error('This hook can only be used under the UserProvider component')

  return context
}
