import { PropsWithChildren } from 'react'
import Box from '@mui/material/Box'
import * as React from 'react'
import { SxProps } from '@mui/material'

const Content = (props: PropsWithChildren & { sx?: SxProps }) => {
  const { sx } = props

  return (
    <Box
      className={'ShiModal-content'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      flexGrow={1}
      alignSelf={'stretch'}
      gap={3}
      paddingX={3}
      paddingBottom={3}
      sx={sx ?? { overflowY: 'auto' }}
    >
      {props.children}
    </Box>
  )
}

export { Content }
