import { useQuery } from 'react-query'

import { GroupedCollectiblesApi, QUERY_KEYS } from '@api'

const useGroupedCollectible = (id: number | string) => {
  const {
    data: groupedCollectible,
    isLoading,
    refetch,
  } = useQuery([QUERY_KEYS.GROUPED_COLLECTIBLE, String(id)], () => GroupedCollectiblesApi.getById(id), {
    refetchOnWindowFocus: false,
  })

  return { groupedCollectible, isLoading, refetch }
}

export { useGroupedCollectible }
