import { useMemo } from 'react'
import { Chip } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { PredictionStatus } from '@features/Predictions/types'
import { useTranslation } from '@hooks'
import { colors } from '@styles'

interface Props {
  status: PredictionStatus
}
const PredictionStatusChip = (props: Props) => {
  const { status } = props
  const { t } = useTranslation()
  const { label, color } = useMemo(() => {
    switch (status) {
      case PredictionStatus.INACTIVE:
        return { label: t('predictions.status.inactive'), color: '#FF0049' }
      case PredictionStatus.ACTIVE:
        return { label: t('predictions.status.active'), color: '#5974FF' }
      case PredictionStatus.COMPLETED:
        return { label: t('predictions.status.completed'), color: '#00A32A' }
      case PredictionStatus.CLOSED:
        return { label: t('predictions.status.closed'), color: '#D2FF00' }
      case PredictionStatus.FINISHED:
        return { label: t('predictions.status.finished'), color: '#A3A3A3' }
    }
  }, [status, t])

  return (
    <Chip
      sx={{
        borderWidth: 1.5,
        borderColor: color,
        color: colors.grayscale[100].background,
        backgroundColor: colors.grayscale[10].background,
        fontFamily: 'GT-Flexa',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
      }}
      label={label}
      variant='outlined'
      icon={<FontAwesomeIcon icon={['fas', 'circle']} size={'xs'} color={color} />}
    />
  )
}

export { PredictionStatusChip }
