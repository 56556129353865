import Box from '@mui/material/Box'
import * as React from 'react'

import { AppBase } from '@features/AppBase'
import { useTranslation } from '@hooks'
import { Images } from '@images'
import { useUser } from '@contexts'
import { SubscriptionType } from '@types'
import { Column, Content, Title } from '@components'
import { BuyActivationCodeButton } from '@features/Coins/components/BuyActivationCodeButton'

import { CoinsBalance, TransactionsHistory } from '../../components'

const CoinsMain = () => {
  const { t } = useTranslation()
  const { userState } = useUser()

  return (
    <AppBase requireActiveSession={true} backgroundImage={Images.WalletBackground}>
      <Box width={'100%'} px={{ md: 16.5, sm: 8, xs: 2 }} py={4}>
        <Title text={t('coins.title')} />
        <Content
          flexDirection={{ md: 'row', xs: 'column' }}
          sx={{ alignItems: 'flex-start', width: '100%', justifyContent: 'space-between', flexWrap: 'nowrap' }}
        >
          <Column width={{ md: '50%', xs: '100%' }}>
            <CoinsBalance />
            {userState!.subscription.type !== SubscriptionType.PREMIUM && <BuyActivationCodeButton onSuccess={() => {}} />}
          </Column>
          <TransactionsHistory />
        </Content>
      </Box>
    </AppBase>
  )
}

export { CoinsMain }
