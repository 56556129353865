import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { removeAuthHeader, setAuthHeader } from '@api'
import { LOCAL_STORAGE_KEYS } from '@constants'
import { AuthResponse, ErrorEnum } from '@types'

const setInterceptors = (api: AxiosInstance): AxiosInstance => {
  api.interceptors.response.use(
    (response: AxiosResponse) => {
      return response
    },
    async (error: AxiosError<any>) => {
      if (error.response?.status === 401 || error.response?.data.message === '401 Unauthorized') {
        if (error.request.responseURL.indexOf('refresh') === -1 && error.request.responseURL.indexOf('login') === -1) {
          try {
            const refreshToken = window.localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN)!
            setAuthHeader(refreshToken)
            const { data } = await api.post<AuthResponse>('/client-auth/login/refresh')
            setAuthHeader(data.token)
            window.localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, data.token)
            window.localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, data.refreshToken)
            const lastRequest = error.config || { headers: { Authorization: '' } }
            lastRequest.headers.Authorization = `Bearer ${data.token}`

            return api.request(lastRequest)
          } catch (refreshError) {
            removeAuthHeader()
            window.localStorage.clear()

            //when client-auth status check fails with redirect=0 we ignore the redirect to /login
            if (error.request.responseURL.indexOf('client-auth/status?redirect=0') === -1) {
              window.location.href = '/login'
            }
          }
        }
      }

      return Promise.reject(
        error.response?.data ? error.response.data : { type: ErrorEnum.UNKNOWN, message: error.message },
      )
    },
  )

  return api
}

export { setInterceptors }
