import Box from '@mui/material/Box'
import * as React from 'react'

import { Metadata } from '@types'
import { useTranslation } from '@hooks'

import { TextBodyBold, TextBodyRegular } from '../Text'

const MetadataAttribute = (props: { name: string; description: string }) => {
  return (
    <Box display={'flex'} flexDirection={'row'}>
      <TextBodyBold>{props.name}:</TextBodyBold>
      <TextBodyRegular>&nbsp;{props.description}</TextBodyRegular>
    </Box>
  )
}

interface ItemMetadataProps {
  metadata: Metadata
}
const ItemMetadata = (props: ItemMetadataProps) => {
  const { metadata } = props
  const { t } = useTranslation()

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <MetadataAttribute name={t('my-shirts.view.name')} description={metadata.name ? metadata.name : ' - '} />
      <MetadataAttribute
        name={t('my-shirts.view.dorsal')}
        description={`${metadata.dorsal !== 0 ? metadata.dorsal : ' -'}`}
      />
      <MetadataAttribute name={t('my-shirts.view.brand')} description={metadata.brand.name} />
      <MetadataAttribute name={t('my-shirts.view.season')} description={metadata.season} />
      <MetadataAttribute name={t('my-shirts.view.year')} description={`${metadata.year}`} />
      <MetadataAttribute name={t('my-shirts.view.team')} description={metadata.team.name} />
      <MetadataAttribute name={t('my-shirts.view.equipment')} description={metadata.equipmentType} />

      <MetadataAttribute
        name={t('my-shirts.view.signed')}
        description={metadata.signed ? t('common.yes') : t('common.no')}
      />
      {metadata.signed && <MetadataAttribute name={t('my-shirts.view.signer')} description={metadata.signer ?? ''} />}
      {!!metadata.history && <MetadataAttribute name={t('my-shirts.view.history')} description={metadata.history} />}
      {metadata?.item?.owner?.profile && (
        <MetadataAttribute
          name={t('my-shirts.view.digitized-by')}
          description={metadata?.item?.owner?.profile.fullName}
        />
      )}
      {metadata?.owner?.profile && (
        <MetadataAttribute name={t('my-shirts.view.owner')} description={metadata?.owner?.profile.fullName} />
      )}
    </Box>
  )
}

export { ItemMetadata }
