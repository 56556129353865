import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SxProps, Theme } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { IconButtonLinkStyled, IconButtonPrimaryStyled, IconButtonSecondaryStyled } from '../styled'

interface CustomIconButtonProps {
  variant: 'primary' | 'secondary' | 'link'
  color?: string
  icon: IconProp
  onClick?: () => void
  disabled?: boolean
  sx?: SxProps<Theme>
}

const CustomIconButton = (props: CustomIconButtonProps) => {
  const icon = <FontAwesomeIcon icon={props.icon} />
  let mSx = props.sx ?? {}
  mSx = props.color ? { ...mSx, color: props.color } : mSx
  switch (props.variant) {
    case 'primary':
      return (
        <IconButtonPrimaryStyled
          className={'ShiIconCustomButton-primary'}
          onClick={props.onClick}
          disabled={props.disabled}
          sx={mSx}
        >
          {icon}
        </IconButtonPrimaryStyled>
      )
    case 'secondary':
      return (
        <IconButtonSecondaryStyled
          className={'ShiIconCustomButton-secondary'}
          onClick={props.onClick}
          disabled={props.disabled}
          sx={mSx}
        >
          {icon}
        </IconButtonSecondaryStyled>
      )
    case 'link':
      return (
        <IconButtonLinkStyled
          className={'ShiIconCustomButton-link'}
          onClick={props.onClick}
          disabled={props.disabled}
          sx={mSx}
        >
          {icon}
        </IconButtonLinkStyled>
      )
  }
}
export { CustomIconButton }
