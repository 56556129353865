import { api } from '@api'
import { Item, ItemFilters, PaginatedRequest, PaginatedResponse } from '@types'

const getById = async (itemId: string | number) => {
  const response = await api.get<Item>(`/items/${itemId}`)

  return response.data
}

const getMyItems = async (page: string | number) => {
  const params = new URLSearchParams()
  params.set('page', `${page}`)
  params.set('pageSize', '50')
  const response = await api.get<PaginatedResponse<Item>>(`/items/owned?${params.toString()}`)

  return response.data
}

const getPublicItems = async (request: PaginatedRequest<ItemFilters>) => {
  const { page, pageSize, filters } = request
  const { brand, team, t: _, ...others } = filters
  const collectibleFilters = { ...others }
  if (brand && Array.isArray(brand) && brand.length > 0) {
    collectibleFilters['brandId'] = brand.map(b => b.id)
  }
  if (team && Array.isArray(team) && team.length > 0) {
    collectibleFilters['teamId'] = team.map(t => t.id)
  }
  const response = await api.get<PaginatedResponse<Item>>(`/public/items`, {
    params: { page, pageSize, ...collectibleFilters },
  })

  return response.data
}

const getPublicById = async (itemId: string | number) => {
  const response = await api.get<Item>(`/public/items/${itemId}`)

  return response.data
}

export const ItemsApi = { getById, getMyItems, getPublicItems, getPublicById }
