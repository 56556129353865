import Box from '@mui/material/Box'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  CustomButton,
  CustomExpandableButton,
  ItemCard,
  Title,
  Content,
  Loading,
  Row,
  CustomPagination,
} from '@components'
import { ItemFiltersDrawer } from '@features/Filters'
import { useTranslation, usePublicItems } from '@hooks'
import { ItemFilters } from '@types'
import { ROUTES } from '@constants'
import { AppBase } from '@features/AppBase'
import { Images } from '@images'

const MuseumMain = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [open, updateOpen] = useState(false)
  const [filters, updateFilters] = useState<ItemFilters>({})
  const { data, changeFilters, changePage, isLoading } = usePublicItems()

  useEffect(() => {
    changeFilters(filters)
    updateOpen(false)
  }, [filters])

  return (
    <AppBase requireActiveSession={false} backgroundImage={Images.MuseumBackground}>
      <Box width={'100%'} px={{ md: 16.5, sm: 8, xs: 2 }} py={4}>
        <Title text={t('museum.title')}>
          <Box display={'flex'} flexGrow={1} justifyContent={'flex-end'} alignSelf={'center'} pb={0.5}>
            <CustomButton
              variant={'secondary'}
              label={t('item-filters.filters')}
              onClick={() => updateOpen(true)}
              startIcon={['fas', 'filter']}
            />
          </Box>
        </Title>
        <Content flexDirection={{ xl: 'row', lg: 'row', md: 'row', sm: 'row', xs: 'column' }}>
          {isLoading && <Loading />}
          {data?.data.map(item => (
            <ItemCard
              key={`item-${item.id}`}
              item={item}
              onClick={eventItem => {
                navigate(`/museum/${eventItem.id}`)
              }}
            />
          ))}
          <ItemFiltersDrawer
            open={open}
            onClose={() => updateOpen(false)}
            defaultValues={filters}
            onChange={updateFilters}
          />
          <Row>
            {data && data.data.length > 0 && data.lastPage > 0 && (
              <CustomPagination count={data.lastPage} page={data.page} onChange={page => changePage(page)} />
            )}
          </Row>
        </Content>
        <CustomExpandableButton
          variant={'primary'}
          icon={['fas', 'plus']}
          label={t('museum.actions.digitize')}
          onClick={() => navigate(ROUTES.DIGITIZE)}
          sx={theme => ({
            position: 'absolute',
            top: '90vh',
            right: { md: theme.spacing(16.5), sm: theme.spacing(8), xs: theme.spacing(4) },
          })}
        />
      </Box>
    </AppBase>
  )
}

export { MuseumMain }
