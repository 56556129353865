import * as yup from 'yup'
import { PhoneNumberUtil } from 'google-libphonenumber'

import { useTranslation } from '@hooks'

const phoneUtil = PhoneNumberUtil.getInstance()

const validateFullPhone = (countryCode, phone) => {
  if (countryCode && phone) {
    const region = phoneUtil.getRegionCodeForCountryCode(countryCode)
    try {
      return region != 'ZZ' && phoneUtil.isValidNumberForRegion(phoneUtil.parse(phone, region), region)
    } catch {
      return false
    }
  }

  return false
}
const validatePhone = (_, context) => {
  const { countryCode, phone } = context.parent
  if (!countryCode) return true

  return validateFullPhone(countryCode, phone)
}

const validateCountryCode = (_, context) => {
  const { countryCode, phone } = context.parent
  if (!phone) return true

  return !!countryCode
}

export const useExtraInfoValidationSchema = () => {
  const { t } = useTranslation()
  const extraInfoValidationSchema = yup.object({
    countryCode: yup
      .string()
      .test('is-valid-country-code', t('create-account.extra-info.validation.required'), (value, context) =>
        validateCountryCode(value, context),
      ),
    phone: yup.string().when('countryCode', {
      is: countryCode => !!countryCode,
      then: schema =>
        schema
          .test('is-valid-phone', t('create-account.extra-info.validation.phone'), (value, context) =>
            validatePhone(value, context),
          )
          .required(),
    }),
    picture: yup.string(),
  })

  return { extraInfoValidationSchema }
}
