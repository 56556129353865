export const QUERY_KEYS = {
  SESSION_USER: 'sessionUser',
  MY_PROFILE: 'my-profile',
  TEAMS: 'teams',
  PLAYERS: 'players',
  COUNTRIES: 'countries',
  BRANDS: 'brands',
  ITEM: 'item',
  POLL: 'poll',
  MY_ITEMS: 'my-items',
  PUBLIC_ITEMS: 'public-items',
  MY_COLLECTIBLES: 'my-collectibles',
  MY_COLLECTIBLES_FILTERED: 'my-collectibles-filtered',
  GROUPED_COLLECTIBLE: 'grouped-collectible',
  COLLECTIBLE_ON_SALE: 'collectible-on-sale',
  COLLECTIBLES_ON_SALE: 'collectibles-on-sale',
  PUBLIC_COLLECTIBLES: 'public-collectibles',
  TRIVIAS: 'trivias',
  TRIVIA: 'trivia',
  TRIVIA_RANKING: 'trivia-ranking',
  PREVIOUS_TRIVIAS: 'previous-trivias',
  PREDICTIONS: 'predictions',
  PREDICTION: 'prediction',
  PREDICTION_RANKING: 'prediction-ranking',
  CHALLENGES: 'challenges',
  CHALLENGE: 'challenge',
  MY_TRANSACTIONS: 'my-transactions',
  RANKING: 'ranking',
  COINS_BUDGET: 'coins-budget',
}
