import { api } from '@api'

import { Poll } from '../types'

const getById = async (id: string | number) => {
  const response = await api.get<Poll>(`/polls/${id}`)

  return response.data
}

const saveOption = async (id: string | number, optionId: number) => {
  const response = await api.post<Poll>(`/polls/${id}`, { optionId })

  return response.data
}

export const PollsApi = { getById, saveOption }
