import * as React from 'react'

import { useModal } from '@contexts'
import { CustomButton, Modal, TextH2Bold, TextH4Bold } from '@components'
import { Images } from '@images'
import { useTranslation } from '@hooks'

interface Props {
  onConfirm: () => void
}
const PredictionConfirmModal = (props: Props) => {
  const { onConfirm } = props
  const { t } = useTranslation()
  const { dismissModal } = useModal()

  return (
    <Modal.Content>
      <Modal.Header image={Images.WarningImage}>
        <TextH2Bold>{t('predictions.modals.confirm.title')}</TextH2Bold>
        <TextH4Bold>{t('predictions.modals.confirm.subtitle')}</TextH4Bold>
      </Modal.Header>
      <Modal.Actions>
        <CustomButton
          variant={'secondary'}
          label={t('predictions.modals.confirm.back')}
          fullWidth={true}
          onClick={() => dismissModal()}
        />
        <CustomButton
          variant={'primary'}
          label={t('predictions.modals.confirm.submit')}
          fullWidth={true}
          onClick={() => {
            dismissModal()
            onConfirm()
          }}
        />
      </Modal.Actions>
    </Modal.Content>
  )
}

export { PredictionConfirmModal }
