import { useState } from 'react'
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'

import { TextInputProp } from '../types'
import { InputStyle, LabelStyle } from '../styles'

const PasswordInput = (props: TextInputProp) => {
  const [showPassword, updateShowPassword] = useState(false)
  const { helperText, ...otherProps } = props

  return (
    <FormControl className={'ShiPasswordInput-form-control'} variant='outlined' fullWidth={otherProps.fullWidth}>
      <InputLabel className={'ShiPasswordInput-label'} sx={LabelStyle}>
        {otherProps.label}
      </InputLabel>
      <OutlinedInput
        className={'ShiPasswordInput-input'}
        type={showPassword ? 'text' : 'password'}
        sx={InputStyle}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={() => updateShowPassword(!showPassword)}
              edge='end'
              size={'small'}
            >
              <FontAwesomeIcon icon={['fas', showPassword ? 'eye-slash' : 'eye']} />
            </IconButton>
          </InputAdornment>
        }
        {...otherProps}
      />
      {helperText && <FormHelperText error={otherProps.error}>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export { PasswordInput }
