import { Player } from '../../types'

const usePlayerAccumulator = () => {
  return (players: Player[]) => {
    return players.reduce<{ player: Player; count: number }[]>((accumulator, currentValue) => {
      let playerAccumulator = accumulator.find(a => a.player.id === currentValue.id)
      if (!playerAccumulator) {
        playerAccumulator = {
          player: currentValue,
          count: 0,
        }
        accumulator.push(playerAccumulator)
      }
      playerAccumulator.count++

      return accumulator
    }, [])
  }
}
export { usePlayerAccumulator }
