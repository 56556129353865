import { Pagination } from '@mui/material'

import { colors } from '@styles'

interface PaginationProps {
  page?: number
  onChange?: (page: number) => void
  count: number
}
const CustomPagination = (props: PaginationProps) => {
  return (
    <Pagination
      count={props.count + 1}
      onChange={(_, p) => {
        if (props.onChange) props.onChange(p - 1)
      }}
      page={(props.page ?? 0) + 1}
      defaultPage={1}
      variant='outlined'
      shape='rounded'
      sx={theme => ({
        alignSelf: 'center',
        '.MuiPaginationItem-root': {
          borderRadius: theme.spacing(1.5),
          minWidth: theme.spacing(5),
          color: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
          '&.Mui-selected': {
            color: theme.palette.primary.light,
            borderColor: theme.palette.primary.light,
            backgroundColor: colors.grayscale['60'].background,
          },
          '&.MuiPaginationItem-previousNext': {
            borderRadius: theme.spacing(1.5),
            minWidth: theme.spacing(5),
            color: colors.grayscale['80'].background,
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main,
          },
        },
      })}
    />
  )
}

export { CustomPagination }
