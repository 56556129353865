import { styled } from '@mui/material/styles'
import MButton from '@mui/material/Button'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SxProps, Theme } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { colors } from '@styles'

interface CustomExpandableButtonProps {
  variant: 'primary' | 'secondary' | 'text' | 'link'
  label: string
  color?: string
  icon: IconProp
  onClick?: () => void
  fullWidth?: boolean
  disabled?: boolean
  sx?: SxProps<Theme>
  key?: string
}

const ExpandableButtonPrimaryStyled = styled(MButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  borderRadius: theme.spacing(theme.shape.borderRadius),
  minWidth: theme.spacing(4),
  ':hover': {
    backgroundColor: theme.palette.primary.light,
  },

  textTransform: 'none',
  height: '40px',
  '&.Mui-disabled': {
    backgroundColor: colors.grayscale['30'].background,
    color: theme.palette.primary.contrastText,
  },
  '.MuiButton-startIcon': { margin: 0 },
  ':hover .MuiButton-startIcon': {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(-0.5),
  },
  '.expandable-button-label': {
    display: 'block',
    width: '0px',
    overflow: 'hidden',
    textWrap: 'nowrap',
    transition: 'width .25s linear',
  },
  ':hover .expandable-button-label': {
    width: '100px',
    transition: 'width 0.25s linear',
  },
}))

const CustomExpandableButton = (props: CustomExpandableButtonProps) => {
  return (
    <ExpandableButtonPrimaryStyled
      className={'ShiExpandableCustomButton-primary'}
      fullWidth={props.fullWidth}
      variant={'contained'}
      onClick={props.onClick}
      startIcon={<FontAwesomeIcon icon={props.icon} />}
      disabled={props.disabled}
      sx={props.sx}
      key={props.key}
    >
      <span className={'ShiExpandableCustomButton-label expandable-button-label'}>{props.label}</span>
    </ExpandableButtonPrimaryStyled>
  )
}

export { CustomExpandableButton }
