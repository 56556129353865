import * as yup from 'yup'

import { useTranslation } from '@hooks'

const usePlaceForSaleSchema = () => {
  const { t } = useTranslation()

  // @ts-ignore
  const placeForSaleSchema = yup.object().shape({
    price: yup
      .string()
      .required(t('collectible.modal.place-for-sale.form.validation.required'))
      .matches(/^[0-9]+$/, t('collectible.modal.place-for-sale.form.validation.price')),
  })

  return { placeForSaleSchema }
}

export { usePlaceForSaleSchema }
