import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { useModal } from '@contexts'
import { CustomButton, Modal, TextH2Bold } from '@components'
import { Images } from '@images'
import { useTranslation } from '@hooks'
import { ROUTES } from '@constants'

const DigitizeYourFirstTShirtModal = () => {
  const { t } = useTranslation()
  const { dismissModal } = useModal()
  const navigate = useNavigate()

  return (
    <Modal.Content>
      <Modal.Header image={Images.PoolThumbnail}>
        <TextH2Bold>{t('landing.modals.digitize-first-shirt.title')}</TextH2Bold>
      </Modal.Header>
      <Modal.Actions>
        <CustomButton
          variant={'secondary'}
          label={t('landing.modals.digitize-first-shirt.back')}
          fullWidth={true}
          onClick={() => dismissModal()}
        />
        <CustomButton
          variant={'primary'}
          label={t('landing.modals.digitize-first-shirt.continue')}
          fullWidth={true}
          onClick={() => {
            dismissModal()
            navigate(ROUTES.DIGITIZE)
          }}
        />
      </Modal.Actions>
    </Modal.Content>
  )
}

export { DigitizeYourFirstTShirtModal }
