import Box from '@mui/material/Box'
import * as React from 'react'
import { useMemo } from 'react'

import { useTranslation } from '@hooks'
import { CustomButton, Modal, TextH2Bold } from '@components'
import { Images } from '@images'
import { Poll, PollPrizeType } from '@features/Polls/types'
import { useModal } from '@contexts'

interface PollOptionSavedModalProps {
  poll: Poll
}
const PollOptionSavedModal = (props: PollOptionSavedModalProps) => {
  const { t } = useTranslation()
  const { dismissModal } = useModal()
  const { poll } = props
  const text = useMemo(() => {
    switch (poll.prizeType) {
      case PollPrizeType.ACTIVATION_CODE:
        return t('poll.modals.option-saved.activation-code')
      case PollPrizeType.POINTS:
        return t('poll.modals.option-saved.points', { points: poll.points })
      case PollPrizeType.NONE:
        return t('poll.modals.option-saved.saved')
    }
  }, [poll])

  return (
    <Modal.Content>
      <Modal.Header image={Images.SuccessImage}>
        <Box display={'flex'} flexDirection={'column'} gap={0} pt={1.5}>
          <TextH2Bold>{text}</TextH2Bold>
        </Box>
      </Modal.Header>
      <Modal.Actions>
        <CustomButton
          variant={'secondary'}
          label={t('poll.modals.option-saved.button')}
          fullWidth={true}
          onClick={() => {
            dismissModal()
          }}
        />
      </Modal.Actions>
    </Modal.Content>
  )
}

export { PollOptionSavedModal }
