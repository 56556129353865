import { Box } from '@mui/material'
import { PropsWithChildren } from 'react'

import { TextH1Bold } from '@components'

interface TitleProps {
  text: string
}
const Title = (props: PropsWithChildren<TitleProps>) => {
  return (
    <Box
      className={'ShiTitle-container'}
      sx={theme => ({
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
      })}
    >
      <TextH1Bold className={'ShiTitle-title'}>{props.text}</TextH1Bold>
      {props.children}
    </Box>
  )
}

export { Title }
