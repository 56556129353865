import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'

import { Images } from '@images'
import { colors } from '@styles'
import { CustomButton, TextBodyRegular, TextTitleBold } from '@components'
import { useTranslation } from '@hooks'
import { ROUTES } from '@constants'

import { WidgetContent } from '../WidgetContent'

const DigitizeWidget = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <WidgetContent
      sx={{
        flexDirection: { md: 'row', sm: 'column', xs: 'column' },
        justifyContent: 'flex-start',
        gap: 2.5,
        flexWrap: 'nowrap',
      }}
    >
      <img
        src={Images.LandingTShirts}
        style={{
          border: `1px solid ${colors.grayscale[30].background}`,
          borderRadius: 12,
          objectFit: 'cover',
          maxHeight: '200px',
        }}
        alt={'t-shirts'}
      />
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={1.5}
        justifyContent={'center'}
        alignItems={'flex-start'}
        flexShrink={1}
      >
        <TextTitleBold>{t('landing.digitize-widget.title')}</TextTitleBold>
        <TextBodyRegular>{t('landing.digitize-widget.content')}</TextBodyRegular>
        <CustomButton
          variant={'primary'}
          label={t('landing.digitize-widget.action')}
          onClick={() => navigate(ROUTES.DIGITIZE)}
        />
      </Box>
    </WidgetContent>
  )
}

export { DigitizeWidget }
