import Box from '@mui/material/Box'
import * as React from 'react'

import { useModal } from '@contexts'
import { CustomButton, Modal, TextH2Bold } from '@components'
import { Images } from '@images'
import { useTranslation } from '@hooks'

interface Props {
  onConfirm: () => void
}
const ConfirmModal = (props: Props) => {
  const { onConfirm } = props
  const { dismissModal } = useModal()
  const { t } = useTranslation()

  return (
    <Modal.Content>
      <Modal.Header image={Images.WarningImage}>
        <Box display={'flex'} flexDirection={'column'} gap={0} pt={1.5}>
          <TextH2Bold>{t('digitize.modals.confirm.title')}</TextH2Bold>
        </Box>
      </Modal.Header>
      <Modal.Actions>
        <CustomButton
          variant={'secondary'}
          label={t('digitize.modals.confirm.back')}
          fullWidth={true}
          onClick={() => dismissModal()}
        />
        <CustomButton
          variant={'primary'}
          label={t('digitize.modals.confirm.submit')}
          fullWidth={true}
          onClick={() => {
            dismissModal()
            onConfirm()
          }}
        />
      </Modal.Actions>
    </Modal.Content>
  )
}

export { ConfirmModal }
