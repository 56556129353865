import { useCallback } from 'react'

import { useTranslation } from '@hooks'
import { Metadata } from '@types'

export const useMetadataToText = () => {
  const { t } = useTranslation()

  const getName = useCallback(
    (metadata?: Metadata) => {
      if (metadata) {
        const season = metadata.season.slice(-2)
        const team = metadata.team.name

        return t('item.name', { season, team })
      }

      return ''
    },
    [t],
  )

  return { getName }
}
