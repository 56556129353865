import * as React from 'react'
import { useCallback, useState } from 'react'

import { useModal } from '@contexts'
import { CustomButton, Modal, TextH2Bold, TextH4Bold } from '@components'
import { Images } from '@images'
import { useTranslation } from '@hooks'
import { AuthApi } from '@api'

import { ValidationEmailSentModal } from '../ValidationEmailSentModal'

interface ResendValidationEmailProps {
  username: string
}
const ResendValidationEmailModal = (props: ResendValidationEmailProps) => {
  const { showModal, dismissModal } = useModal()
  const { t } = useTranslation()
  const [isSubmitting, setSubmitting] = useState(false)

  const onSubmit = useCallback(async () => {
    if (!isSubmitting) {
      setSubmitting(true)
      try {
        await AuthApi.resendValidationEmail({ username: props.username })
        showModal({ modal: <ValidationEmailSentModal /> })
      } catch {}
      setSubmitting(false)
    }
  }, [props.username, isSubmitting])

  return (
    <Modal.Content>
      <Modal.Header image={Images.ForgotPasswordImage}>
        <TextH2Bold>{t('email-validation.modals.resend-email.title')}</TextH2Bold>
        <TextH4Bold>{t('email-validation.modals.resend-email.subtitle')}</TextH4Bold>
      </Modal.Header>
      <Modal.Actions>
        <CustomButton
          variant={'secondary'}
          label={t('email-validation.modals.resend-email.actions.back')}
          fullWidth={true}
          onClick={() => dismissModal()}
        />
        <CustomButton
          variant={'primary'}
          label={t('email-validation.modals.resend-email.actions.continue')}
          fullWidth={true}
          disabled={isSubmitting}
          onClick={() => onSubmit()}
        />
      </Modal.Actions>
    </Modal.Content>
  )
}

export { ResendValidationEmailModal }
