import * as yup from 'yup'

import { useTranslation } from '@hooks'

export const useChangePasswordValidationSchema = () => {
  const { t } = useTranslation()

  const changePasswordValidationSchema = yup.object({
    username: yup
      .string()
      .email(t('forgot-password.modals.change-password.validation.email'))
      .required(t('forgot-password.modals.change-password.validation.required')),
    password: yup
      .string()
      .min(8, t('forgot-password.modals.change-password.validation.password-length'))
      .required(t('forgot-password.modals.change-password.validation.required')),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], t('forgot-password.modals.change-password.validation.password-must-match'))
      .required(t('forgot-password.modals.change-password.validation.required')),
    token: yup.string().required(),
  })

  return { changePasswordValidationSchema }
}
