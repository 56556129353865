import Box from '@mui/material/Box'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { ItemCard, TextH2Bold, TextSubtitleRegular, Carousel, Loading } from '@components'
import { usePublicItems, useTranslation } from '@hooks'

const LastTShirts = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { data, isLoading } = usePublicItems()

  return data?.data && data?.data.length > 0 ? (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={1.5}
      justifyContent={'center'}
      alignItems={'center'}
      width={'100%'}
    >
      <TextH2Bold
        sx={theme => ({
          color: theme.palette.primary.main,
        })}
      >
        {t('landing.last-shirts-widget.title')}
      </TextH2Bold>
      <TextSubtitleRegular>{t('landing.last-shirts-widget.content')}</TextSubtitleRegular>
      {isLoading && <Loading />}
      {data && (
        <Carousel
          items={data.data}
          itemProvider={item => <ItemCard item={item} onClick={() => navigate(`/museum/${item.id}`)} />}
        />
      )}
    </Box>
  ) : null
}

export { LastTShirts }
