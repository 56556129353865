import Box from '@mui/material/Box'
import * as React from 'react'
import { useEffect } from 'react'

import { Content, Loading, Title } from '@components'
import { useTranslation } from '@hooks'
import { AppBase } from '@features/AppBase'
import { useUser } from '@contexts'
import { Images } from '@images'
import { useDigitizeYourFirstTShirt } from '@features/Landing/hooks'

import { useChallenges } from '../../hooks'
import { ChallengeCard } from '../../components'

const ChallengeMain = () => {  
  const { t } = useTranslation()
  const { data, isLoading, refetch } = useChallenges()
  const { userState } = useUser()

  useDigitizeYourFirstTShirt()

  useEffect(() => {
    refetch()
  }, [userState])

  // const { validQuota } = useCheckQuota(QuotaFeature.CHALLENGES, {
  //   redirect: () => navigate(ROUTES.CHALLENGES),
  //   noModal: true,
  //   forceRedirect: false,
  // })

  return (
    <AppBase requireActiveSession={false} backgroundImage={Images.ChallengesBackground}>
      <Box width={'100%'} px={{ md: 16.5, sm: 8, xs: 2 }} py={4}>
        <Title text={t('challenges.title')} />
        <Content flexDirection={{ xl: 'row', lg: 'row', md: 'column', sm: 'column', xs: 'column' }}>
          {isLoading && <Loading />}
          {data?.map(challenge => (
            <ChallengeCard challenge={challenge} joinEnabled={true} key={`challenge-${challenge.id}`} />
          ))}
        </Content>
      </Box>      
    </AppBase>
  )
}

export { ChallengeMain }
