import Box from '@mui/material/Box'

import { CustomButton, Modal, TextBodyBold, TextH2Bold, TextH4Regular } from '@components'
import { useTranslation } from '@hooks'
import { colors } from '@styles'
import { Images } from '@images'
import { useModal } from '@contexts'

interface Props {
  image: string
  label: string
}
const TShirtCard = (props: Props) => {
  return (
    <Box
      sx={theme => ({
        backgroundColor: colors.grayscale[70].background,
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2),
        justifyContent: 'cener',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      })}
    >
      <img src={props.image} style={{ height: 150, width: 150, backgroundColor: colors.grayscale[60].background }} />
      <TextBodyBold sx={{ textAlign: 'center' }}>{props.label}</TextBodyBold>
    </Box>
  )
}

const HelpModal = () => {
  const { dismissModal } = useModal()
  const { t } = useTranslation()

  return (
    <Modal.Content>
      <TextH2Bold>{t('digitize.modals.help.title')}</TextH2Bold>
      <TextH4Regular sx={{ textAlign: 'center' }}>{t('digitize.modals.help.subtitle')}</TextH4Regular>
      <Box display={'flex'} flexWrap={'wrap'} gap={4} justifyContent={'center'}>
        <TShirtCard label={t('digitize.modals.help.front')} image={Images.TShirtThumbnailFront} />
        <TShirtCard label={t('digitize.modals.help.back')} image={Images.TShirtThumbnailBack} />
        <TShirtCard label={t('digitize.modals.help.right-side')} image={Images.TShirtThumbnailRight} />
        <TShirtCard label={t('digitize.modals.help.left-side')} image={Images.TShirtThumbnailLeft} />
      </Box>
      <Modal.Actions>
        <CustomButton variant={'primary'} label={t('digitize.modals.help.submit')} fullWidth onClick={dismissModal} />
      </Modal.Actions>
    </Modal.Content>
  )
}

export { HelpModal }
