import Box from '@mui/material/Box'
import * as React from 'react'

import { CustomIconButton, RoundedCornersBox, TextCaptionBold, TextLegalRegular } from '@components'
import { colors } from '@styles'
import { FileSizeUtil } from '@utils'

import { FileProps } from '../../types'
import { Preview } from './styled'

interface SelectedImageProps {
  originalFile: FileProps
  onDelete: () => void
  errors: string[]
}
const SelectedImage = (props: SelectedImageProps) => {
  const showError = props.errors.length > 0

  return (
    <RoundedCornersBox
      mt={2}
      sx={{
        borderWidth: 1,
        padding: 1,
        width: '100%',
        borderColor: showError ? colors.status.error.background : colors.primary.main.background,
      }}
      display={'flex'}
      justifyContent={'space-between'}
      flexDirection={'row'}
    >
      <Preview src={props.originalFile.objectUrl} />
      <Box flexDirection={'column'} px={2} flexWrap={'wrap'}>
        <TextCaptionBold>{props.originalFile.name}</TextCaptionBold>
        <TextLegalRegular>{FileSizeUtil.getReadableFileSizeString(props.originalFile.size)}</TextLegalRegular>
        {showError &&
          props.errors.map((error, index) => (
            <TextLegalRegular key={`error-${index}`} sx={{ color: colors.status.error.background }}>
              {error}
            </TextLegalRegular>
          ))}
      </Box>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} flexGrow={1}>
        <CustomIconButton
          variant={'link'}
          icon={'trash'}
          sx={{ color: colors.primary.main.background }}
          onClick={props.onDelete}
        />
      </Box>
    </RoundedCornersBox>
  )
}

export { SelectedImage }
