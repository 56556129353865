import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { useFormik } from 'formik'

import { CustomButton, ImageFileInput, Row, SelectInput, TextInput } from '@components'
import { CountryCode } from '@types'
import { useCountryCodes, useTranslation } from '@hooks'

import { ExtraInfoForm, StepProps } from '../types'
import { useExtraInfoValidationSchema } from './validation'
import { useCreateAccount } from '../../../../../contexts/create-account-context'
import { useSignUp } from '../../../../../hooks/useSignUp'
import { FileProps } from '../../../../../../Digitize/types'

const ExtraInfo = (props: StepProps) => {
  const { createAccountState, setCreateAccountState } = useCreateAccount()
  const { extraInfoValidationSchema } = useExtraInfoValidationSchema()
  const formik = useFormik<ExtraInfoForm>({
    initialValues: {
      phone: '',
      picture: '',
      countryCode: '',
      ...(createAccountState?.extraInfo ?? {}),
    },
    validationSchema: extraInfoValidationSchema,
    onSubmit: values => {
      setCreateAccountState({ ...createAccountState, extraInfo: { ...values }, submit: true })
    },
  })
  const { signUpMutation, isLoading } = useSignUp()
  const { t } = useTranslation()

  const { countryCodes } = useCountryCodes()
  const onImageFileChange = (fileProps: FileProps) => {
    formik.setFieldValue('picture', fileProps.url ?? '')
  }
  const [selectedCountryCodes, updateSelectedCountryCodes] = useState<CountryCode[]>([])

  useEffect(() => {
    if (selectedCountryCodes.length > 0) {
      formik.setFieldValue('countryCode', selectedCountryCodes[0].code)
    } else {
      formik.setFieldValue('countryCode', '')
    }
  }, [selectedCountryCodes])

  const signUp = useCallback(
    async (saveForm = true) => {
      if (saveForm) {
        await formik.submitForm()
      } else {
        setCreateAccountState({ ...createAccountState, submit: true })
      }
    },
    [formik, createAccountState, setCreateAccountState],
  )

  useEffect(() => {
    if (createAccountState.submit) {
      signUpMutation()
      setCreateAccountState({ ...createAccountState, submit: false })
    }
  }, [createAccountState])

  return (
    <>
      <ImageFileInput
        onFileChange={onImageFileChange}
        label={t('create-account.extra-info.input.picture')}
        aspect={1}
      />
      <Box flexDirection={'row'} display={'flex'} gap={2} width={'100%'}>
        <SelectInput<CountryCode>
          label={t('create-account.extra-info.input.phone-country-code')}
          options={[new CountryCode('', '', ''), ...countryCodes]}
          value={selectedCountryCodes}
          onChange={updateSelectedCountryCodes}
          error={Boolean(formik.errors.countryCode)}
          helperText={formik.errors.countryCode}
        />
        <TextInput
          label={t('create-account.extra-info.input.phone')}
          name={'phone'}
          value={formik.values.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
        />
      </Box>
      <Row>
        <CustomButton
          variant={'secondary'}
          label={t('create-account.common.input.back')}
          fullWidth={true}
          onClick={() => {
            if (props.prev) {
              props.prev()
            }
          }}
        />
        <CustomButton
          variant={'primary'}
          label={t('create-account.common.input.submit')}
          fullWidth={true}
          disabled={!formik.isValid || isLoading}
          onClick={async () => {
            await signUp()
          }}
        />
      </Row>
    </>
  )
}

export { ExtraInfo }
