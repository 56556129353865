import { useNavigate, useParams } from 'react-router-dom'
import { useCallback, useMemo } from 'react'
import Box from '@mui/material/Box'
import * as React from 'react'

import {
  CustomButton,
  Title,
  ItemImages,
  ItemMetadata,
  Content,
  Header,
  Column,
  Loading,
  TextH3Bold,
} from '@components'
import { useCollectible, useMetadataToText, useTranslation } from '@hooks'
import { ROUTES } from '@constants'
import { useModal, useUser } from '@contexts'
import { AppBase } from '@features/AppBase'
import { Images } from '@images'

import { PurchaseConfirmModal } from '../../modals'
import { LoginModal } from '../../../Login'

const MarketplaceItemView = () => {
  const { showModal } = useModal()
  const { id = '0' } = useParams()
  const navigate = useNavigate()
  const { getName } = useMetadataToText()
  const { t } = useTranslation()
  const { collectible, isLoading } = useCollectible(id)
  const { userState } = useUser()
  const onButtonBuyClicked = useCallback(() => {
    if (!userState) {
      showModal({ modal: <LoginModal /> })
    } else {
      showModal({ modal: <PurchaseConfirmModal collectibleId={id} /> })
    }
  }, [userState])

  const images = useMemo(
    () =>
      collectible
        ? [
            collectible.metadata.mediaFiles.frontImage,
            collectible.metadata.mediaFiles.backImage,
            collectible.metadata.mediaFiles.leftArmImage,
            collectible.metadata.mediaFiles.rightArmImage,
          ]
        : [],
    [collectible],
  )

  return (
    <AppBase requireActiveSession={false} backgroundImage={Images.MarketplaceBackground}>
      <Box width={'100%'} px={{ lg: 16.5, md: 10, sm: 8, xs: 2 }} py={4}>
        <Header>
          <CustomButton
            variant={'secondary'}
            label={t('marketplace.view.back')}
            startIcon={['fas', 'chevron-left']}
            onClick={() => navigate(ROUTES.MARKETPLACE)}
          />
          <Title text={getName(collectible?.metadata)} />
        </Header>

        <Content
          flexDirection={{ md: 'row', xs: 'column' }}
          sx={{ justifyContent: 'flex-start' }}
          alignItems={{ md: 'flex-start', xs: 'center' }}
        >
          {isLoading && <Loading />}
          {collectible && (
            <>
              <ItemImages images={images} />
              <Column sx={{ alignItems: 'flex-start' }} maxWidth={'300px'} width={'100%'}>
                <ItemMetadata metadata={collectible.metadata} />
                <Box display={'flex'} flexDirection={'row'}>
                  <TextH3Bold sx={{ textTransform: 'uppercase' }}>
                    {t('marketplace.item.price', { coins: collectible.price })}
                  </TextH3Bold>
                </Box>
                <CustomButton
                  variant={'primary'}
                  label={t('marketplace.item.buy')}
                  onClick={() => onButtonBuyClicked()}
                  fullWidth={true}
                  sx={{ maxWidth: 290 }}
                />
              </Column>
            </>
          )}
        </Content>
      </Box>
    </AppBase>
  )
}

export { MarketplaceItemView }
