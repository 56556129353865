import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

import { colors } from '@styles'

export const Header = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${colors.grayscale['30'].background}`,
  height: theme.spacing(8),
  alignItems: 'center',
  padding: theme.spacing(2),
}))

export const Footer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  borderTop: `1px solid ${colors.grayscale['30'].background}`,
  height: theme.spacing(8),
  alignItems: 'center',
  padding: theme.spacing(2),
  gap: theme.spacing(2),
}))
