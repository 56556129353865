import Box from '@mui/material/Box'
import * as React from 'react'
import { InputAdornment } from '@mui/material'
import { useFormik } from 'formik'

import { useModal } from '@contexts'
import { CustomButton, Modal, TextInput, TextH2Bold } from '@components'
import { Images } from '@images'
import { useTranslation } from '@hooks'
import { Collectible } from '@types'
import { CollectiblesApi } from '@api'
import { usePlaceForSaleSchema } from '@features/MyCollection/modals/PlaceOnSaleModal/validation'

interface Props {
  collectible: Collectible
}
interface Form {
  price: string
}
const PlaceOnSaleModal = (props: Props) => {
  const { collectible } = props
  const { dismissModal } = useModal()
  const { t } = useTranslation()
  const { placeForSaleSchema } = usePlaceForSaleSchema()

  const formik = useFormik<Form>({
    initialValues: { price: '' },
    validationSchema: placeForSaleSchema,
    onSubmit: async values => {
      formik.setSubmitting(true)
      try {
        const { price } = values
        await CollectiblesApi.putOnSaleById(collectible.id, Number(price))
        dismissModal()
      } catch (e) {
        formik.setSubmitting(false)
      }
    },
  })

  return (
    <Modal.Content>
      <Modal.Header image={Images.WarningImage}>
        <Box display={'flex'} flexDirection={'column'} gap={0} pt={1.5}>
          <TextH2Bold>{t('collectible.modal.place-for-sale.title')}</TextH2Bold>
        </Box>
      </Modal.Header>
      <Modal.Form>
        <TextInput
          label={t('collectible.modal.place-for-sale.value')}
          type={'number'}
          name={'price'}
          fullWidth
          startAdornment={
            <InputAdornment
              sx={{
                '& > .MuiTypography-root': { color: 'black' },
                height: '16px',
                paddingRight: '4px',
              }}
              position='start'
            >
              {t('collectible.modal.place-for-sale.price')}
            </InputAdornment>
          }
          value={formik.values.price}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.price && Boolean(formik.errors.price)}
          helperText={formik.touched.price && formik.errors.price}
        />
      </Modal.Form>
      <Modal.Actions>
        <CustomButton
          variant={'secondary'}
          label={t('collectible.modal.place-for-sale.actions.back')}
          fullWidth={true}
          onClick={() => dismissModal()}
        />
        <CustomButton
          variant={'primary'}
          label={t('collectible.modal.place-for-sale.actions.submit')}
          fullWidth={true}
          disabled={!formik.isValid || formik.isSubmitting}
          onClick={formik.handleSubmit}
        />
      </Modal.Actions>
    </Modal.Content>
  )
}

export { PlaceOnSaleModal }
