import { useMutation } from 'react-query'

import { QUERY_KEYS } from '@api'
import { useTranslation } from '@hooks'
import { useFlash, useModal, useUser } from '@contexts'
import { ErrorResponse } from '@types'

import { ProfileApi } from '../../api'
import { EditProfileSuccessModal } from '../../modals'

export const useProfileEditor = () => {
  const { t } = useTranslation()
  const { showFlash } = useFlash()
  const { showModal } = useModal()
  const { setUserState } = useUser()

  const { mutateAsync: updateProfile } = useMutation([QUERY_KEYS.MY_PROFILE], ProfileApi.update, {
    onSuccess: async data => {
      setUserState(data)
      showModal({ modal: <EditProfileSuccessModal /> })
    },
    onError: (_error: ErrorResponse) => {
      showFlash({ message: t('my-profile.common.error'), severity: 'error' })
    },
  })

  return { updateProfile }
}
