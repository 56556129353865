import { Collectible } from '@types'

export enum TransactionType {
  GIFT = 'GIFT',
  COLLECTIBLE_EXCHANGE = 'COLLECTIBLE_EXCHANGE',
  PRIZE = 'PRIZE',
  BUY = 'BUY',
}

export type TrxUser = {
  id: number
  username: string
}
export interface Transaction {
  id: number
  description: string
  sender: TrxUser
  receiver: TrxUser
  amount: number
  type: TransactionType
  collectible?: Collectible
  createdAt: Date
}

export interface CreatePaymentResponse {
  redirect: string
  paymentId: string
  orderId: string
  paymentToken: string
}

export enum CoinsCurrency {
  EUR = 'EUR',
  USD = 'USD',
}

export interface CoinsBudget {
  amount: number
  currency: CoinsCurrency
  coins?: number
}
