import { useCallback, useState } from 'react'
import * as monei from '@monei-js/components'

import { useFlash } from '@contexts'
import { PaymentsApi } from '@features/Coins/api'

const useActivationCodePayment = (onSuccess: () => void) => {
  const { showFlash } = useFlash()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<unknown>()

  const payActivationCode = useCallback(async () => {
    setIsLoading(true)
    if (!isLoading) {
      try {
        const paymentRequest = await PaymentsApi.createActivationCodePayment()
        const result = await monei.confirmPayment({
          paymentId: paymentRequest.paymentId,
        })
        console.log(result)
        if (result.status === 'SUCCEEDED') {
          setError(null)

          //TODO: mostrar nuestros textos
          showFlash({ message: result.statusMessage ?? 'OK', severity: 'success' })
          onSuccess()
        } else {
          //TODO: mostrar nuestros textos
          showFlash({ message: result.statusMessage ?? 'ERROR', severity: 'error' })
          setError(result)
        }
      } catch (error) {
        console.log(error)
        setError(error)
      }
    }
    setIsLoading(false)
  }, [isLoading])

  return { payActivationCode, isLoading, isError: !!error, error }
}

export { useActivationCodePayment }
