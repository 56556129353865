import * as React from 'react'
import { useCallback } from 'react'

import { useModal } from '@contexts'
import { CustomButton, Modal, TextH2Bold, TextH4Bold } from '@components'
import { Images } from '@images'
import { useTranslation } from '@hooks'
import { CollectiblesApi } from '@api'

import { PurchaseSuccessModal } from '../PurchaseSuccessModal'
import { PurchaseErrorModal } from '../PurchaseErrorModal'
import { useSessionUserUpdater } from '../../../../hooks/useSessionUserUpdater'

interface Props {
  collectibleId: number | string
}
const PurchaseConfirmModal = (props: Props) => {
  const { collectibleId } = props
  const { showModal, dismissModal } = useModal()
  const { t } = useTranslation()
  const { refresh } = useSessionUserUpdater()
  const onPurchase = useCallback(async () => {
    try {
      await CollectiblesApi.purchase(collectibleId)
      showModal({ modal: <PurchaseSuccessModal />, options: { onDismiss: refresh } })
    } catch {
      showModal({ modal: <PurchaseErrorModal /> })
    }
  }, [collectibleId])

  return (
    <Modal.Content>
      <Modal.Header image={Images.WarningImage}>
        <TextH2Bold>{t('marketplace.modals.confirm.title')}</TextH2Bold>
        <TextH4Bold>{t('marketplace.modals.confirm.subtitle')}</TextH4Bold>
      </Modal.Header>
      <Modal.Actions>
        <CustomButton
          variant={'secondary'}
          label={t('marketplace.modals.confirm.back')}
          fullWidth={true}
          onClick={() => dismissModal()}
        />
        <CustomButton
          variant={'primary'}
          label={t('marketplace.modals.confirm.submit')}
          fullWidth={true}
          onClick={() => onPurchase()}
        />
      </Modal.Actions>
    </Modal.Content>
  )
}

export { PurchaseConfirmModal }
