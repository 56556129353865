import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useModal, useUser } from '@contexts'
import { SubscriptionType } from '@types'
import { ROUTES } from '@constants'

import { DigitizeYourFirstTShirtModal } from '../../modals'

const useDigitizeYourFirstTShirt = () => {
  const { userState } = useUser()
  const { pathname } = useLocation()
  const { isOpen, showModal } = useModal()
  const [shouldShowModal, setShouldShowModal] = useState(true)

  const onClose = () => {
    setShouldShowModal(false)
  }
  useEffect(() => {
    if (
      shouldShowModal &&
      userState?.subscription.type === SubscriptionType.NONE &&
      userState?.subscription.digitizeQuota > 0 &&
      !isOpen &&
      (pathname === ROUTES.HOME ||
        pathname === ROUTES.TRIVIAS ||
        pathname === ROUTES.CHALLENGES ||
        pathname === ROUTES.PREDICTIONS )
    ) {
      showModal({
        modal: <DigitizeYourFirstTShirtModal />,
        options: { onClose },
      })
    }
  }, [userState, shouldShowModal, pathname])
}

export { useDigitizeYourFirstTShirt }
