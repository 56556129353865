import * as React from 'react'

import { useModal } from '@contexts'
import { Modal } from '@components'

import { FileProps } from '../../types'
import { ImageCropper } from '../../components/ImageCropper'

interface Props {
  file: FileProps
  onCrop: (file: FileProps) => void
  onCancel: () => void
  aspect?: number
}

const ImageCropperModal = (props: Props) => {
  const { file, onCrop, onCancel } = props
  const { dismissModal } = useModal()

  return (
    <Modal.Content>
      <ImageCropper
        imgSrc={file}
        onCrop={newFile => {
          onCrop(newFile)
          dismissModal()
        }}
        aspect={props.aspect}
        onCancel={() => {
          onCancel()
          dismissModal()
        }}
      />
    </Modal.Content>
  )
}

export { ImageCropperModal }
