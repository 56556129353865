import { default as Logo } from './Logo.svg'
import { default as AppleIcon } from './AppleIcon.svg'
import { default as GoogleIcon } from './GoogleIcon.svg'
import { default as TShirtPlaceholder } from './tshirt-placeholder.svg'
import { default as TShirtThumbnailBack } from './tshirt-thumbnail-back.svg'
import { default as TShirtThumbnailFront } from './tshirt-thumbnail-front.svg'
import { default as TShirtThumbnailRight } from './tshirt-thumbnail-right.svg'
import { default as TShirtThumbnailLeft } from './tshirt-thumbnail-left.svg'
import { default as Placeholder } from './placeholder.png'

const defaultTheme = '0'
const themes = { '0': 'shirtum-theme', '1': 'turkish-league-theme' }
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Theme = require('./' + themes[process.env.REACT_APP_PARTNER_ID ?? defaultTheme])

export const Images = {
  Logo,
  AppleIcon,
  GoogleIcon,
  TShirtPlaceholder,
  TShirtThumbnailBack,
  TShirtThumbnailFront,
  TShirtThumbnailLeft,
  TShirtThumbnailRight,
  Placeholder,
  ...Theme.theme,
}
