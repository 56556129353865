import { RoundedCornersBox, TextH4Regular, TextMainTitleBold } from '@components'
import { useTranslation, useSessionUserUpdater } from '@hooks'
import { useUser } from '@contexts'
import { BuyCoins } from '@features/Coins/components/BuyCoins'

import { useTransactions } from '../..//hooks'

const CoinsBalance = () => {
  const { t } = useTranslation()
  const { userState } = useUser()

  const { invalidate } = useTransactions()
  const { refresh } = useSessionUserUpdater()
  const onBuySucceed = () => {
    invalidate()
    refresh()
  }

  return (
    <RoundedCornersBox
      width={{ lg: '80%', xs: '100%' }}
      padding={2}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
    >
      <TextH4Regular>{t('coins.balance.title')}</TextH4Regular>
      <TextMainTitleBold
        sx={theme => ({
          marginBottom: theme.spacing(1.5),
          fontSize: 40,
        })}
      >
        {t('coins.balance.coins', { coins: userState?.coins ?? 0 })}
      </TextMainTitleBold>

      <BuyCoins onSuccess={onBuySucceed} />
    </RoundedCornersBox>
  )
}

export { CoinsBalance }
