import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useLoginResponseManager } from '@hooks'
import { AuthApi, setAuthHeader } from '@api'
import { LOCAL_STORAGE_KEYS } from '@constants'
import { ErrorResponse } from '@types'
import { useFlash, useModal } from '@contexts'
import { ResendValidationEmailModal } from '@features/Login'

const useLogin = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { showFlash } = useFlash()
  const { showModal } = useModal()
  const onError = (error: ErrorResponse, form: any) => {
    if (error.type === 'LOGIN_ATTEMPTS_EXCEEDED') {
      navigate('/login/change-password')
    } else if (error.type === 'INCORRECT_USER_PASSWORD') {
      showFlash({ message: t('login.password_wrong'), severity: 'error' })
    } else if (error.type === 'EMAIL_VALIDATION_REQUIRED') {
      if (form.username) {
        showModal({ modal: <ResendValidationEmailModal username={form.username} /> })
      }
    } else {
      showFlash({ message: error.type, severity: 'error' })
    }
  }
  const { onSuccess } = useLoginResponseManager()

  const { mutate, isLoading } = useMutation(AuthApi.login, {
    onSuccess: ({ token, refreshToken }) => {
      setAuthHeader(token)
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, token)
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, refreshToken)

      onSuccess()
    },
    onError,
  })

  const { mutate: mutateGoogle, isLoading: isLoadingGoogle } = useMutation(AuthApi.loginGoogle, {
    onSuccess: ({ token, refreshToken }) => {
      setAuthHeader(token)
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, token)
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, refreshToken)

      onSuccess()
    },
    onError,
  })

  return { login: mutate, isLoading: isLoading || isLoadingGoogle, loginGoogle: mutateGoogle }
}

export { useLogin }
