import { FormControl, FormHelperText, ListItemText, MenuItem, Select, SelectChangeEvent, SxProps } from '@mui/material'
import * as React from 'react'
import { ReactElement, useMemo } from 'react'
import { Theme } from '@mui/material/styles'

import { colors } from '@styles'

import { TextInput } from '../TextInput'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    sx: theme => ({
      color: 'white',
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      borderRadius: theme.spacing(2),
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
      backgroundColor: colors.grayscale['80'].background,
    }),
  },
}

interface SelectInputProp<O> {
  label: string
  name?: string
  helperText?: string | boolean | any
  error?: boolean
  multiple?: boolean
  fullWidth?: boolean
  options: O[]
  itemProvider?: (option: O, index: number) => ReactElement
  value: O[]
  onChange: (value: O[]) => void
  objectToString?: (value?: O) => string | undefined
  sx?: SxProps<Theme>
}
const SelectInput = <O,>(props: SelectInputProp<O>) => {
  const {
    name,
    multiple,
    label,
    value,
    onChange,
    itemProvider,
    options,
    helperText,
    error,
    objectToString = (v?) => v?.toString(),
    sx = {},
  } = props
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event
    const stringValues = Array.isArray(value) ? value : [value]
    const oValues: O[] = stringValues
      .map(s => {
        return options.find(o => objectToString(o) === s.toString())
      })
      .filter(o => !!o) as O[]

    onChange(oValues)
  }

  const selectedValue = useMemo(() => {
    const valueArray = Array.isArray(value) ? value : [value]

    return valueArray.map(v => objectToString(v) ?? '')
  }, [value])

  return (
    <FormControl
      className={'ShiSelectInput-form-control'}
      variant='outlined'
      fullWidth={props.fullWidth}
      sx={{ minWidth: '120px', ...sx }}
    >
      <Select<string[]>
        name={name}
        multiple={multiple}
        value={selectedValue.length == 0 ? (multiple ? [] : '') : selectedValue}
        onChange={handleChange}
        input={<TextInput label={label} formControlSx={{ minHeight: 'auto' }} />}
        renderValue={selected => selected.map(s => s?.toString() ?? '').join(', ')}
        MenuProps={MenuProps}
        className={'ShiSelectInput-select'}
        error={error}
      >
        {options.map((option: O, index: number) => {
          const name = objectToString(option) ?? `option ${index}`

          return (
            <MenuItem key={name} value={name} className={'ShiSelectInput-item'}>
              {itemProvider ? itemProvider(option, index) : <ListItemText primary={name} />}
            </MenuItem>
          )
        })}
      </Select>
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export { SelectInput }
