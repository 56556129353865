import { usePaginatedRequestWithFilters } from '@hooks'
import { QUERY_KEYS } from '@api'
import { useUser } from '@contexts'

import { RankingApi } from '../../api'
import { RankingGlobalFilters, RankingGlobal, DatesRange, RankingType } from '../../types'

const useGlobalRanking = (datesRange: DatesRange, type: RankingType) => {
  const { userState } = useUser()
  const { isLoading, data, changeFilters, changePage, paginationWithFilters } = usePaginatedRequestWithFilters<
    RankingGlobalFilters,
    RankingGlobal
  >(
    { datesRange, type },
    QUERY_KEYS.RANKING + `-${type}-${userState ? userState.id : '-'}-` + datesRange,
    RankingApi.paginateRanking,
  )

  return { isLoading, data, changeFilters, changePage, paginationWithFilters }
}

export { useGlobalRanking }
