import { useMutation } from 'react-query'

import { QUERY_KEYS, ChallengesApi } from '@api'
import { ApiError } from '@types'

import { Challenge, CollectedItemDto } from '../../types'

const useSaveCollectedItems = (
  challengeId: number | string,
  onSuccess?: () => void,
  onError?: (error: ApiError) => void,
) => {
  const {
    mutate: saveCollectedItems,
    isLoading: isSavingCollectedItems,
    data: challenge,
  } = useMutation<Challenge, ApiError, CollectedItemDto[]>(
    [QUERY_KEYS.CHALLENGE, String(challengeId)],
    (collectedItems: CollectedItemDto[]) => ChallengesApi.saveCollectedItems(challengeId, collectedItems),
    {
      onSuccess,
      onError,
    },
  )

  return { challenge, isLoading: isSavingCollectedItems, saveCollectedItems }
}

export { useSaveCollectedItems }
