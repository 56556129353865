import Box from '@mui/material/Box'

import { Statistics, TeamStatistics } from '@features/Predictions/types'
import { PredictionRow } from '@features/Predictions/components/PredictionRow'
import { TextBodyRegular, TextH2Bold, TextH4Bold } from '@components'
import { colors } from '@styles'
import { useTranslation } from '@hooks'

import { useScorersToString } from '../../../hooks'

interface StatisticsRowProps {
  localValue: string | number
  visitorValue: string | number
  label: string
}
const StatisticsRow = (props: StatisticsRowProps) => {
  const { localValue, visitorValue, label } = props

  return (
    <PredictionRow
      sx={theme => ({ marginBottom: theme.spacing(2) })}
      leftCellContent={<TextH2Bold>{localValue}</TextH2Bold>}
      centralCellContent={<TextH4Bold sx={{ textAlign: 'center' }}>{label}</TextH4Bold>}
      rightCellContent={<TextH2Bold>{visitorValue}</TextH2Bold>}
    />
  )
}

interface Props {
  statistics?: Statistics
}
const StatisticsTab = (props: Props) => {
  const { statistics } = props

  const { t } = useTranslation()
  const scorersToString = useScorersToString()

  return statistics ? (
    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
      <PredictionRow
        sx={theme => ({ marginBottom: theme.spacing(1) })}
        leftCellContent={<TextBodyRegular>{t('predictions.form.header-results')}</TextBodyRegular>}
        centralCellContent={
          <TextH2Bold sx={{ color: colors.primary.main.background, textAlign: 'center' }}>
            {t('predictions.form.header-stats')}
          </TextH2Bold>
        }
        rightCellContent={<TextBodyRegular>{t('predictions.form.header-results')}</TextBodyRegular>}
      />

      <StatisticsRow
        localValue={statistics.localStats.goals}
        visitorValue={statistics.visitorStats.goals}
        label={t(`predictions.form.goals`)}
      />
      <StatisticsRow
        localValue={scorersToString(statistics.localStats.scorers)}
        visitorValue={scorersToString(statistics.visitorStats.scorers)}
        label={t(`predictions.form.scorers`)}
      />

      <StatisticsRow
        localValue={`${statistics.localStats.possession}%`}
        visitorValue={`${statistics.visitorStats.possession}%`}
        label={t(`predictions.form.possession`)}
      />
      {(
        [
          { attribute: 'offsides' },
          { attribute: 'cornerKicks', label: 'corner-kicks' },
          { attribute: 'shotsOffTarget', label: 'shots-off-target' },
          { attribute: 'shotsOnTarget', label: 'shots-on-target' },
          { attribute: 'blockedScoringAttempts', label: 'blocked-scoring-attempts' },
          { attribute: 'goalkeeperSaves', label: 'goalkeeper-saves' },
          { attribute: 'tackles' },
          { attribute: 'totalPasses', label: 'total-passes' },
          { attribute: 'completedPasses', label: 'completed-passes' },
          { attribute: 'fouls' },
          { attribute: 'yellowCards', label: 'yellow-cards' },
          { attribute: 'redCards', label: 'red-cards' },
        ] as { attribute: keyof Omit<TeamStatistics, 'scorers'>; label?: string }[]
      ).map(i => (
        <StatisticsRow
          key={i.attribute}
          localValue={statistics.localStats[i.attribute]}
          visitorValue={statistics.visitorStats[i.attribute]}
          label={t(`predictions.form.${i.label ?? i.attribute}`)}
        />
      ))}
    </Box>
  ) : null
}
export { StatisticsTab }
