import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import * as React from 'react'
import { PropsWithChildren, useCallback } from 'react'
import { LinearProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import {
  CountDown,
  CustomButton,
  RoundedCornersBox,
  TextBodyRegular,
  TextCaptionBold,
  TextH4Bold,
  TextTitleBold,
} from '@components'
import { colors } from '@styles'
import { useTranslation } from '@hooks'
import { ROUTES } from '@constants'
import { useModal, useUser } from '@contexts'
import { LoginModal } from '@features/Login'

import { Challenge, ChallengeClientStatus } from '../../types'

const ChallengeCardImage = styled('img')(({ theme }) => ({
  borderRadius: theme.spacing(2),
  borderColor: colors.grayscale['30'].background,
  borderWidth: 1,
  borderStyle: 'solid',
  width: '50%',
  height: theme.spacing(50),
  objectFit: 'cover',
}))

const ChallengeDetailsColumn = (props: PropsWithChildren) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-evenly'}
      flexWrap={'wrap'}
      gap={3}
      sx={{ width: '50%' }}
    >
      {props.children}
    </Box>
  )
}

interface Props {
  challenge: Challenge
  joinEnabled: boolean
}

const ChallengeCard = (props: Props) => {
  const { challenge, joinEnabled } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { userState } = useUser()
  const { showModal } = useModal()
  const onButtonJoinClicked = useCallback(() => {
    if (!userState) {
      showModal({ modal: <LoginModal /> })
    } else {
      navigate(`${ROUTES.CHALLENGES}/${challenge.id}`)
    }
  }, [userState, challenge])

  return (
    <RoundedCornersBox
      display={'flex'}
      flexDirection={'row'}
      flexBasis={{ xl: '30%', lg: '45%', md: '45%', sm: '50%', xs: '100%' }}
      mb={4}
      p={2}
      gap={2.5}
    >
      <ChallengeCardImage src={challenge.imageUrl} />
      <ChallengeDetailsColumn>
        <TextTitleBold>{challenge.name}</TextTitleBold>
        <TextBodyRegular>{challenge.description}</TextBodyRegular>
        <Box gap={2} display={'flex'} flexDirection={'column'}>
          <CountDown dueDate={challenge.endDate} />
          <Box width={'100%'}>
            <LinearProgress
              variant='determinate'
              value={
                (100 * (challenge?.challengeClient?.totalCollectedItems ?? 0)) /
                (challenge?.challengeClient?.totalRequiredItems ?? 1)
              }
              color={'primary'}
            />
            <TextCaptionBold sx={{ textAlign: 'right' }}>{`${challenge?.challengeClient?.totalCollectedItems ?? 0}/${
              challenge?.challengeClient?.totalRequiredItems ?? 0
            }`}</TextCaptionBold>
          </Box>
        </Box>
        {challenge.challengeClient?.status !== ChallengeClientStatus.FINISHED && (
          <CustomButton
            variant={'secondary'}
            label={t('challenges.join')}
            onClick={onButtonJoinClicked}
            disabled={!challenge.challengeClient && !joinEnabled}
          />
        )}
        {challenge.challengeClient?.status === ChallengeClientStatus.FINISHED && (
          <TextH4Bold>{t('challenges.points', { points: `${challenge.challengeClient.points}` })}</TextH4Bold>
        )}
      </ChallengeDetailsColumn>
    </RoundedCornersBox>
  )
}

export { ChallengeCard }
