import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const CentralCell = styled(Box)(() => ({
  width: '20%',
  display: 'flex',
  justifyContent: 'center',
}))

export const SideCell = styled(Box)(() => ({
  width: '40%',
  display: 'flex',
}))
export const LeftCell = styled(SideCell)(() => ({
  justifyContent: 'flex-start',
}))

export const RightCell = styled(SideCell)(() => ({
  justifyContent: 'flex-end',
}))
