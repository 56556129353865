import Box from '@mui/material/Box'
import * as React from 'react'
import { useEffect } from 'react'

import { Loading, PaginatedTable, TableActions, TableColumns, TextCaptionBold, TextH3Bold } from '@components'
import { colors } from '@styles'
import { useTranslation } from '@hooks'
import { useGlobalRanking } from '@features/Rankings/hooks/useGlobalRanking'

import { DatesRange, RankingGlobal, RankingGlobalComparator, RankingType } from '../../types'

interface Props {
  datesRange: DatesRange
}

const type = RankingType.Global
const GlobalRankingTable = (props: Props) => {
  const { datesRange } = props
  const { t } = useTranslation()
  const { data, isLoading, changePage, changeFilters } = useGlobalRanking(datesRange, type)

  useEffect(() => {
    changeFilters({ datesRange, type })
  }, [datesRange])

  const columns: (TableColumns<RankingGlobal> | TableActions<RankingGlobal>)[] = [
    {
      width: '10%',
      field: 'position',
      sorteable: false,
      type: 'column',
      headerName: t('ranking.header.position'),
      getValue: item => {
        return item.position
      },
      renderCell: (_item, value) => {
        return (
          <Box
            display={'flex'}
            flexDirection={'column'}
            sx={{ minHeight: 40, justifyContent: 'center', paddingLeft: 1 }}
          >
            <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>{value}</TextCaptionBold>
          </Box>
        )
      },
    },
    {
      width: '30%',
      field: 'name',
      sorteable: false,
      type: 'column',
      headerName: t('ranking.header.name'),
      renderCell: (item, value) => {
        return (
          <Box display={'flex'} flexDirection={'column'}>
            <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>{value}</TextCaptionBold>
          </Box>
        )
      },
      getValue: item => `${item.name || ''}`,
    },
    {
      width: '10%',
      field: 'predictionPoints',
      sorteable: false,
      type: 'column',
      headerName: t('ranking.header.predictionPoints'),
      renderCell: (_item, value) => {
        return (
          <Box display={'flex'} flexDirection={'column'}>
            <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>{value}</TextCaptionBold>
          </Box>
        )
      },
      getValue: item => {
        return item.predictionPoints ?? '-'
      },
    },
    {
      width: '10%',
      field: 'triviaPoints',
      sorteable: false,
      type: 'column',
      headerName: t('ranking.header.triviaPoints'),
      renderCell: (_item, value) => {
        return (
          <Box display={'flex'} flexDirection={'column'}>
            <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>{value}</TextCaptionBold>
          </Box>
        )
      },
      getValue: item => {
        return item.triviaPoints ?? '-'
      },
    },
    {
      width: '10%',
      field: 'challengePoints',
      sorteable: false,
      type: 'column',
      headerName: t('ranking.header.challengePoints'),
      renderCell: (_item, value) => {
        return (
          <Box display={'flex'} flexDirection={'column'}>
            <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>{value}</TextCaptionBold>
          </Box>
        )
      },
      getValue: item => {
        return item.challengePoints ?? '-'
      },
    },
    {
      width: '10%',
      field: 'totalItemsPoints',
      sorteable: false,
      type: 'column',
      headerName: t('ranking.header.totalItemsPoints'),
      renderCell: (_item, value) => {
        return (
          <Box display={'flex'} flexDirection={'column'}>
            <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>{value}</TextCaptionBold>
          </Box>
        )
      },
      getValue: item => {
        return item.totalItemsPoints ?? '-'
      },
    },
    {
      width: '10%',
      field: 'totalCollectiblePoints',
      sorteable: false,
      type: 'column',
      headerName: t('ranking.header.totalCollectiblePoints'),
      renderCell: (_item, value) => {
        return (
          <Box display={'flex'} flexDirection={'column'}>
            <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>{value}</TextCaptionBold>
          </Box>
        )
      },
      getValue: item => {
        return item.totalCollectiblePoints ?? '-'
      },
    },
    {
      width: '10%',
      field: 'totalPoints',
      sorteable: false,
      type: 'column',
      headerName: t('ranking.header.totalPoints'),
      renderCell: (_item, value) => {
        return (
          <Box display={'flex'} flexDirection={'column'}>
            <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>{value}</TextCaptionBold>
          </Box>
        )
      },
      getValue: item => {
        return item.totalPoints ?? '-'
      },
    },
  ]

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && data?.data && data?.data.length == 0 && (
        <Box alignSelf={'center'} mt={4}>
          <TextH3Bold>{t('ranking.empty')} </TextH3Bold>
        </Box>
      )}
      {data?.data && data?.data.length > 0 && (
        <PaginatedTable<RankingGlobal>
          columns={columns}
          onChangePage={changePage}
          data={data}
          extraItemComparator={RankingGlobalComparator}
        />
      )}
    </>
  )
}

export { GlobalRankingTable }
