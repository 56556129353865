import { Prediction, PredictionsFilters } from '@features/Predictions/types'
import { QUERY_KEYS } from '@api'
import { PredictionsApi } from '@features/Predictions/api'
import { usePaginatedRequestWithFilters } from '@hooks'

const usePredictions = () => {
  const { isLoading, data, changeFilters, changePage, paginationWithFilters } = usePaginatedRequestWithFilters<
    PredictionsFilters,
    Prediction
  >({}, QUERY_KEYS.PREDICTIONS, PredictionsApi.paginate)

  return { isLoading, data, changeFilters, changePage, paginationWithFilters }
}

export { usePredictions }
