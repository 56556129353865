import { createContext, ReactNode, useContext, useState } from 'react'

type ContextState = {
  isOpen: boolean
  setOpen: (isOpen: boolean | ((prevState: boolean) => boolean)) => void
}

const MenuDrawerContext = createContext<ContextState | null>(null)

export function MenuDrawerProvider({ children }: { children: ReactNode }) {
  const [isOpen, setOpen] = useState(false)

  return (
    <MenuDrawerContext.Provider
      value={{
        isOpen: isOpen,
        setOpen,
      }}
    >
      {children}
    </MenuDrawerContext.Provider>
  )
}

export function useMenuDrawer(): ContextState {
  const context = useContext(MenuDrawerContext)
  if (!context) throw Error('This hook can only be used under the MenuDrawerProvider component')

  return context
}
