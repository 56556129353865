export enum PollMediaType {
  PICTURE = 'PICTURE',
  VIDEO = 'VIDEO',
}

export enum PollPrizeType {
  NONE = 'NONE',
  POINTS = 'POINTS',
  ACTIVATION_CODE = 'ACTIVATION_CODE',
}

export type PollOption = {
  id: number
  description: string
  mediaType: PollMediaType
  mediaUrl: string
  mediaThumbnailUrl: string
}

export type Poll = {
  id: number
  name: string
  prizeType: PollPrizeType
  points: number
  options: PollOption[]
  clientOption?: PollOption | null
}
