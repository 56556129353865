import { useNavigate, useParams } from 'react-router-dom'
import { useMemo } from 'react'
import Box from '@mui/material/Box'
import * as React from 'react'

import { CustomButton, Title, ItemImages, ItemMetadata, Header, Content, Loading } from '@components'
import { useGroupedCollectible, useMetadataToText, useTranslation } from '@hooks'
import { AppBase } from '@features/AppBase'
import { Images } from '@images'

import { TokenList } from '../../components'

const MyCollectionItemView = () => {
  const { id = '0' } = useParams()
  const { getName } = useMetadataToText()
  const navigate = useNavigate()
  const { groupedCollectible, isLoading } = useGroupedCollectible(id)
  const images = useMemo(
    () =>
      groupedCollectible
        ? [
            groupedCollectible.mediaFiles.frontImage,
            groupedCollectible.mediaFiles.backImage,
            groupedCollectible.mediaFiles.leftArmImage,
            groupedCollectible.mediaFiles.rightArmImage,
          ]
        : [],
    [groupedCollectible],
  )

  const { t } = useTranslation()

  return (
    <AppBase requireActiveSession backgroundImage={Images.CollectiblesBackground}>
      <Box width={'100%'} px={{ lg: 16.5, md: 10, sm: 8, xs: 2 }} py={4}>
        <Header>
          <CustomButton
            variant={'secondary'}
            label={t('my-collectibles.view.back')}
            startIcon={['fas', 'chevron-left']}
            onClick={() => navigate('/my-collectibles')}
          />
          <Title text={getName(groupedCollectible)} />
        </Header>

        <Content sx={{ alignItems: 'flex-start' }}>
          {isLoading && <Loading />}
          {groupedCollectible && (
            <>
              <ItemImages images={images} />
              <Box flexDirection={'column'} display={'flex'} flexGrow={4} flexShrink={0}>
                <ItemMetadata metadata={groupedCollectible} />
                <TokenList groupedCollectible={groupedCollectible} />
              </Box>
            </>
          )}
        </Content>
      </Box>
    </AppBase>
  )
}

export { MyCollectionItemView }
