import React, { useContext, useState } from 'react'

import { Team } from '@types'

import { ExtraInfoForm, PersonalInfoForm } from '../screens/CreateAccountMain/components/Step/types'

export type CreateAccountState = {
  personalInfoForm?: PersonalInfoForm
  nationalTeam?: Team
  clubTeam?: Team
  extraInfo?: ExtraInfoForm
  submit: boolean
}

type ContextState = {
  createAccountState: CreateAccountState
  setCreateAccountState: (state: CreateAccountState) => void
}

const CreateAccountContext = React.createContext<ContextState | null>(null)

export function CreateAccountProvider({ children }: { children: React.ReactNode }) {
  const [createAccountState, setCreateAccountState] = useState<CreateAccountState>({ submit: false })

  return (
    <CreateAccountContext.Provider value={{ createAccountState, setCreateAccountState }}>
      {children}
    </CreateAccountContext.Provider>
  )
}

export function useCreateAccount(): ContextState {
  const context = useContext(CreateAccountContext)
  if (!context) throw Error('This hook can only be used under the CreateAccountProvider component')

  return context
}
