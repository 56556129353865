import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'

import { useFlash, useModal } from '@contexts'
import { ErrorResponse } from '@types'
import { useTranslation } from '@hooks'

import { SignUpApi } from '../../api'
import { useCreateAccount } from '../../contexts/create-account-context'
import { SignUpDto } from '../../types'
import { Modal } from '../../modals'

export const useSignUp = () => {
  const { showFlash } = useFlash()
  const { showModal } = useModal()
  const navigate = useNavigate()

  const { t } = useTranslation()
  const { createAccountState } = useCreateAccount()

  const onError = (error: ErrorResponse) => {
    showModal({ modal: <Modal.Error message={error.message} /> })
  }
  const { mutate, isLoading } = useMutation(SignUpApi.signUp, {
    onSuccess: () => {
      navigate('/')
      showFlash({ message: t('create-account.common.success'), severity: 'success' })
      showModal({ modal: <Modal.Success /> })
    },
    onError,
  })

  const signUpMutation = useCallback(() => {
    let phone = ''
    if (createAccountState?.extraInfo?.countryCode && createAccountState?.extraInfo?.phone) {
      phone = `+${createAccountState?.extraInfo?.countryCode}${createAccountState?.extraInfo?.phone}`
    }

    const payload: SignUpDto = {
      firstName: createAccountState?.personalInfoForm?.firstName ?? '',
      lastName: createAccountState?.personalInfoForm?.lastName ?? '',
      birthdate: createAccountState?.personalInfoForm?.birthdate ?? '',
      password: createAccountState?.personalInfoForm?.password ?? '',
      email: createAccountState?.personalInfoForm?.email ?? '',
      username: createAccountState?.personalInfoForm?.email ?? '',
      teamId: createAccountState?.clubTeam?.id ?? 0,
      nationalTeamId: createAccountState?.nationalTeam?.id ?? 0,
      phone: phone,
      picture: createAccountState?.extraInfo?.picture,
    }

    return mutate(payload)
  }, [createAccountState, mutate])

  return { signUpMutation: signUpMutation, isLoading }
}
