import * as React from 'react'
import { useFormik } from 'formik'

import { CustomButton, DateInput, PasswordInput, TextInput } from '@components'
import { useTranslation } from '@hooks'

import { PersonalInfoForm, StepProps } from '../types'
import { usePersonalInfoValidationSchema } from './validation'
import { useCreateAccount } from '../../../../../contexts/create-account-context'

const PersonalInfo = (props: StepProps) => {
  const { createAccountState, setCreateAccountState } = useCreateAccount()
  const { t } = useTranslation()
  const { personalInfoValidationSchema } = usePersonalInfoValidationSchema()
  const formik = useFormik<PersonalInfoForm>({
    initialValues: {
      firstName: '',
      lastName: '',
      birthdate: '',
      email: '',
      password: '',
      confirmPassword: '',
      ...(createAccountState?.personalInfoForm ?? {}),
    },
    validationSchema: personalInfoValidationSchema,
    onSubmit: values => {
      setCreateAccountState({ ...createAccountState, personalInfoForm: { ...values } })
    },
  })

  return (
    <>
      <TextInput
        label={t('create-account.personal-info.input.first-name')}
        fullWidth
        name={'firstName'}
        value={formik.values.firstName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
        helperText={formik.touched.firstName && formik.errors.firstName}
      />
      <TextInput
        label={t('create-account.personal-info.input.last-name')}
        fullWidth
        name={'lastName'}
        value={formik.values.lastName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
        helperText={formik.touched.lastName && formik.errors.lastName}
      />
      <DateInput
        label={t('create-account.personal-info.input.birthdate')}
        fullWidth
        name={'birthdate'}
        openTo={'year'}
        onChange={date => {
          if (!!date) {
            formik.setFieldValue('birthdate', date.format('YYYY-MM-DD'))
          }
          formik.setFieldTouched('birthdate', true, true)
        }}
        error={formik.touched.birthdate && Boolean(formik.errors.birthdate)}
        helperText={formik.touched.birthdate && formik.errors.birthdate}
      />
      <TextInput
        label={t('create-account.personal-info.input.email')}
        fullWidth
        name={'email'}
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
      <PasswordInput
        label={t('create-account.personal-info.input.password')}
        fullWidth
        name={'password'}
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />
      <PasswordInput
        label={t('create-account.personal-info.input.confirm-password')}
        fullWidth
        name={'confirmPassword'}
        value={formik.values.confirmPassword}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
      />
      <CustomButton
        variant={'primary'}
        label={t('create-account.common.input.next')}
        fullWidth={true}
        disabled={!formik.isValid}
        onClick={() => {
          formik.submitForm().then(() => (props.next ? props.next() : false))
        }}
      />
    </>
  )
}

export { PersonalInfo }
