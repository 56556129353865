import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box'
import * as React from 'react'

import { useModal } from '@contexts'
import { CustomButton, Modal, TextH2Bold, TextH4Bold } from '@components'
import { Images } from '@images'
import { colors } from '@styles'
import { useTranslation } from '@hooks'

interface Props {
  error: string
}
const BuyCoinsErrorModal = (props: Props) => {
  const { dismissModal } = useModal()
  const { t } = useTranslation()
  const { error } = props

  return (
    <Modal.Content>
      <Modal.Header image={Images.BuyCoinsError}>
        <FontAwesomeIcon icon={['far', 'circle-xmark']} size={'3x'} color={colors.status.error.background} />
        <Box display={'flex'} flexDirection={'column'} gap={0} pt={1.5}>
          <TextH2Bold>{t('coins.buy.modals.error.title')}</TextH2Bold>
          <TextH4Bold>{error}</TextH4Bold>
        </Box>
      </Modal.Header>
      <Modal.Actions>
        <CustomButton
          variant={'secondary'}
          label={t('coins.buy.modals.error.back')}
          fullWidth={true}
          onClick={() => dismissModal()}
        />
      </Modal.Actions>
    </Modal.Content>
  )
}

export { BuyCoinsErrorModal }
