import Box from '@mui/material/Box'
import { LinearProgress } from '@mui/material'
import * as React from 'react'

import { CountDown, CustomButton, TextCaptionBold } from '@components'
import { useTranslation } from '@hooks'

import { PropsWithChallenge } from '../../types'

const ChallengeStatusBar = (
  props: PropsWithChallenge<{
    pendingChanges: boolean
    onSubmit: () => void
    isSubmitting: boolean
  }>,
) => {
  const { challenge, pendingChanges, onSubmit, isSubmitting } = props
  const { t } = useTranslation()

  return challenge ? (
    <Box
      display={'flex'}
      flexDirection={{ lg: 'row', md: 'row', sm: 'column', xs: 'column' }}
      justifyContent={'space-between'}
      mt={4}
      gap={4}
      alignItems={'center'}
    >
      <CountDown dueDate={challenge.endDate} />
      <Box flexGrow={1} width={'100%'}>
        <LinearProgress
          variant='determinate'
          value={
            (100 * (challenge?.challengeClient?.totalCollectedItems ?? 0)) /
            (challenge?.challengeClient?.totalRequiredItems ?? 1)
          }
          color={'primary'}
        />
        <TextCaptionBold sx={{ textAlign: 'right' }}>{`${challenge?.challengeClient?.totalCollectedItems ?? 0}/${
          challenge?.challengeClient?.totalRequiredItems ?? 0
        }`}</TextCaptionBold>
      </Box>
      <CustomButton
        variant={'primary'}
        label={t('challenges.view.actions.save')}
        sx={{ flexShrink: 0 }}
        disabled={!pendingChanges || isSubmitting}
        onClick={onSubmit}
      />
    </Box>
  ) : null
}

export { ChallengeStatusBar }
