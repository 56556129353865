import { PropsWithChildren } from 'react'
import Box from '@mui/material/Box'
import * as React from 'react'

const Form = (props: PropsWithChildren) => {
  return (
    <Box
      className={'ShiModal-form'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'flex-start'}
      alignSelf={'stretch'}
      gap={1.5}
    >
      {props.children}
    </Box>
  )
}

export { Form }
