interface Props {
  size?: number
}
const BannerIcon = (props: Props) => {
  const { size = 24 } = props

  return (
    <svg width={size} height={size} viewBox='0 0 23 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        id='Vector'
        d='M10.1443 14.433C6.54639 14.4124 3.64948 11.5361 3.6701 8C3.6701 7.97938 3.6701 7.95876 3.6701 7.93814C3.69072 4.40206 6.61856 1.5567 10.2165 1.56701C12.0928 1.56701 13.7835 2.37113 14.9588 3.61856C13.5052 1.43299 11 0 8.14433 0C3.63917 0 0 3.57732 0 8C0 12.4227 3.63917 16 8.13402 16C10.9794 16 13.4948 14.5567 14.9485 12.3814C13.7526 13.6495 12.0412 14.4433 10.1443 14.433Z'
        fill='currentColor'
      />
      <path
        id='Vector_2'
        d='M17.866 4.26807L17.8041 7.22683L15.0413 7.97941L17.7629 8.94848L17.7011 11.6598L19.4742 9.54642L22.1547 10.4743L20.6083 8.20621L22.5052 5.94848L19.598 6.7526L17.8763 4.28868L17.866 4.26807Z'
        fill='currentColor'
      />
    </svg>
  )
}

export { BannerIcon }
