import { useTranslation } from '@hooks'

import { usePlayerAccumulator } from '../usePlayerAccumulator'
import { OWN_GOAL_PLAYER_ID, Player } from '../../types'

const useScorersToString = () => {
  const { t } = useTranslation()
  const playerAccumulator = usePlayerAccumulator()

  return (scorers: Player[]) => {
    return scorers.length > 0
      ? playerAccumulator(scorers)
          .map(pc => {
            const name =
              Number(pc.player.id) == OWN_GOAL_PLAYER_ID ? t('predictions.scorer-picker.own-goal') : pc.player.name

            return name + (pc.count > 1 ? ` (${pc.count})` : '')
          })
          .join(', ')
      : '-'
  }
}
export { useScorersToString }
