import { styled, Theme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { PropsWithChildren } from 'react'
import { SxProps } from '@mui/material'

import { colors } from '@styles'

const Content = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(3),
  border: `2px solid ${colors.grayscale[10].background}`,
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  width: '100%',
  backgroundColor: colors.grayscale['100'].background,
  padding: theme.spacing(2),
  justifyContent: 'center',
  flexWrap: 'wrap',
}))

interface Props extends PropsWithChildren {
  sx?: SxProps<Theme>
}
const WidgetContent = (props: Props) => {
  return <Content sx={props.sx}>{props.children}</Content>
}
export { WidgetContent }
