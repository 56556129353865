import { useQuery } from 'react-query'
import { useCallback, useState } from 'react'

import { QUERY_KEYS, ItemsApi } from '@api'
import { ItemFilters, PaginatedRequest } from '@types'

const usePublicItems = () => {
  const [paginationWithFilters, updatePaginationWithFilters] = useState<PaginatedRequest<ItemFilters>>({
    page: 0,
    filters: {},
  })
  const { page, filters } = paginationWithFilters

  const changeFilters = (f: ItemFilters) => {
    updatePaginationWithFilters({ filters: { ...f }, page: 0 })
  }

  const changePage = useCallback(
    (page: number) => {
      updatePaginationWithFilters({ ...paginationWithFilters, page })
    },
    [paginationWithFilters],
  )

  const { data, isLoading } = useQuery(
    [QUERY_KEYS.PUBLIC_ITEMS, page, filters],
    () => ItemsApi.getPublicItems(paginationWithFilters),
    {
      refetchOnWindowFocus: false,
    },
  )

  return { data, isLoading, changePage, changeFilters }
}

export { usePublicItems }
