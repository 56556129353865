import { IconName } from '@fortawesome/free-regular-svg-icons'

interface MenuItemProps {
  id: string
  label: string
  icon?: IconName
  subMenu?: MenuItemProps[]
  userRequired?: boolean
}

const menu: MenuItemProps[] = [
  {
    id: 'nav.digitize',
    label: 'nav.digitize',
  },
  {
    id: 'nav.play',
    label: 'nav.play',
    subMenu: [
      { id: 'nav.predictions', label: 'nav.predictions' },
      { id: 'nav.challenge', label: 'nav.challenge' },
      { id: 'nav.trivia', label: 'nav.trivia' },
    ],
  },
  {
    id: 'nav.collectibles',
    label: 'nav.collectibles',
    userRequired: true,
    subMenu: [
      {
        id: 'nav.my-shirts',
        label: 'nav.my-shirts',
      },
      {
        id: 'nav.my-collectibles',
        label: 'nav.my-collectibles',
      },
    ],
  },
  { id: 'nav.marketplace', label: 'nav.marketplace' },
  { id: 'nav.community', label: 'nav.community' },
  { id: 'nav.museum', label: 'nav.museum' },
]

export { menu }
