import { api } from '@api'
import { Collectible, ItemFilters, PaginatedRequest, PaginatedResponse } from '@types'

const purchase = async (collectibleId: string | number) => {
  await api.post<void>(`/collectibles/purchase/${collectibleId}`)
}
const getOnSaleById = async (collectibleId: string | number) => {
  const response = await api.get<Collectible>(`/collectibles/on-sale/${collectibleId}`)
  const collectible = response.data
  if (collectible.owner) {
    collectible.metadata.owner = collectible.owner
  }

  return response.data
}

const putOnSaleById = async (collectibleId: string | number, price: number) => {
  const response = await api.put<Collectible>(`/collectibles/on-sale/${collectibleId}`, { price })

  return response.data
}

const removeFromSaleById = async (collectibleId: string | number) => {
  const response = await api.delete<Collectible>(`/collectibles/on-sale/${collectibleId}`)

  return response.data
}

const getCollectiblesOnSale = async (request: PaginatedRequest<ItemFilters>) => {
  const { page, pageSize, filters } = request
  const { brand, team, t: _, ...others } = filters
  const collectibleFilters = { ...others }
  if (brand && Array.isArray(brand) && brand.length > 0) {
    collectibleFilters['brandId'] = brand.map(b => b.id)
  }
  if (team && Array.isArray(team) && team.length > 0) {
    collectibleFilters['teamId'] = team.map(t => t.id)
  }
  const response = await api.get<PaginatedResponse<Collectible>>(`/collectibles/on-sale`, {
    params: { page, pageSize, ...collectibleFilters },
  })
  const { data, ...other } = response.data

  return { ...other, data }
}

const getMyCollectiblesWithFilters = async (request: PaginatedRequest<ItemFilters>) => {
  const { page, pageSize, filters } = request
  const { brand, team, t: _, ...others } = filters
  const collectibleFilters = { ...others }
  if (brand && Array.isArray(brand) && brand.length > 0) {
    collectibleFilters['brandId'] = brand.map(b => b.id)
  }
  if (team && Array.isArray(team) && team.length > 0) {
    collectibleFilters['teamId'] = team.map(t => t.id)
  }
  const response = await api.get<PaginatedResponse<Collectible>>(`/collectibles/owned`, {
    params: { page, pageSize, ...collectibleFilters },
  })
  const { data, ...other } = response.data

  return { ...other, data }
}

export const CollectiblesApi = {
  purchase,
  getOnSaleById,
  getCollectiblesOnSale,
  putOnSaleById,
  removeFromSaleById,
  getMyCollectiblesWithFilters,
}
