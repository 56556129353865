import Box from '@mui/material/Box'
import * as React from 'react'

import { Collectible } from '@types'
import { CustomIconButton, RoundedCornersBox, TextCaptionBold, TextLegalRegular } from '@components'
import { colors } from '@styles'
import { useTranslation } from '@hooks'
import { useModal } from '@contexts'

import { PlaceOnSaleModal, RemoveFromSaleModal } from '../..//modals'
import { Preview } from './styled'

interface Props {
  collectible: Collectible
  image: string
  onChange?: () => void
}
const TokenItem = (props: Props) => {
  const { collectible, image, onChange } = props
  const { showModal } = useModal()
  const { t } = useTranslation()
  const onSale = !!collectible.price

  const placeOnSale = () => {
    showModal({
      modal: <PlaceOnSaleModal collectible={collectible} />,
      options: {
        onDismiss: onChange,
      },
    })
  }

  const removeFromSale = () => {
    showModal({
      modal: <RemoveFromSaleModal collectible={collectible} />,
      options: {
        onDismiss: onChange,
      },
    })
  }

  return (
    <RoundedCornersBox
      mt={2}
      sx={{
        borderWidth: 1,
        padding: 1,
        width: '100%',
        borderColor: colors.primary.main.background,
      }}
      display={'flex'}
      justifyContent={'space-between'}
      flexDirection={'row'}
      alignItems={'center'}
    >
      <Preview src={image} />
      <Box flexDirection={'column'} px={2} flexWrap={'wrap'}>
        <TextCaptionBold>{`#${collectible.id}`}</TextCaptionBold>
        {onSale && (
          <TextLegalRegular sx={{ color: colors.grayscale['10'].background }}>
            {t('collectible.tokens.coins', { coins: collectible.price })}
          </TextLegalRegular>
        )}
      </Box>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} flexGrow={1}>
        <CustomIconButton
          variant={'link'}
          icon={onSale ? 'circle-minus' : 'circle-plus'}
          sx={{ color: colors.primary.main.background }}
          onClick={onSale ? removeFromSale : placeOnSale}
        />
      </Box>
    </RoundedCornersBox>
  )
}

export { TokenItem }
