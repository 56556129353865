export type { ApiError } from './errors'
export { ErrorEnum } from './errors'
export { CountryCode } from './country-codes'

export interface Brand {
  id: number
  name: string
}
export interface Team {
  id: number
  name: string
  logo: string
  type: TeamType
}

export interface ErrorResponse {
  type: string
  message: string
}

export type Profile = {
  email: string
  username: string
  firstName: string
  lastName: string
  birthdate: string
  address: string
  avatar: string
  phoneNumber: string
  fullName: string
  favouriteTeam: Team
  favouriteNationalTeam: Team
}

export enum SubscriptionType {
  NONE = 'NONE',
  FREEMIUM = 'FREEMIUM',
  PREMIUM = 'PREMIUM',
}
export type Subscription = {
  type: SubscriptionType
  digitizeQuota: number
  triviasQuota: number
  challengesQuota: number
  predictionsQuota: number
  pollsQuota: number
}
export type User = {
  id: number
  username: string
  coins: number
  createdAt: Date
  profile: Profile
  subscription: Subscription
}

export interface Country {
  id: number
  createdAt: Date
  name: string
  code: string
  availableToTrade: boolean
  currency: string
  flag: string
}

export interface AuthResponse {
  client: User
  token: string
  refreshToken: string
}

export type PaginatedRequest<Filters> = {
  page?: number
  pageSize?: number
  filters: Filters
}

export interface PaginatedResponse<T> {
  data: T[]
  extraItem?: T
  total: number
  page: number
  pageSize: number
  lastPage: number
  next?: string | null
  previous?: string | null
}

export enum ItemStatus {
  INCOMPLETE = 'Incomplete', //El usuario completó los datos del Item, pero no cargó fotos todavía.
  REVIEW = 'Review', //El usuario completó todo. Queda en revisión por parte del admin
  USERREVIEW = 'User review', //Vuelve al usuario con observaciones
  VALIDATED = 'Validated', //Aprobado por el admin
}
export enum TeamType {
  TEAM = 'Team',
  NATIONAL_TEAM = 'National_Team',
}

export enum ItemEquipmentType {
  FIRST = 'First',
  SECOND = 'Second',
  THIRD = 'Third',
}

export enum BooleanType {
  YES = 'Yes',
  NO = 'No',
}

export type Metadata = {
  id: number
  name: string
  type: TeamType
  team: Team
  year: number
  season: string
  brand: Brand
  dorsal: number
  equipmentType: ItemEquipmentType
  signed: boolean
  signer?: string
  history?: string
  mediaFiles: ItemMediaFiles
  item?: Item
  owner?: User
}

export type ItemMediaFiles = {
  frontImage: string
  backImage: string
  rightArmImage: string
  leftArmImage: string
  signatureImage?: string
}

export type Item = {
  id: number
  status: ItemStatus
  metadata: Metadata
  owner?: User
}

export interface ItemFilters {
  [key: string]: any | '' | undefined
}

export interface TeamsFilters {
  id?: number[]
  name?: string
  type: TeamType[]
}

export type PropsWithItem<P = unknown> = P & { item?: Item | undefined }

export type Collectible = {
  id: number
  item?: Item
  metadata: Metadata
  owner: User
  price: number | null
  locked: boolean
}

export type GroupedCollectible = Metadata & {
  collectibles: Collectible[]
}

export type PropsWithCollectible<P = unknown> = P & { collectible?: Collectible | undefined }

export type PropsWithGroupedCollectible<P = unknown> = P & { groupedCollectible?: GroupedCollectible | undefined }
