import * as React from 'react'
import Box from '@mui/material/Box'
import { Avatar, Chip, Grid } from '@mui/material'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CustomButton, CustomPagination, TextInput, Row } from '@components'
import { Team, TeamType } from '@types'
import { colors } from '@styles'
import { useTranslation, useTeams } from '@hooks'
import { TeamItem } from '@features/Teams'

import { StepProps } from '../types'
import { useCreateAccount } from '../../../../../contexts/create-account-context'

const NationalTeam = (props: StepProps) => {
  const { t } = useTranslation()
  const { createAccountState, setCreateAccountState } = useCreateAccount()

  const { paginationWithFilters, data, changeFilters, changePage } = useTeams({ type: [TeamType.NATIONAL_TEAM] })
  const initialNationalTeam = createAccountState?.nationalTeam
  const [selectedTeam, updateSelectedTeam] = useState<Team | null>(initialNationalTeam ?? null)
  const { filters } = paginationWithFilters
  const filteredTeams = data?.data ?? []

  return (
    <>
      <TextInput
        label={t('create-account.national-team.input.national-team')}
        fullWidth
        onChange={e => changeFilters({ ...filters, name: e.target.value })}
        startAdornment={
          <Box mr={1}>
            <FontAwesomeIcon icon={['fas', 'magnifying-glass']} size={'1x'} color={colors.grayscale['30'].background} />
          </Box>
        }
      />
      <Box>
        {selectedTeam && (
          <Chip
            avatar={<Avatar alt={selectedTeam.name} src={selectedTeam.logo} />}
            label={selectedTeam.name}
            variant='outlined'
            color={'primary'}
            onDelete={() => updateSelectedTeam(null)}
          />
        )}
      </Box>
      <Grid container spacing={2} columns={16}>
        {filteredTeams.map((t, index) => (
          <Grid item xs={4} key={`national-team-${index}`}>
            <TeamItem {...t} onClick={() => updateSelectedTeam(t)} />
          </Grid>
        ))}
      </Grid>
      {data && filteredTeams.length > 0 && (
        <CustomPagination count={data.lastPage} page={data.page} onChange={page => changePage(page)} />
      )}
      <CustomButton
        variant={'text'}
        label={t('create-account.common.input.skip')}
        fullWidth={true}
        color={colors.primary.main.background}
        onClick={() => {
          if (props.next) {
            props.next()
          }
        }}
        disabled={!!selectedTeam}
      />
      <Row>
        <CustomButton
          variant={'secondary'}
          label={t('create-account.common.input.back')}
          fullWidth={true}
          onClick={() => {
            if (props.prev) {
              props.prev()
            }
          }}
        />
        <CustomButton
          variant={'primary'}
          label={t('create-account.common.input.next')}
          disabled={!selectedTeam}
          fullWidth={true}
          onClick={() => {
            if (props.next) {
              if (selectedTeam) {
                setCreateAccountState({ ...createAccountState, nationalTeam: { ...selectedTeam } })
              }
              props.next()
            }
          }}
        />
      </Row>
    </>
  )
}

export { NationalTeam }
