import { createContext, ReactNode, useCallback, useContext, useState } from 'react'
import { Modal as MuiModal } from '@mui/material'

import { Modal } from '@components'

type ShowModalProps = { modal: ReactNode; options?: Options }
type Options = {
  onClose?: () => void
  onDismiss?: () => void
  modalProps?: { width?: string | number; height?: string | number; borderColor?: string }
}
type ContextState = {
  isOpen: boolean
  currentModal: ReactNode | null
  showModal: (props: ShowModalProps) => void
  dismissModal: () => void
}

const ModalContext = createContext<ContextState | null>(null)

export function ModalProvider({ children }: { children: ReactNode }) {
  const [modalConfig, updateModalConfig] = useState<null | ShowModalProps>(null)

  const handleDismiss = () => {
    updateModalConfig(prevState => {
      if (prevState != null && prevState?.options?.onDismiss) {
        prevState.options.onDismiss()
      }

      return null
    })
  }

  const handleClose = useCallback(() => {
    if (modalConfig?.options?.onClose) {
      modalConfig?.options?.onClose()
    }
    handleDismiss()
  }, [handleDismiss, modalConfig])

  return (
    <ModalContext.Provider
      value={{
        isOpen: !!modalConfig,
        currentModal: modalConfig?.modal,
        showModal: (props: ShowModalProps) => {
          updateModalConfig(props)
        },
        dismissModal: handleDismiss,
      }}
    >
      {children}
      <MuiModal open={!!modalConfig}>
        <>
          <Modal.Base handleClose={handleClose} {...(modalConfig?.options?.modalProps ?? {})}>
            {modalConfig?.modal}
          </Modal.Base>
        </>
      </MuiModal>
    </ModalContext.Provider>
  )
}

export function useModal(): ContextState {
  const context = useContext(ModalContext)
  if (!context) throw Error('This hook can only be used under the ModalProvider component')

  return context
}
