import { useNavigate, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import * as React from 'react'

import { Content, CustomButton, CustomPagination, Header, Loading, Row, TextH3Bold, Title } from '@components'
import { useTriviaRanking } from '@features/Trivias/hooks/useTriviaRanking'
import { AppBase } from '@features/AppBase'
import { useTranslation } from '@hooks'
import { ROUTES } from '@constants'
import { Images } from '@images'

import { RankingTable } from '../../components'

const TriviaRanking = () => {
  const { id = 0 } = useParams()
  const { isLoading, data, changePage } = useTriviaRanking(Number(id))
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <AppBase requireActiveSession={false} backgroundImage={Images.TriviasBackground}>
      <Box width={'100%'} px={{ md: 16.5, sm: 8, xs: 2 }} py={4}>
        <Header>
          <CustomButton
            variant={'secondary'}
            label={t('ranking-trivia.back')}
            startIcon={['fas', 'chevron-left']}
            onClick={() => navigate(ROUTES.TRIVIAS)}
          />
          <Title text={t('ranking-trivia.title')} />
        </Header>
        <Content flexDirection={'column'}>
          {isLoading && <Loading />}
          {!isLoading && data?.data && data?.data.length == 0 && (
            <Box alignSelf={'center'} mt={4}>
              <TextH3Bold>{t('ranking-trivia.empty')} </TextH3Bold>
            </Box>
          )}
          {data && data?.data.length > 0 && <RankingTable data={data} onChangePage={changePage} />}
          <Row>
            {data && data.data.length > 0 && data.lastPage > 0 && (
              <CustomPagination count={data.lastPage} page={data.page} onChange={page => changePage(page)} />
            )}
          </Row>
        </Content>
      </Box>
    </AppBase>
  )
}

export { TriviaRanking }
