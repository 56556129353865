import { api } from '@api'
import { User } from '@types'

import { ProfileDto } from '../types'

const update = async (payload: Partial<ProfileDto>) => {
  const response = await api.put<User>('/client/me/profile', payload)

  return response.data
}

export const ProfileApi = { update }
