import { useQuery } from 'react-query'
import { useEffect, useState } from 'react'

import { QUERY_KEYS } from '@api'
import { PaymentsApi } from '@features/Coins/api'
import { CoinsBudget } from '@features/Coins/types'

const useCoinsBudget = (coinsBudget: CoinsBudget) => {
  const [error, setError] = useState<{ minAmount: number; minCoinsToBuy: number } | null>(null)
  const { coins, amount, currency } = coinsBudget
  const { data, isLoading, isError } = useQuery(
    [QUERY_KEYS.COINS_BUDGET, currency, amount, coins],
    () => PaymentsApi.getCoinsBudget(coinsBudget),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        const { minAmount, minCoinsToBuy } = err
        if (minAmount && minCoinsToBuy) {
          setError({ minAmount, minCoinsToBuy })
        }
      },
    },
  )

  useEffect(() => {
    if (isLoading) {
      setError(null)
    }
  }, [isLoading])

  return { data, isLoading, isError, error }
}

export { useCoinsBudget }
