import * as yup from 'yup'

import { useTranslation } from '@hooks'

import { FileProps } from '../../types'

const MAX_FILE_SIZE = 1048576 //1mb
const validFileExtensions = { image: ['jpg', 'png', 'jpeg', 'webp'] }
const isValidFileType = (fileName: string, fileType: string) => {
  return !!fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1
}
const useTShirtImageValidationSchema = () => {
  const { t } = useTranslation()
  const TShirtImageValidationSchema = yup.object<FileProps>().shape({
    size: yup.number().required().max(MAX_FILE_SIZE, t('digitize.image-picker.validation.file-size')),
    name: yup
      .string()
      .required()
      .test('is-valid-type', t('digitize.image-picker.validation.image-type'), value =>
        isValidFileType(value && value.toLowerCase(), 'image'),
      ),
  })

  return { TShirtImageValidationSchema }
}

export { useTShirtImageValidationSchema }
