import * as React from 'react'
import Box from '@mui/material/Box'

import { CustomButton, TextH2Bold } from '@components'
import { LoginModal } from '@features/Login'
import { useTranslation } from '@hooks'
import { useModal } from '@contexts'
import { Images } from '@images'
import { colors } from '@styles'

import { WidgetContent } from '../WidgetContent'

const PutYourShirtsToPlayWidget = () => {
  const { t } = useTranslation()
  const { showModal } = useModal()

  return (
    <WidgetContent>
      <Box sx={{ width: '100%', position: 'relative' }}>
        <img
          src={Images.PutYourShirtsToPlay}
          style={{ border: `1px solid ${colors.grayscale['30'].background}`, borderRadius: 12, width: '100%' }}
          alt={'t-shirts'}
        />
        <Box sx={{ position: 'absolute', padding: 2, bottom: 0 }}>
          <TextH2Bold>{t('landing.celebrate-widget.title')}</TextH2Bold>
        </Box>
      </Box>
      <CustomButton
        variant={'secondary'}
        label={t('landing.celebrate-widget.action')}
        sx={{ mt: 2, fontWeight: 700, fontSize: 18 }}
        onClick={() => showModal({ modal: <LoginModal /> })}
      />
    </WidgetContent>
  )
}

export { PutYourShirtsToPlayWidget }
