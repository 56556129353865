import * as React from 'react'
import { useCallback, useState } from 'react'
import Box from '@mui/material/Box'
import { Avatar, Chip, Grid } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CustomButton, CustomPagination, Row, TextInput } from '@components'
import { Team, TeamType } from '@types'
import { colors } from '@styles'
import { useTranslation, useTeams } from '@hooks'
import { TeamItem } from '@features/Teams'

import { StepProps } from '../types'
import { useCreateAccount } from '../../../../../contexts/create-account-context'

const Club = (props: StepProps) => {
  const MAX_TEAMS = 1
  const { t } = useTranslation()
  const { createAccountState, setCreateAccountState } = useCreateAccount()
  const initialClubTeam = createAccountState?.clubTeam
  const [selectedTeams, updateSelectedTeams] = useState<Team[]>(initialClubTeam ? [initialClubTeam] : [])

  const { paginationWithFilters, data, changeFilters, changePage } = useTeams({ type: [TeamType.TEAM] })
  const { filters } = paginationWithFilters

  const filteredTeams = data?.data ?? []

  const onTeamClick = useCallback(
    (t: Team) => {
      const founded = selectedTeams.find(st => st.name === t.name)
      if (!!founded) {
        updateSelectedTeams(selectedTeams.filter(st => st.name != t.name))
      } else {
        const newTeams = [...selectedTeams, t]
        if (newTeams.length > MAX_TEAMS) {
          newTeams.splice(0, newTeams.length - MAX_TEAMS)
        }
        updateSelectedTeams(newTeams)
      }
    },
    [selectedTeams],
  )

  return (
    <>
      <TextInput
        label={t('create-account.club-team.input.club-team')}
        fullWidth
        onChange={e => changeFilters({ ...filters, name: e.target.value })}
        startAdornment={
          <Box mr={1}>
            <FontAwesomeIcon icon={['fas', 'magnifying-glass']} size={'1x'} color={colors.grayscale['30'].background} />
          </Box>
        }
      />
      <Box>
        {selectedTeams.map(st => (
          <Chip
            sx={{ mr: 1, mb: 1 }}
            avatar={<Avatar alt={st.name} src={st.logo} />}
            label={st.name}
            variant='outlined'
            color={'primary'}
            onDelete={() => onTeamClick(st)}
          />
        ))}
      </Box>
      <Grid container spacing={2} columns={16}>
        {filteredTeams.map(t => (
          <Grid item xs={4}>
            <TeamItem {...t} onClick={() => onTeamClick(t)} />
          </Grid>
        ))}
      </Grid>
      {data && filteredTeams.length > 0 && (
        <CustomPagination count={data.lastPage} page={data.page} onChange={page => changePage(page)} />
      )}

      <CustomButton
        variant={'text'}
        label={t('create-account.common.input.skip')}
        fullWidth={true}
        color={colors.primary.main.background}
        onClick={() => {
          if (props.next) {
            props.next()
          }
        }}
        disabled={selectedTeams.length > 0}
      />
      <Row>
        <CustomButton
          variant={'secondary'}
          label={t('create-account.common.input.back')}
          fullWidth={true}
          onClick={() => {
            if (props.prev) {
              props.prev()
            }
          }}
        />
        <CustomButton
          variant={'primary'}
          label={t('create-account.common.input.next')}
          fullWidth={true}
          disabled={selectedTeams.length == 0}
          onClick={() => {
            if (props.next) {
              if (selectedTeams && selectedTeams.length > 0) {
                setCreateAccountState({ ...createAccountState, clubTeam: { ...selectedTeams[0] } })
              }
              props.next()
            }
          }}
        />
      </Row>
    </>
  )
}

export { Club }
