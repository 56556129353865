import Box from '@mui/material/Box'
import * as React from 'react'

import { useTranslation } from '@hooks'
import { CustomButton, TextSubtitleBold } from '@components'
import { colors } from '@styles'
import { useModal } from '@contexts'

import { TShirtImagePicker } from '../TShirtImagePicker'
import { HelpModal } from '../../modals'
import { useDigitize } from '../../contexts/digitize-context'

const FormImages = () => {
  const { signed } = useDigitize()
  const { t } = useTranslation()
  const { showModal } = useModal()

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={2}
      flexGrow={1}
      flexShrink={1}
      sx={{ maxWidth: 500, width: '100%' }}
    >
      <Box display={'flex'} flexDirection={'column'} gap={3}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
          <TextSubtitleBold>{t('digitize.images.title')}</TextSubtitleBold>
          <CustomButton
            variant={'link'}
            label={t('digitize.images.help')}
            color={colors.primary.main.background}
            onClick={() => showModal({ modal: <HelpModal /> })}
          />
        </Box>
        <TShirtImagePicker title={t('digitize.images.front')} name={'frontImage'} />
        <TShirtImagePicker title={t('digitize.images.back')} name={'backImage'} />
        <TShirtImagePicker title={t('digitize.images.right-side')} name={'rightArmImage'} />
        <TShirtImagePicker title={t('digitize.images.left-side')} name={'leftArmImage'} />
        {signed && <TShirtImagePicker title={t('digitize.images.signature')} name={'signatureImage'} />}
      </Box>
    </Box>
  )
}

export { FormImages }
