export enum DatesRange {
  CurrentWeek = 'current-week',
  CurrentMonth = 'current-month',
  CurrentSeason = 'current-season',
  Total = 'total',
}

export enum RankingType {
  Global = 'global',
  Predictions = 'predictions',
  Trivias = 'trivias',
  Challenges = 'challenges',
  Collectibles = 'collectibles',
  Digitalization = 'digitalization',
}

export interface RankingGlobalFilters {
  datesRange: DatesRange
  type: RankingType
}

export interface RankingGlobal {
  partnerId: number
  clientId: number
  position: string
  username: string
  name: string
  predictionPoints: string
  predictionCount: string
  triviaPoints: string
  triviaCount: string
  triviaCorrect: string
  triviaTotal: string
  challengePoints: string
  challengeCount: string
  totalItemsPoints: string
  itemsCount: string
  totalCollectiblePoints: string
  collectibleCount: string
  totalPoints: string
  team: string
}

export const RankingGlobalComparator = (item: RankingGlobal, other?: RankingGlobal) => {
  if (item && other) {
    return item.clientId === other.clientId
  }

  return false
}
