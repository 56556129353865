import Box from '@mui/material/Box'
import * as React from 'react'
import { PropsWithChildren } from 'react'

import { HeaderImage } from '@components'

interface HeaderProps extends PropsWithChildren {
  image?: string
}
const Header = (props: HeaderProps) => {
  const { image, children } = props

  return (
    <Box
      className={'ShiModal-header'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'flex-start'}
      alignSelf={'stretch'}
      gap={2.5}
    >
      {!!image && <HeaderImage image={image} />}
      <Box display={'flex'} flexDirection={'column'} gap={0} sx={{ width: '100%' }}>
        {children}
      </Box>
    </Box>
  )
}

export { Header }
