import Box from '@mui/material/Box'
import dayjs from 'dayjs'
import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import { useNavigate } from 'react-router-dom'

import { PaginatedTable, TextCaptionBold, TextCaptionRegular, TableActions, TableColumns } from '@components'
import { PaginatedResponse } from '@types'
import { colors } from '@styles'
import { useTranslation } from '@hooks'
import { ROUTES } from '@constants'
import { MedalIcon } from '@features/Icons/MedalIcon'

import { Trivia } from '../../types'

interface Props {
  data: PaginatedResponse<Trivia>
  onChangePage: (page: number) => void
}

const PreviousTriviasTable = (props: Props) => {
  const navigate = useNavigate()
  const { data, onChangePage } = props
  const { t } = useTranslation()

  const columns: (TableColumns<Trivia> | TableActions<Trivia>)[] = [
    {
      width: '25%',
      field: 'date',
      sorteable: false,
      type: 'column',
      headerName: t('previous-trivias.header.date'),
      getValue: item => {
        return dayjs(item.quizClient?.lastEventDate).date()
      },
      renderCell: (item, _value) => {
        const matchDate = dayjs(item.quizClient?.lastEventDate)
        const userTime = dayjs(item.quizClient?.lastEventDate).tz(dayjs.tz.guess())

        return (
          <Box display={'flex'} flexDirection={'column'}>
            <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>
              {matchDate.format(t('previous-trivias.column.date-format')).toUpperCase()}
            </TextCaptionBold>
            <TextCaptionRegular sx={{ color: colors.grayscale[10].background }}>
              {userTime.format(t('previous-trivias.column.time-format'))}
            </TextCaptionRegular>
          </Box>
        )
      },
    },
    {
      width: '25%',
      field: 'name',
      sorteable: true,
      type: 'column',
      headerName: t('previous-trivias.header.name'),
      renderCell: (item, value) => {
        return (
          <Box display={'flex'} flexDirection={'column'}>
            <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>{value}</TextCaptionBold>
          </Box>
        )
      },
      getValue: item => `${item.name || ''}`,
    },
    {
      width: '20%',
      field: 'correctAnswers',
      sorteable: true,
      type: 'column',
      headerName: t('previous-trivias.header.correctAnswers'),
      renderCell: (_item, value) => {
        return <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>{value}</TextCaptionBold>
      },
      getValue: item =>
        t('previous-trivias.column.correctAnswers', {
          ok: item.quizClient?.correctAnswers ?? '-',
          total: item.quizClient?.totalQuestions ?? '-',
        }),
    },
    {
      width: '15%',
      field: 'points',
      sorteable: true,
      type: 'column',
      headerName: t('previous-trivias.header.points'),
      renderCell: (_item, value) => {
        return (
          <Box display={'flex'} flexDirection={'column'}>
            <TextCaptionBold sx={{ color: colors.grayscale[10].background }}>{value}</TextCaptionBold>
          </Box>
        )
      },
      getValue: item => {
        return item.quizClient?.points ?? '-'
      },
    },

    {
      width: '5%',
      field: 'actions',
      headerName: '',
      type: 'actions',
      getActions: () => [
        <IconButton key='medal' onClick={() => {}} sx={{ color: colors.primary.main.background }}>
          <MedalIcon />
        </IconButton>,
      ],
    },
  ]

  return (
    <PaginatedTable<Trivia>
      columns={columns}
      onChangePage={onChangePage}
      data={data}
      onRowClicked={(_, item) => navigate(`${ROUTES.RANKING_TRIVIAS}/${item.id}`)}
    />
  )
}

export { PreviousTriviasTable }
