import Box from '@mui/material/Box'
import * as React from 'react'

import { CustomButton, Modal, TextH2Bold, TextH4Bold } from '@components'
import { Images } from '@images'
import { useTranslation } from '@hooks'

interface TriviaCompletedModalProps {
  points: number
  correctAnswers: number
  totalQuestions: number
  onClick: () => void
}
export const TriviaCompletedModal = (props: TriviaCompletedModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal.Content>
      <Modal.Header image={Images.TriviaCompletedImage}>
        <Box display={'flex'} flexDirection={'column'} gap={0} pt={1.5}>
          {(props.correctAnswers > 0) && <TextH2Bold>
            {t('trivias.modal.completed.title', {
              points: `${props.points}`,
              ok: props.correctAnswers,
              total: props.totalQuestions,
            })}
          </TextH2Bold>
          }
          {(props.correctAnswers == 0) && <TextH2Bold>
            {t('trivias.modal.completed.title-no-correct', {
              points: `${props.points}`,
              ok: props.correctAnswers,
              total: props.totalQuestions,
            })}
          </TextH2Bold> 
          }
          {props.correctAnswers > 0 ? <TextH4Bold>{t('trivias.modal.completed.subtitle')}</TextH4Bold> : <TextH4Bold>{t('trivias.modal.completed.subtitle-no-correct')}</TextH4Bold>}
        </Box>
      </Modal.Header>
      <Modal.Actions>
        <CustomButton
          variant={'secondary'}
          label={t('trivias.modal.completed.button')}
          fullWidth={true}
          onClick={props.onClick}
        />
      </Modal.Actions>
    </Modal.Content>
  )
}
