import { useNavigate, useLocation } from 'react-router-dom'

import { useModal } from '@contexts'

import { useSessionUserUpdater } from '../useSessionUserUpdater'

interface Props {
  onSuccess?: () => void
}

const useLoginResponseManager = (props?: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { dismissModal } = useModal()
  const { isLoading, refresh } = useSessionUserUpdater({
    onSuccess: () => {
      if (props?.onSuccess) props.onSuccess()
      if (location.pathname === '/login') {
        navigate('/', {})
      }
      dismissModal()
    },
  })

  return { isLoading, onSuccess: refresh }
}

export { useLoginResponseManager }
