import Box from '@mui/material/Box'
import * as React from 'react'
import { FormHelperText } from '@mui/material'

import { TeamPickerModal } from '@features/Teams'
import { useModal } from '@contexts'
import { TeamType, Team } from '@types'
import { TextBodyBold } from '@components'
import { useTranslation } from '@hooks'
import { TeamIcon } from '@features/Teams'

import { LinkButtonStyled } from '../../../../components/Buttons/styled'

interface TeamPickerProps {
  type?: TeamType
  team?: Team
  onChange: (team: Team | null) => void
  error: boolean
  helperText?: string | boolean
}
const TeamPicker = (props: TeamPickerProps) => {
  const { type, team, helperText, error, onChange } = props
  const { t } = useTranslation()
  const { showModal } = useModal()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, flexGrow: 1, alignItems: 'center', mb: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, alignItems: 'center' }}>
        <TeamIcon image={team?.logo} />
        <TextBodyBold>{team?.name}</TextBodyBold>
        {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
      </Box>
      <LinkButtonStyled
        disabled={!type}
        onClick={() =>
          showModal({
            modal: <TeamPickerModal maxTeams={1} type={type!} onChange={t => onChange(t ? t.shift() ?? null : null)} />,
          })
        }
      >
        {t('digitize.team-picker.select')}
      </LinkButtonStyled>
    </Box>
  )
}

export { TeamPicker }
