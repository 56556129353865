import Box from '@mui/material/Box'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { Title, Content, Column, TextH2Bold, CustomButton, Row, GroupedCollectibleCard, Loading } from '@components'
import { useTranslation } from '@hooks'
import { ROUTES } from '@constants'
import { AppBase } from '@features/AppBase'
import { Images } from '@images'

import { useMyCollectibles } from '../../hooks'

const MyCollectionMain = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isLoading, data } = useMyCollectibles(0)

  return (
    <AppBase requireActiveSession backgroundImage={Images.CollectiblesBackground}>
      <Box width={'100%'} px={{ md: 16.5, sm: 8, xs: 2 }} py={4}>
        <Title text={t('my-collectibles.title')} />
        <Content flexDirection={{ xl: 'row', lg: 'row', md: 'row', sm: 'row', xs: 'column' }}>
          {isLoading && <Loading />}
          {!isLoading &&
            data?.data.map(groupedCollectible => (
              <GroupedCollectibleCard
                key={`grouped-${groupedCollectible.id}`}
                groupedCollectible={groupedCollectible}
                onClick={() => navigate(`${ROUTES.MY_COLLECTIBLES}/${groupedCollectible.id}`)}
              />
            ))}
          {!isLoading && (data?.data.length ?? 0) == 0 && (
            <Column>
              <TextH2Bold>{t('my-collectibles.empty')}</TextH2Bold>
              <Row>
                <CustomButton variant={'primary'} label={t('nav.digitize')} onClick={() => navigate(ROUTES.DIGITIZE)} />
                <CustomButton
                  variant={'secondary'}
                  label={t('nav.marketplace')}
                  onClick={() => navigate(ROUTES.MARKETPLACE)}
                />
              </Row>
            </Column>
          )}
        </Content>
      </Box>
    </AppBase>
  )
}

export { MyCollectionMain }
