import { useQuery, useQueryClient } from 'react-query'

import { QUERY_KEYS } from '@api'

import { TransactionsApi } from '../../api'

const useTransactions = () => {
  const queryClient = useQueryClient()

  const { data, isLoading } = useQuery([QUERY_KEYS.MY_TRANSACTIONS], () => TransactionsApi.getTransactions(), {
    refetchOnWindowFocus: false,
  })

  const invalidate = () => queryClient.invalidateQueries(QUERY_KEYS.MY_TRANSACTIONS)

  return { data, isLoading, invalidate }
}
export { useTransactions }
