import * as React from 'react'

import { Carousel } from '@components'

import { ChallengeRequirementItem } from '../../types'
import { ChallengeRequiredItemCard } from '../ChallengeRequiredItemCard'

interface Props {
  requiredItems: ChallengeRequirementItem[]
  itemSelected: ChallengeRequirementItem | null
  onItemSelected: (item: ChallengeRequirementItem) => void
}
const ChallengeRequiredItems = (props: Props) => {
  const { requiredItems, onItemSelected, itemSelected } = props

  return (
    <Carousel<ChallengeRequirementItem>
      items={requiredItems}
      itemProvider={item => (
        <ChallengeRequiredItemCard
          requiredItem={item}
          onSelected={() => onItemSelected(item)}
          isSelected={!!itemSelected && itemSelected.id == item.id}
        />
      )}
    />
  )
}

export { ChallengeRequiredItems }
