import { Team } from '@types'

export interface PredictionsFilters {
  [key: string]: any | '' | undefined
}

export enum PredictionStatus {
  INACTIVE = 'Inactive', //La predicción sobre el partido está creada, pero no disponible para ser completada.
  ACTIVE = 'Active', //La predicción está disponible para ser completada.
  COMPLETED = 'Completed', //El usuario completó el pronostico y todavía no comenzó el partido
  CLOSED = 'Closed', //El período para pronosticar termino. -> No se pueden realizar modificaciones en el pronostico o pronosticos nuevos.
  FINISHED = 'Finished', //Vuelve al usuario con observaciones
}

export type Stadium = {
  name: string
  address: string
}

export type TeamStatistics = {
  goals: number
  possession: number
  offsides: number
  cornerKicks: number
  shotsOffTarget: number
  shotsOnTarget: number
  blockedScoringAttempts: number
  goalkeeperSaves: number
  tackles: number
  totalPasses: number
  completedPasses: number
  fouls: number
  yellowCards: number
  redCards: number
  scorers: Player[]
}

export type Statistics = {
  localStats: TeamStatistics
  visitorStats: TeamStatistics
}

export type Match = {
  date: string
  round: number
  competition: string
  localTeam: Team
  visitorTeam: Team
  stadium: Stadium
  localTeamSquad: Player[]
  visitorTeamSquad: Player[]
  statistics?: Statistics
}

export type Alignment = {
  localTeamSquad: Player[]
  visitorTeamSquad: Player[]
}

export type ClientPrediction = {
  id: number
  completed: boolean
  points: number
  statistics?: Statistics
}

export type Prediction = {
  id: number
  match: Match
  status: PredictionStatus
  clientPrediction?: ClientPrediction
}

export interface PlayersFilters {
  id?: number[]
  name?: string
  teams: Team[]
}

export const OWN_GOAL_PLAYER_ID = -1
export interface Player {
  id: string
  name: string
  dorsal?: number | null
  image?: string
}

export type RankingPrediction = {
  client_id: number
  position: number
  name: string
  team?: string
  points: number
}
export interface RankingPredictionsFilters extends PredictionsFilters {
  predictionId: number
}

export const RankingPredictionsComparator = (item: RankingPrediction, other?: RankingPrediction) => {
  if (item && other) {
    return item.client_id === other.client_id
  }

  return false
}
