import { Drawer, FormControlLabel } from '@mui/material'
import Box from '@mui/material/Box'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconButton from '@mui/material/IconButton'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import dayjs from 'dayjs'

import { TeamPickerInput } from '@features/Teams'
import { Checkbox, CustomButton, DateInput, Row, SelectInput, TextInput, TextSubtitleBold } from '@components'
import { colors } from '@styles'
import { Brand, ItemEquipmentType, ItemFilters, TeamType } from '@types'
import { useBrands, useTeams, useTranslation } from '@hooks'

import { Footer, Header } from './styled'

interface Props {
  showCoins?: boolean
  onClose?: () => void
  open?: boolean
  onChange?: (filters: ItemFilters) => void
  defaultValues?: ItemFilters
}

const ItemFiltersDrawer = (props: Props) => {
  const { open, onClose, onChange, defaultValues, showCoins = false } = props
  const [filters, updateFilters] = useState<ItemFilters>(defaultValues ?? {})
  const { brands } = useBrands()

  const teamIds = (filters.team ?? [{ id: 0 }]).filter(t => !t.name).map(t => t.id)

  const { data: teamsData } = useTeams({ type: [TeamType.TEAM, TeamType.NATIONAL_TEAM], id: teamIds })

  const _onChange = useCallback(
    (name: string, value: any) => {
      if (name === 'type') {
        const { team: _, ...others } = filters
        updateFilters({ ...others, team: [], [name]: value })
      } else {
        updateFilters({ ...filters, [name]: value })
      }
    },
    [filters],
  )

  useEffect(() => {
    if (brands && brands.length > 0 && filters && filters.brand && Array.isArray(filters.brand)) {
      const oneWithName = filters.brand.find(b => !!b.name)
      if (!oneWithName) {
        filters['brand'] = filters?.brand.map(b => brands.find(bb => bb.id == b.id)).filter(b => !!b)
        updateFilters(filters)
      }
    }
  }, [brands, filters])

  useEffect(() => {
    const teams = teamsData?.data
    if (teams && teams.length > 0 && filters && filters.team && Array.isArray(filters.team)) {
      const oneWithName = filters.team.find(t => !!t.name)
      if (!oneWithName) {
        filters['team'] = teams
        updateFilters(filters)
      }
    }
  }, [teamsData, filters])

  const { t } = useTranslation()

  return (
    <Drawer
      anchor={'right'}
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          maxWidth: 500,
          boxSizing: 'border-box',
        },
      }}
    >
      <Header>
        <TextSubtitleBold>{t('item-filters.filters')}</TextSubtitleBold>
        <IconButton size={'small'} onClick={props.onClose}>
          <FontAwesomeIcon icon={'close'} size={'2x'} color={colors.grayscale['10'].background} />
        </IconButton>
      </Header>
      <Box display={'flex'} flexDirection={'column'} gap={2} p={2} flexGrow={1}>
        {showCoins && (
          <Row>
            <TextInput
              label={t('item-filters.from')}
              fullWidth
              type={'number'}
              onChange={e => _onChange('from', e.target.value)}
              defaultValue={defaultValues?.from}
              value={filters.from ?? ''}
            />
            <TextInput
              label={t('item-filters.to')}
              fullWidth
              type={'number'}
              onChange={e => _onChange('to', e.target.value)}
              defaultValue={defaultValues?.to}
              value={filters.to ?? ''}
            />
          </Row>
        )}
        <SelectInput
          label={t('item-filters.item-type')}
          options={[TeamType.TEAM, TeamType.NATIONAL_TEAM]}
          value={filters.type ?? []}
          multiple={true}
          objectToString={type => `${type}`.replace('_', ' ')}
          onChange={values => _onChange('type', values)}
        />
        <TeamPickerInput
          label={t('item-filters.teams')}
          type={filters.type}
          team={filters.team ?? []}
          onChange={value => {
            _onChange('team', value)
          }}
        />
        <TextInput
          label={t('item-filters.name')}
          fullWidth
          onChange={e => _onChange('name', e.target.value)}
          defaultValue={defaultValues?.name}
          value={filters.name ?? ''}
        />

        <DateInput
          name={'year'}
          label={t('item-filters.year')}
          format={'YYYY'}
          defaultValue={filters.year ? dayjs(new Date(filters.year, 1, 1)) : undefined}
          onChange={date => {
            _onChange('year', date?.format('YYYY') ?? '')
          }}
          views={['year']}
        />

        <DateInput
          name={'season'}
          label={t('item-filters.season')}
          format={'YYYY'}
          onChange={date => {
            _onChange('season', date?.format('YYYY') ?? '')
          }}
          views={['year']}
        />

        <SelectInput<Brand>
          label={t('item-filters.brand')}
          fullWidth
          options={brands}
          multiple={true}
          objectToString={brand => brand?.name}
          onChange={value => _onChange('brand', value)}
          value={filters.brand ?? defaultValues?.brand}
        />
        <TextInput
          label={t('item-filters.dorsal')}
          fullWidth
          onChange={e => _onChange('dorsal', e.target.value)}
          defaultValue={defaultValues?.dorsal}
          value={filters.dorsal ?? ''}
        />
        <SelectInput
          label={t('item-filters.equipment')}
          options={[ItemEquipmentType.FIRST, ItemEquipmentType.SECOND, ItemEquipmentType.THIRD]}
          value={filters.equipment}
          multiple={true}
          onChange={values => _onChange('equipment', values)}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={filters.signed}
              onChange={(_, checked) => {
                _onChange('signed', checked)
              }}
            />
          }
          label={t('item-filters.signed')}
          sx={{ color: colors.grayscale['10'].background }}
        />
      </Box>
      <Footer>
        <CustomButton
          variant={'secondary'}
          label={t('item-filters.clear')}
          fullWidth
          onClick={() => {
            updateFilters({})
            if (onChange) onChange({})
          }}
        />
        <CustomButton
          variant={'primary'}
          label={t('item-filters.submit')}
          fullWidth
          onClick={() => {
            if (onChange) onChange({ ...filters, t: Date.now() })
          }}
        />
      </Footer>
    </Drawer>
  )
}

export { ItemFiltersDrawer }
