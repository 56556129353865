import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconButton from '@mui/material/IconButton'

import { colors } from '@styles'
import { useTranslation } from '@hooks'
import { CustomButton } from '@components'

const Copyright = () => {
  const { t } = useTranslation()

  return (
    <Typography variant='body2' sx={{ color: colors.grayscale['10'].background }}>
      {t('footer.copy-rights')}
    </Typography>
  )
}

const Footer = () => {
  const { t } = useTranslation()

  return (
    <Box
      component='footer'
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: colors.grayscale['80'].background,
        flexGrow: 1,
        borderTop: `1px solid ${colors.grayscale['30'].background}`,
      }}
    >
      <Container maxWidth='xl'>
        <Box flexDirection={'column'}>
          <Box sx={{ flexDirection: { xs: 'column', sm: 'column', md: 'row' }, display: 'flex', mb: 2 }}>
            <Box flexGrow={2}>
              <Typography
                variant='h3'
                sx={{ color: colors.grayscale['10'].background, textTransform: 'uppercase', mb: 1.5 }}
              >
                {t('footer.follow-us')}
              </Typography>
              <Box columnGap={2} display={'flex'}>
                <IconButton aria-label='instagram' size='medium' sx={{ color: colors.primary.main.background }}>
                  <FontAwesomeIcon icon={['fab', 'instagram']} />
                </IconButton>
                <IconButton aria-label='facebook' size='medium' sx={{ color: colors.primary.main.background }}>
                  <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                </IconButton>
                <IconButton aria-label='youtube' size='medium' sx={{ color: colors.primary.main.background }}>
                  <FontAwesomeIcon icon={['fab', 'youtube']} />
                </IconButton>
              </Box>
            </Box>
            <Box
              flexGrow={2}
              flexDirection={'row'}
              columnGap={2}
              sx={{ flexDirection: { xs: 'column', sm: 'column', md: 'row' }, display: 'flex' }}
            >
              <Box display={'flex'} flexDirection={'column'} flexGrow={1} alignItems={'flex-start'}>
                <Typography variant='body1' sx={{ color: colors.grayscale['30'].background }}>
                  {t('footer.support')}
                </Typography>
                <CustomButton variant={'link'} label={t('footer.help')} />
                <CustomButton variant={'link'} label={t('footer.contact-us')} />
              </Box>
              <Box display={'flex'} flexDirection={'column'} flexGrow={1} alignItems={'flex-start'}>
                <Typography variant='body1' sx={{ color: colors.grayscale['30'].background }}>
                  {t('footer.about')}
                </Typography>
                <CustomButton variant={'link'} label={t('footer.shirtum')} />
                <CustomButton variant={'link'} label={t('footer.us')} />
              </Box>
              <Box display={'flex'} flexDirection={'column'} flexGrow={1} alignItems={'flex-start'}>
                <Typography variant='body1' sx={{ color: colors.grayscale['30'].background }}>
                  {t('footer.legal')}
                </Typography>
                <CustomButton variant={'link'} label={t('footer.legal')} />
                <CustomButton variant={'link'} label={t('footer.terms-and-conditions')} />
                <CustomButton variant={'link'} label={t('footer.code-of-conduct')} />
              </Box>
            </Box>
          </Box>
          <Box>
            <Copyright />
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export { Footer }
