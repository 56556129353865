import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

import { Statistics, TeamStatistics } from '@features/Predictions/types'
import { useTranslation } from '@hooks'
import { useScorersToString } from '@features/Predictions/hooks'
import { PredictionRow } from '@features/Predictions/components/PredictionRow'
import { TextBodyRegular, TextH2Bold, TextH4Bold } from '@components'
import { colors } from '@styles'

interface StatisticsWithPredictionRowProps {
  localValue: string | number
  localPredictionValue: string | number | null
  visitorValue: string | number
  visitorPredictionValue: string | number | null
  label: string
}

const PredictionRowValue = styled(TextH2Bold)(() => ({ width: '50%', textOverflow: 'ellipsis', overflow: 'hidden' }))
const RightPredictionRowValue = styled(PredictionRowValue)(() => ({ textAlign: 'end' }))

const StatisticsWithPredictionRow = (props: StatisticsWithPredictionRowProps) => {
  const { localValue, localPredictionValue, visitorValue, visitorPredictionValue, label } = props

  return (
    <PredictionRow
      sx={theme => ({ marginBottom: theme.spacing(2) })}
      leftCellContent={
        <>
          <PredictionRowValue>{localValue}</PredictionRowValue>
          <PredictionRowValue>{localPredictionValue}</PredictionRowValue>
        </>
      }
      centralCellContent={<TextH4Bold sx={{ textAlign: 'center' }}>{label}</TextH4Bold>}
      rightCellContent={
        <>
          <RightPredictionRowValue>{visitorPredictionValue}</RightPredictionRowValue>
          <RightPredictionRowValue>{visitorValue}</RightPredictionRowValue>
        </>
      }
    />
  )
}

interface Props {
  statistics?: Statistics
  prediction?: Statistics
}

const YourPredictionTab = (props: Props) => {
  const { statistics, prediction } = props

  const { t } = useTranslation()

  const scorersToString = useScorersToString()

  return statistics && prediction ? (
    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
      <PredictionRow
        sx={theme => ({ marginBottom: theme.spacing(1) })}
        leftCellContent={
          <>
            <TextBodyRegular sx={{ width: '50%' }}>{t('predictions.form.header-results')}</TextBodyRegular>
            <TextBodyRegular sx={{ width: '50%' }}>{t('predictions.form.header-your-prediction')}</TextBodyRegular>
          </>
        }
        centralCellContent={
          <TextH2Bold sx={{ color: colors.primary.main.background, textAlign: 'center' }}>
            {t('predictions.form.header-stats')}
          </TextH2Bold>
        }
        rightCellContent={
          <>
            <TextBodyRegular sx={{ width: '50%', textAlign: 'end' }}>
              {t('predictions.form.header-your-prediction')}
            </TextBodyRegular>
            <TextBodyRegular sx={{ width: '50%', textAlign: 'end' }}>
              {t('predictions.form.header-results')}
            </TextBodyRegular>
          </>
        }
      />

      <StatisticsWithPredictionRow
        localValue={statistics.localStats.goals}
        localPredictionValue={prediction.localStats.goals}
        visitorValue={statistics.visitorStats.goals}
        visitorPredictionValue={prediction.visitorStats.goals}
        label={t(`predictions.form.goals`)}
      />
      <StatisticsWithPredictionRow
        localValue={scorersToString(statistics.localStats.scorers)}
        localPredictionValue={scorersToString(prediction.localStats.scorers)}
        visitorValue={scorersToString(statistics.visitorStats.scorers)}
        visitorPredictionValue={scorersToString(prediction.visitorStats.scorers)}
        label={t(`predictions.form.scorers`)}
      />

      <StatisticsWithPredictionRow
        localValue={`${statistics.localStats.possession}%`}
        localPredictionValue={`${prediction.localStats.possession}%`}
        visitorValue={`${statistics.visitorStats.possession}%`}
        visitorPredictionValue={`${prediction.visitorStats.possession}%`}
        label={t(`predictions.form.possession`)}
      />
      {(
        [
          { attribute: 'offsides' },
          { attribute: 'cornerKicks', label: 'corner-kicks' },
          { attribute: 'shotsOffTarget', label: 'shots-off-target' },
          { attribute: 'shotsOnTarget', label: 'shots-on-target' },
          { attribute: 'blockedScoringAttempts', label: 'blocked-scoring-attempts' },
          { attribute: 'goalkeeperSaves', label: 'goalkeeper-saves' },
          { attribute: 'tackles' },
          { attribute: 'totalPasses', label: 'total-passes' },
          { attribute: 'completedPasses', label: 'completed-passes' },
          { attribute: 'fouls' },
          { attribute: 'yellowCards', label: 'yellow-cards' },
          { attribute: 'redCards', label: 'red-cards' },
        ] as { attribute: keyof Omit<TeamStatistics, 'scorers'>; label?: string }[]
      ).map(i => (
        <StatisticsWithPredictionRow
          key={i.attribute}
          localValue={statistics.localStats[i.attribute]}
          localPredictionValue={prediction.localStats[i.attribute]}
          visitorValue={statistics.visitorStats[i.attribute]}
          visitorPredictionValue={prediction.visitorStats[i.attribute]}
          label={t(`predictions.form.${i.label ?? i.attribute}`)}
        />
      ))}
    </Box>
  ) : null
}
export { YourPredictionTab }
