import * as process from 'process'

import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useModal, useUser } from '@contexts'
import { SubscriptionType } from '@types'
import { ROUTES } from '@constants'

import { AnswerPollToWinPrizeModal } from '../../modals'

const useFreemiumUserPoll = () => {
  const { userState } = useUser()
  const { pathname } = useLocation()
  const { isOpen, showModal } = useModal()
  const [shouldShowModal, setShouldShowModal] = useState(true)

  const onClose = () => {
    setShouldShowModal(false)
  }

  const shouldShowWidget = useMemo(() => {
    return (
      process.env.REACT_APP_NEW_USER_POLL_PATH &&
      userState?.subscription.type === SubscriptionType.FREEMIUM &&
      userState?.subscription.pollsQuota > 0 &&
      pathname === ROUTES.HOME
    )
  }, [userState, pathname])

  useEffect(() => {
    if (process.env.REACT_APP_NEW_USER_POLL_PATH && shouldShowWidget && shouldShowModal && !isOpen) {
      showModal({
        modal: <AnswerPollToWinPrizeModal actionPath={process.env.REACT_APP_NEW_USER_POLL_PATH} onClose={onClose} />,
        options: { onClose },
      })
    }
  }, [shouldShowWidget, shouldShowModal])

  return { shouldShowWidget, actionPath: process.env.REACT_APP_NEW_USER_POLL_PATH ?? '' }
}

export { useFreemiumUserPoll }
