import { styled } from '@mui/material/styles'
import MuiRadio from '@mui/material/Radio'
import MuiCheckbox from '@mui/material/Checkbox'

import { colors } from '@styles'

const Radio = styled(MuiRadio)(({ theme }) => ({
  color: colors.grayscale['10'].background,
  '&.Mui-checked': {
    color: theme.palette.primary.dark,
  },
}))

const Checkbox = styled(MuiCheckbox)(({ theme }) => ({
  color: colors.grayscale['10'].background,
  '&.Mui-checked': {
    color: theme.palette.primary.dark,
  },
}))

export { Radio, Checkbox }
