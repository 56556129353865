import Box from '@mui/material/Box'
import { LinearProgress } from '@mui/material'
import * as React from 'react'

import { Images } from '@images'
import { HeaderImage, RoundedCornersBox, TextCaptionBold, TextH2Bold } from '@components'
import { useTranslation } from '@hooks'

interface ProgressProps {
  value: number
  title: string
}
const Progress = (props: ProgressProps) => {
  const { value } = props
  const { t } = useTranslation()

  return (
    <RoundedCornersBox
      display={'flex'}
      width={{ lg: '50%', md: '100%', sm: '100%', xs: '100%' }}
      flexDirection={'column'}
      alignItems={'flex-start'}
      alignSelf={'flex-start'}
      gap={2.5}
      p={2}
    >
      <HeaderImage image={Images.CreateAccountImage} />
      <TextH2Bold>{props.title}</TextH2Bold>
      <Box width={'100%'}>
        <TextCaptionBold sx={{ mb: 1 }}>{t('create-account.progress')}</TextCaptionBold>
        <LinearProgress variant='determinate' value={value} color={'primary'} />
        <TextCaptionBold sx={{ textAlign: 'right' }}>{`${value}%`}</TextCaptionBold>
      </Box>
    </RoundedCornersBox>
  )
}

export { Progress }
