import Box from '@mui/material/Box'
import * as React from 'react'

import { TextCaptionBold } from '@components'
import { Team } from '@types'

interface TeamItemProps extends Team {
  onClick?: () => void
}

const TeamItem = (props: TeamItemProps) => {
  return (
    <Box
      onClick={props.onClick}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      display={'flex'}
      sx={theme => ({ borderRadius: theme.spacing(1.5), padding: theme.spacing(1), cursor: 'pointer' })}
    >
      <img src={props.logo} width={50} height={50} alt={props.name} />
      <TextCaptionBold sx={theme => ({ textAlign: 'center', mt: theme.spacing(1) })}>{props.name}</TextCaptionBold>
    </Box>
  )
}

export { TeamItem }
