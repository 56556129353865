import { Prediction } from '@features/Predictions/types'
import { usePredictionRanking } from '@features/Predictions/hooks/usePredictionRanking'

import { RankingTable } from '../../RankingTable'

interface Props {
  prediction: Prediction
}
const RankingTab = (props: Props) => {
  const { prediction } = props
  const { data, changePage } = usePredictionRanking(prediction.id)

  return data ? <RankingTable data={data} onChangePage={changePage} /> : null
}

export { RankingTab }
