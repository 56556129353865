import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { AppBase } from '@features/AppBase'
import { Content, CustomButton, Header, Loading, Title } from '@components'
import { ROUTES } from '@constants'
import { MatchHeader } from '@features/Predictions/components/MatchHeader'
import { PredictionDetailsTabs, PredictionForm } from '@features/Predictions/components'
import { PredictionStatus } from '@features/Predictions/types'
import { usePrediction } from '@features/Predictions/hooks'
import { Images } from '@images'
import { useCheckQuota } from '@features/Quotas/hooks'
import { QuotaFeature } from '@features/Quotas/types'

const PredictionsView = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { prediction, isLoading } = usePrediction(id!)
  const { showModalQuota, validQuota } = useCheckQuota(QuotaFeature.PREDICTIONS, {
    redirect: () => navigate(ROUTES.PREDICTIONS),
    noModal: true,
  })

  const title = useMemo(() => {
    return prediction
      ? t('predictions.view.title', {
          localTeamName: prediction.match.localTeam.name,
          visitorTeamName: prediction.match.visitorTeam.name,
        })
      : ''
  }, [prediction, t])

  useEffect(() => {
    if (prediction?.status === PredictionStatus.ACTIVE && !validQuota) {
      showModalQuota()
    }
  }, [validQuota, prediction])

  return (
    <AppBase requireActiveSession backgroundImage={Images.PredictionsBackground}>
      <Box width={'100%'} px={{ lg: 12, md: 8, sm: 4, xs: 2 }} py={4}>
        <Header>
          <CustomButton
            variant={'secondary'}
            label={t('predictions.view.back')}
            startIcon={['fas', 'chevron-left']}
            onClick={() => navigate(ROUTES.PREDICTIONS)}
          />
          <Title text={title} />
        </Header>

        <Content flexDirection={'column'}>
          {isLoading && <Loading />}
          {!isLoading && prediction && (
            <>
              <MatchHeader match={prediction?.match} status={prediction.status} />
              {[PredictionStatus.ACTIVE, PredictionStatus.COMPLETED].includes(prediction.status) ? (
                <PredictionForm prediction={prediction} />
              ) : (
                <PredictionDetailsTabs prediction={prediction} />
              )}
            </>
          )}
        </Content>
      </Box>
    </AppBase>
  )
}

export { PredictionsView }
