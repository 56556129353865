import Box from '@mui/material/Box'
import * as React from 'react'
import { useCallback, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Chip, Grid } from '@mui/material'

import { useModal } from '@contexts'
import { CustomButton, Modal, TextInput } from '@components'
import { colors } from '@styles'
import { useTranslation } from '@hooks'

import { usePlayerAccumulator } from '../../hooks'
import { OWN_GOAL_PLAYER_ID, Player } from '../../types'

interface Props {
  onChange: (value: Player[] | null) => void
  squad: Player[]
  players: Player[] | null
  maxPlayers?: number
}

const PlayerPickerModal = (props: Props) => {
  const { squad, onChange, maxPlayers = 1 } = props
  const playerAccumulator = usePlayerAccumulator()
  const { dismissModal } = useModal()
  const { t } = useTranslation()
  const [selectedPlayers, updateSelectedPlayers] = useState<Player[]>(props.players ?? [])
  const [query, updateQuery] = useState('')
  const playersWithCount = useMemo(() => {
    return playerAccumulator(selectedPlayers)
  }, [selectedPlayers])

  const onPlayerClick = useCallback(
    (p: Player) => {
      if (selectedPlayers.length + 1 <= maxPlayers) {
        updateSelectedPlayers([...selectedPlayers, p])
      }
    },
    [selectedPlayers, maxPlayers],
  )

  const onDeletePlayerClick = useCallback(
    (p: Player) => {
      const foundedIndex = selectedPlayers.findIndex(st => st.id === p.id)
      if (foundedIndex >= 0) {
        selectedPlayers.splice(foundedIndex, 1)
        updateSelectedPlayers([...selectedPlayers])
      }
    },
    [selectedPlayers],
  )

  const ownGoalPlayer = useMemo<Player>(() => {
    return { name: t('predictions.scorer-picker.own-goal'), id: `${OWN_GOAL_PLAYER_ID}` }
  }, [t])

  const filteredPlayers = useMemo(() => {
    const items = query
      .split(' ')
      .map(i => i.trim().toLowerCase())
      .filter(i => i !== '')

    const players =
      items.length == 0
        ? squad
        : squad.filter(p => {
            const playerName = p.name.toLowerCase()

            return items.find(i => playerName.includes(i))
          })

    return [ownGoalPlayer, ...players]
  }, [squad, query, ownGoalPlayer])

  const label = t('predictions.scorer-picker.select')

  return (
    <Modal.Content>
      <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} alignSelf={'stretch'} gap={2.5}>
        <TextInput
          label={label}
          fullWidth
          onChange={e => updateQuery(e.target.value)}
          startAdornment={
            <Box mr={1}>
              <FontAwesomeIcon
                icon={['fas', 'magnifying-glass']}
                size={'1x'}
                color={colors.grayscale['30'].background}
              />
            </Box>
          }
        />
        <Box>
          {playersWithCount.map(pc => (
            <Chip
              sx={{ mr: 1, mb: 1 }}
              label={
                `${
                  Number(pc.player.id) == OWN_GOAL_PLAYER_ID ? t('predictions.scorer-picker.own-goal') : pc.player.name
                }` + (pc.count > 1 ? ` (${pc.count})` : '')
              }
              variant='outlined'
              color={Number(pc.player.id) == OWN_GOAL_PLAYER_ID ? 'secondary' : 'primary'}
              onDelete={() => onDeletePlayerClick(pc.player)}
            />
          ))}
        </Box>
        <Grid container spacing={2} columns={16}>
          {filteredPlayers.map(p => (
            <Grid item xs={4}>
              <Chip
                sx={{
                  mr: 1,
                  mb: 1,
                }}
                label={`${p.name}`}
                variant='filled'
                color={Number(p.id) == OWN_GOAL_PLAYER_ID ? 'secondary' : 'primary'}
                onClick={() => onPlayerClick(p)}
              />
            </Grid>
          ))}
        </Grid>
        <Modal.Actions>
          <CustomButton
            variant={'secondary'}
            label={t('teams.action.back')}
            fullWidth={true}
            onClick={() => {
              dismissModal()
            }}
          />
          <CustomButton
            variant={'primary'}
            label={t('teams.action.continue')}
            fullWidth={true}
            onClick={() => {
              onChange(selectedPlayers)
              dismissModal()
            }}
            disabled={selectedPlayers.length != maxPlayers}
          />
        </Modal.Actions>
      </Box>
    </Modal.Content>
  )
}

export { PlayerPickerModal }
